import SystemReducer from "./system/systemSlice";
import RequestQuotationReducer from "components/containers/requestQuotation/requestQuotationSlice";
import QuotationReducer from "store/modules/quotation-slice";
import CommonDataReducer from "store/modules/commonDataSlice";
import ContactUsReducer from "store/modules/contactUsSlice";
import CareersReducer from "pages/careers/careers-slice";
import BrandsReducer from "pages/brand/brands-slice";

const RootReducer = {
  system: SystemReducer,
  careers: CareersReducer,
  contactUs: ContactUsReducer,
  requestQuotation: RequestQuotationReducer,
  quotation: QuotationReducer,
  commonData: CommonDataReducer,
  brands: BrandsReducer,
};

export default RootReducer;
