import React from "react";
import PropTypes from "prop-types";
import { ContentPane } from "components/ui/contentPane";
import SectionTitle from "components/ui/pageSection/SectionTitle";
import "./contact-us.scss";
import { Col, Row } from "react-bootstrap";
import ContactForm from "./form/ContactForm";
import PhoneNumbers from "./phoneNumbers/PhoneNumbers";
import Social from "./social/Social";
import RcBlockUi from "components/ui/rcBlockUi/RcBlockUi";

function ContactUs({ formId, loading, validator, containerCss, onFormSubmit }) {
  return (
    <section id="contacts" className={`contact-us-wrapper ${containerCss}`}>
      <RcBlockUi blocking={loading}>
        <ContentPane
          isFullWidth={true}
          hasShadow={false}
          contentCss="content-box"
          contentPaddingHorizontal="3"
          contentPaddingVertical="3"
          mainContentCss="main-content py-5"
        >
          <SectionTitle
            title="Contact Us"
          //subTitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
          //tempor incididunt ut labore"
          />

          <Row className="section-content g-0">
            <Col xs={12} md={{ span: 5, offset: 1 }}>
              <ContactForm
                formId={formId}
                validator={validator}
                containerCss="mb-5x py-3"
                onFormSubmit={onFormSubmit}
              />
            </Col>
            <Col className="d-none d-md-flex flex-row justify-content-center">
              <div className="separator"></div>
            </Col>
            <Col xs={12} md={{ span: 5 }}>
              <PhoneNumbers containerCss="py-3" />
            </Col>
          </Row>
        </ContentPane>
        <ContentPane
          isFullWidth={true}
          hasShadow={false}
          containerCss="contact-us-wrapper"
          contentCss="social-box"
          contentPaddingHorizontal="3"
          contentPaddingVertical="3"
          mainContentCss="main-content p-5"
        >
          <div className="d-flex flex-row justify-content-center">
            <Social />
          </div>
        </ContentPane>
      </RcBlockUi>
    </section>
  );
}

ContactUs.propTypes = {
  formId: PropTypes.string,
  loading: PropTypes.bool,
  validator: PropTypes.object,
  containerCss: PropTypes.string,
  onFormSubmit: PropTypes.func,
};

ContactUs.defaultProps = {};

export default ContactUs;
