import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { Image } from "react-bootstrap";
import MarkDown from "components/ui/markdown/MarkDown";

function FeatureItem({
  isImage,
  imageUrl,
  featureMarkDown,
  isLastItem,
  onPreview,
}) {
  const handlePreview = useCallback(
    () => onPreview(imageUrl),
    [onPreview, imageUrl]
  );

  if (isImage) {
    return (
      <Image
        src={imageUrl}
        className={`feature-image cursor-pointer ${isLastItem ? "" : "mb-4"}`}
        onClick={handlePreview}
        fluid
        rounded
      />
    );
  }

  return (
    <MarkDown
      text={featureMarkDown}
      containerCss={`${isLastItem ? "" : "mb-4x"}`}
    />
  );
}

FeatureItem.propTypes = {
  isImage: PropTypes.bool,
  imageUrl: PropTypes.string,
  featureMarkDown: PropTypes.string,
  isLastItem: PropTypes.bool,
};

FeatureItem.defaultProps = {};

export default React.memo(FeatureItem);
