/**
 * Configure additional middleware here
 */
import { configureStore } from "@reduxjs/toolkit";
import RootReducer from "./rootReducer";

const ConfigureStore = () => {
  const store = configureStore({
    reducer: RootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
    //   getDefaultMiddleware({
    //     serializableCheck: {
    //       // Ignore these action types
    //       ignoredActions: ["system/showToastError/fulfilled"],
    //     },
    //   }),
  });

  return store;
};

export default ConfigureStore;
