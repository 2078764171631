import React from "react";
import PropTypes from "prop-types";
import { Stack, Image } from "react-bootstrap";
import { ReactComponent as LocationSvg } from "assets/images/feather/map-pin.svg";
import { Badge } from "react-bootstrap";
import { BrandStatus } from "constants/brandStatus";
import useMediaQueryExt from "hooks/useMediaQueryExt";
import { ReactComponent as HomeSvg } from "assets/images/feather/home.svg";
import "./brand-information.scss";

function BrandInformation({
  location,
  price,
  brandTypeText,
  brandStatus,
  containerCss,
}) {
  const { isMobile } = useMediaQueryExt();

  if (isMobile) {
    return (
      <div className="d-flex flex-column flex-wrap fw-700 tsmall2 brand-information-wrapper">
        <div className="mb-1 d-flex flex-row justify-content-start align-items-center">
          <LocationSvg height={16} className="me-2" />
          <span>{location}</span>
        </div>
        <div className="mb-1 info-item d-flex flex-row justify-content-start align-items-center">
          <Image src="/images/brand/money-2.png" className="img-item" />
          PHP {price}
        </div>
        <div className="mb-1 d-flex flex-row justify-content-start align-items-center">
          <HomeSvg height={16} className="me-2" />
          {brandTypeText}
        </div>
        {brandStatus === BrandStatus.SoldOut && (
          <div>
            <Badge
              bg="danger"
              text="light"
              style={{ fontSize: "0.8rem" }}
              className="info-item"
            >
              Sold Out
            </Badge>
          </div>
        )}
      </div>
    );
  }

  return (
    <Stack
      direction="horizontal"
      gap={2}
      className="fw-700 text-mutedx tsmall2"
    >
      <div>
        <LocationSvg height={16} className="me-1" />
        <span>{location}</span>
      </div>
      <div className="mx-2 text-muted">|</div>
      <div style={{ fontWeight: "bold" }}>PHP {price}</div>
      <div className="mx-2 text-muted">|</div>
      <div>{brandTypeText}</div>
      {brandStatus === BrandStatus.SoldOut && (
        <>
          <div className="mx-2 text-muted">|</div>
          <Badge bg="danger" text="light" style={{ fontSize: "0.8rem" }}>
            Sold Out
          </Badge>
        </>
      )}
    </Stack>
  );
}

BrandInformation.propTypes = {
  location: PropTypes.string,
  price: PropTypes.string,
  brandTypeText: PropTypes.string,
  containerCss: PropTypes.string,
};

BrandInformation.defaultProps = {};

export default React.memo(BrandInformation);
