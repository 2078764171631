import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { PropTypes } from "prop-types";
import { useDropzone } from "react-dropzone";
import { Card, Col, Row } from "react-bootstrap";
import { noop } from "lodash";
import "./rc-file-upload.scss";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import {
  fileListToBase64,
  toKilobyte,
  getColor,
  limitFileName,
  fileListToArrayBuffer,
  getForFileUploading,
} from "./utils/helper";
import ImageListing from "./imageListing/ImageListing";
import FileListing from "./imageListing/FileListing";
import FileRejections from "./rejections/FileRejections";
import {
  acceptStyle,
  baseStyle,
  focusedStyle,
  rejectStyle,
} from "./styles/style-object";
import { FileUploadStatus } from "./utils/constants";
import ImagePreview from "components/ui/imagePreview/ImagePreview";

function RcFileUpload({
  text,
  dropText,
  minSize,
  maxSize,
  maxFiles,
  initialFiles,
  mode,
  fileTypes,
  isReadonly,
  uploadLabel,
  onChange,
  onUpload,
  onRemove,
  onActivityInProgress,
}) {
  const [files, setFiles] = useState([]);
  const [isPreviewOpen, setPreviewOpen] = useState(false);
  const [previewUrl, setPreviewUrl] = useState("");
  const [isDropping, toggleDrop] = useState(false);
  const refIsMounted = useRef(false);

  const changeFileUploadStatus = (resp) => {
    setFiles((prev) => {
      const result = prev.map((fileItem) => {
        if (fileItem.type !== FileUploadStatus.NEW) return fileItem;

        let searchedItem = resp.find(
          (f) => f.clientRefId === fileItem.clientRefId
        );

        let newValue = {
          ...fileItem,
          id: searchedItem.id,
          preview: searchedItem.resourceUrl,
          isBusy: false,
        };

        if (!searchedItem.hasError) {
          newValue.type = FileUploadStatus.UPLOADED;
        } else {
          newValue.type = FileUploadStatus.UPLOAD_FAILED;
          newValue.hasError = true;
          newValue.errorMessage = "Error uploading";
        }

        return newValue;
      });

      return result;
    });
  };

  const removeFilesDueToInternalServerError = (filesToUpload) => {
    const filesToUploadIds = filesToUpload.map((o) => o.clientRefId);

    setFiles((prev) => {
      const result = prev.filter(
        (f) => !filesToUploadIds.includes(f.clientRefId)
      );
      return result;
    });
  };

  const changeBusyStatusTo = (id, statusFlag) => {
    setFiles((prev) => {
      const result = prev.map((o) => {
        if (o.id === id) {
          return {
            ...o,
            isBusy: statusFlag,
          };
        }

        return o;
      });

      return result;
    });
  };

  const setFileErrorMessage = (id, errorMessage) => {
    setFiles((prev) => {
      const result = prev.map((o) => {
        if (o.id === id) {
          return {
            ...o,
            hasError: true,
            errorMessage,
          };
        }

        return o;
      });

      return result;
    });
  };

  const isSystemBusy = () => {
    if (files.length === 0) return false;
    const result = files.some((p) => p?.isBusy === true);
    return result;
  };

  const onDrop = useCallback(
    async (acceptedFiles) => {
      // Reject files exceeding the maxFiles limit
      //toggleDrop(true);

      let finalAcceptedFiles = [];
      let filesCount = files.length + acceptedFiles.length;

      if (filesCount > maxFiles) {
        let itemsUpperLimit = maxFiles - files.length;
        finalAcceptedFiles = acceptedFiles.slice(0, itemsUpperLimit);
      } else {
        finalAcceptedFiles = acceptedFiles;
      }

      let filesWithAdditionalProps = finalAcceptedFiles.map((file) =>
        Object.assign(file, {
          clientRefId: uuidv4(),
          preview: URL.createObjectURL(file),
          isBusy: true,
        })
      );

      // Convert to base64 each file
      //const arrayOfBase64Files = await fileListToBase64(filesWithAdditionalProps);
      const arrayOfBuffer = await fileListToArrayBuffer(
        filesWithAdditionalProps
      );

      // Complete set of files
      //files.push(...arrayOfBuffer);
      //files.push(...arrayOfBase64Files);
      //files.push(...filesWithAdditionalProps);

      setFiles((current) => {
        return [...current, ...arrayOfBuffer];
      });

      // Determine which files to upload
      let forFileUploading = getForFileUploading(arrayOfBuffer);

      // #for: fileData mode
      // Upload to server if handled by consumer
      if (forFileUploading.length === 0) return;

      const promiseCb = new Promise((resolve, reject) => {
        onUpload(forFileUploading, mode, resolve, reject);
      });

      promiseCb
        .then((resp) => {
          changeFileUploadStatus(resp);
        })
        .catch((ex) => {
          removeFilesDueToInternalServerError(forFileUploading);
          alert(ex);
        });
    },
    [files, maxFiles, onChange, onUpload]
  );

  const getFileTypesAccepted = () => {
    if (mode === "Image")
      return { "image/*": [".png", ".gif", ".jpeg", ".jpg", ".svg", ".webp"] };

    if (mode === "File" && Object.keys(fileTypes).length === 0) {
      return {
        "image/*": [
          ".pdf",
          ".jpg",
          ".jpeg",
          ".png",
          ".gif",
          ".bmp",
          ".svg",
          ".webp",
        ],
        "application/pdf": [".pdf"],
        "application/msword": [".doc"],
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
          [".docx"],
        "application/vnd.ms-excel": [".xls"],
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
          ".xlsx",
        ],
      };
    } else {
      return fileTypes;
    }
  };

  useEffect(() => {
    const isBusy = isSystemBusy();
    onActivityInProgress(isBusy);

    if (!refIsMounted.current && files.length === 0) {
      refIsMounted.current = true;
      return;
    }

    onChange([...files]);
  }, [files]);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isFocused,
    isDragAccept,
    isDragReject,
    fileRejections,
    //rejectedFiles,
    acceptedFiles, //
  } = useDropzone({
    accept: getFileTypesAccepted(),
    minSize: minSize,
    maxSize: maxSize,
    //multiple: true,
    onDrop,
    disabled: isReadonly,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const openPreview = (file) => {
    setPreviewUrl(file.preview);
    setPreviewOpen(true);
  };

  const removeFile = (file) => {
    if (isReadonly) return;

    // If existing files then perform BE delete
    // Otherwise let the jobs handle the purging of files
    if (file.type === FileUploadStatus.INIT) {
      changeBusyStatusTo(file.id, true);
      onRemove(file.id, mode === "Image")
        .then((resp) => {
          URL.revokeObjectURL(file.preview);
          let filesUpdated = files.filter((o) => o !== file);
          setFiles(filesUpdated);
          onChange([...filesUpdated]);
        })
        .catch((err) => {
          setFileErrorMessage(file.id, "Unable to delete file");
        })
        .finally(() => {
          changeBusyStatusTo(file.id, false);
        });

      return;
    }

    URL.revokeObjectURL(file.preview);
    let filesUpdated = files.filter((o) => o !== file);
    setFiles(filesUpdated);
    onChange([...filesUpdated]);
  };

  // const removeFile = useCallback(
  //   (file) => () => {
  //     debugger;
  //     if (isReadonly) return;

  //     // If existing files then perform BE delete
  //     if (file.type === FileUploadStatus.INIT) {
  //       changeBusyStatusTo(file.id, true);
  //       debugger;
  //       onRemove(file.id, mode === "Image")
  //         .then((resp) => {
  //           URL.revokeObjectURL(file.preview);
  //           let filesUpdated = files.filter((o) => o !== file);
  //           setFiles(filesUpdated);
  //           onChange([...filesUpdated]);
  //         })
  //         .catch((err) => {
  //           setFileErrorMessage(file.id, "Unable to delete file");
  //         })
  //         .finally(() => {
  //           changeBusyStatusTo(file.id, false);
  //         });

  //       return;
  //     }

  //     URL.revokeObjectURL(file.preview);
  //     let filesUpdated = files.filter((o) => o !== file);
  //     setFiles(filesUpdated);
  //     onChange([...filesUpdated]);
  //   },
  //   [files, isReadonly, onChange, onRemove]
  // );

  const closePreview = (e) => {
    setPreviewOpen(false);
  };

  const downloadFile = (file) => {
    const { type, preview } = file;

    if (type !== FileUploadStatus.INIT) return false;

    var a = document.createElement("a");
    a.href = preview;
    a.setAttribute("download", "download");
    var b = document.createEvent("MouseEvents");
    b.initEvent("click", false, true);
    a.dispatchEvent(b);

    return false;
  };

  // const downloadFile = useCallback(
  //   (file) => () => {
  //     const { name, type, resourceUrl } = file;

  //     if (type !== FileUploadStatus.INIT) return false;
  //
  //     var a = document.createElement("a");
  //     a.href = resourceUrl;
  //     a.setAttribute("download", "download");
  //     var b = document.createEvent("MouseEvents");
  //     b.initEvent("click", false, true);
  //     a.dispatchEvent(b);

  //     return false;
  //   },
  //   []
  // );

  const DragMessage = ({ isDragActive, isDragReject, rejected }) => {
    return (
      <>
        {!isDragActive && !rejected && (
          <div
            className="text-center drag-msg"
            style={{ userSelect: "none", pointerEvents: "none" }}
          >
            <div>{uploadLabel}</div>
            <div>
              {text} Maximum of {maxFiles} file{maxFiles > 1 ? "s" : ""}.
            </div>
            {files.length > 0 ? (
              <div className="small text-primary">
                <strong>{files.length}</strong> file(s) attached.
              </div>
            ) : null}
          </div>
        )}
        {isDragActive && !rejected && !isDragReject && (
          <p
            className="mt-3 drag-msg fg-blue"
            style={{ userSelect: "none", pointerEvents: "none" }}
          >
            {dropText}
          </p>
        )}
        {(rejected || isDragReject) && (
          <p
            className="mt-3 drag-msg fg-red"
            style={{ userSelect: "none", pointerEvents: "none" }}
          >
            Unable to upload file(s)
          </p>
        )}
      </>
    );
  };

  const reloadInitialFiles = () => {
    if (!initialFiles || initialFiles.length <= 0) return;

    setFiles(
      initialFiles.map((o) =>
        Object.assign(
          {},
          {
            id: o.id,
            type: FileUploadStatus.INIT,
            name: o.originalName,
            size: o.size,
            preview: getPreviewData(o),
          }
        )
      )
    );
  };

  const getPreviewData = (file) => {
    //if (file.data.includes("data:")) return file.data;
    return `${file.resourceUrl}`;
  };

  const isImageMode = () => mode === "Image";

  // Component did mount
  useEffect(() => {
    reloadInitialFiles();
  }, [initialFiles]);

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  return (
    <Col className="rca-fileUploader pe-0 ps-0">
      <ImagePreview
        imageLargeUrl={previewUrl}
        isOpen={isPreviewOpen}
        closePreview={closePreview}
        showRotate={true}
        alternateText="Image Preview"
      />
      <div
        {...getRootProps({ style })}
        validForReject={fileRejections?.length > 0}
        className="mb-2x"
      >
        <input {...getInputProps()} />
        <DragMessage
          isDragActive={isDragActive}
          isDragReject={isDragReject}
          rejected={fileRejections?.length > 0}
        />
        <FileRejections data={fileRejections} />
      </div>
      {isImageMode() ? (
        <Row className="thumbsContainer">
          <ImageListing
            files={files}
            isReadonly={isReadonly}
            onRemoveFile={removeFile}
            onOpenPreview={openPreview}
          />
        </Row>
      ) : (
        <Row className="thumbsContainer">
          <FileListing
            files={files}
            onRemoveFile={removeFile}
            onDownloadFile={downloadFile}
          />
        </Row>
      )}
    </Col>
  );
}

RcFileUpload.propTypes = {
  minSize: PropTypes.number,
  maxSize: PropTypes.number,
  maxFiles: PropTypes.number,
  mode: PropTypes.string, //Note: Mode can be Image or File, each mode has different layout and behavior
  fileTypes: PropTypes.object,
  uploadLabel: PropTypes.string,
  dropText: PropTypes.string,
  initialFiles: PropTypes.array,
  isReadonly: PropTypes.bool,
  uploadMode: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onUpload: PropTypes.func,
  onRemove: PropTypes.func,
  onActivityInProgress: PropTypes.func,
};

RcFileUpload.defaultProps = {
  minSize: 0,
  maxSize: 102400, // 100kb default
  maxFiles: 5,
  mode: "File",
  fileTypes: {},
  uploadLabel: "Drag 'n' drop some files here, or click to select files",
  dropText: "Drop here!",
  initialFiles: [],
  isReadonly: false,
  uploadMode: "fileData", // fileData or base64
  onChange: noop,
  onUpload: noop,
  onRemove: noop,
  onActivityInProgress: noop,
};

export default RcFileUpload;
