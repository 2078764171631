import React from "react";
import PropTypes from "prop-types";
import { Image } from "react-bootstrap";
import "./section-header.scss";

function SectionHeader({ title, imageUrl, alt, containerCss }) {
  return (
    <section
      className={`section-header-wrapper position-relative d-flex flex-column justify-content-center align-items-center ${containerCss}`}
    >
      <Image src={imageUrl} alt={alt} />
      <div className="section-text">
        <h1 className="fg-white rca-heading4">{title}</h1>
      </div>
    </section>
  );
}

SectionHeader.propTypes = {
  containerCss: PropTypes.string,
  title: PropTypes.string,
  imageUrl: PropTypes.string,
  alt: PropTypes.string,
};

SectionHeader.defaultProps = {};

export default SectionHeader;
