import React from "react";
import PropTypes from "prop-types";
import { ContentPane } from "components/ui/contentPane";
import { NavLink, Outlet } from "react-router-dom";

function DemoPage({ containerCss }) {
  return (
    <ContentPane isFullWidth={false} hasShadow={true}>
      <NavLink to="buttons" className="me-3">
        Buttons
      </NavLink>
      <NavLink to="navbar" className="me-3">
        NavBar
      </NavLink>
      <NavLink to="framer-motion" className="me-3">
        Effects
      </NavLink>
      <NavLink to="input-demo" className="me-3">
        Input Demo
      </NavLink>
      <NavLink to="data-table" className="me-3">
        DataTable
      </NavLink>
      <NavLink to="playground" className="me-3">
        Playground
      </NavLink>
      <div className="mt-4">
        <Outlet />
      </div>
    </ContentPane>
  );
}

DemoPage.propTypes = {
  containerCss: PropTypes.string,
};

DemoPage.defaultProps = {};

export default DemoPage;
