import Http from "core/http/http";

function BrandShowcaseService() {
  const apiUrl = "/v1/brands";

  const getBrandsAsync = () => Http().get(`${apiUrl}`);

  return {
    getBrandsAsync,
  };
}

export default BrandShowcaseService;
