import { Navigate, Route } from "react-router-dom";
import LoginView from "pages/auth/login/loginView";
import SigninOrSignupPage from "pages/auth/client/signinOrSignup/SigninOrSignupPage";
import SignInPage from "pages/auth/client/signIn/SignInPage";
import SignUpPage from "pages/auth/client/signUp/SignUpPage";
import MagicLinkSentPage from "pages/auth/client/magicLinkSent/MagicLinkSentPage";

const authRoutes = () => {
  return (
    <>
      <Route index element={<LoginView />} />
      <Route path="login" element={<LoginView />} />
      <Route path="client" element={<SigninOrSignupPage />}>
        <Route path="signin" element={<SignInPage />} />
        <Route path="signup" element={<SignUpPage />} />
        <Route path="magic-link-sent" element={<MagicLinkSentPage />} />
      </Route>
      <Route path="*" element={<Navigate to="/pages/error-404" />} />
    </>
  );
};

export default authRoutes;
