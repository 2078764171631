import Http from "core/http/http";

function SignupService() {
  let apiUrl = "/v1/leads";

  const registerLead = (payload) => Http().post(`${apiUrl}`, payload);

  return {
    registerLead,
  };
}

export default SignupService;
