import React from "react";
import PropTypes from "prop-types";

function NoRecord({ containerCss }) {
  return (
    <section className={`d-flex flex-column justify-content-center align-items-center h-100 ${containerCss}`}>
      <i className="fas fa-database fa-8x fg-gray-200 mb-2 mb-md-5" />
      <h3 className="fg-gray-700">No record to display.</h3>
    </section>
  );
}

NoRecord.propTypes = {
  containerCss: PropTypes.string,
};

NoRecord.defaultProps = {};

export default NoRecord;
