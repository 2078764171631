import React from "react";
import PropTypes from "prop-types";
import { Card } from "react-bootstrap";
import { ReactComponent as CompletedImg } from "assets/images/quote/completed.svg";
import "./magic-link.scss";

function MagicLinkSentPage({ containerCss }) {
  return (
    <div className="magic-link-sent-wrapper">
      <Card className="py-3 shadow-sm">
        <Card.Body className="text-center">
          <CompletedImg height="180px" />
          <h1>Magic Link Sent</h1>
          <h4 className="text-muted">
            We have sent the magic link to your email{" "}
            <i>rlcristobal@gmail.com</i>.
          </h4>
          <h4 className="text-muted">Thank you!</h4>
        </Card.Body>
      </Card>
    </div>
  );
}

MagicLinkSentPage.propTypes = {
  containerCss: PropTypes.string,
};

MagicLinkSentPage.defaultProps = {};

export default MagicLinkSentPage;
