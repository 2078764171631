import { useEffect, useState } from "react";

function useCookiePolicy() {
  const URN_COOKIE_KEY = "urn:cookie.policy";
  const [showCookie, setShowCookie] = useState(false);

  useEffect(() => {
    setShowCookie(!isCookieExist());
  }, []);

  const isCookieExist = () => {
    try {
      const result = JSON.parse(localStorage.getItem(URN_COOKIE_KEY)) || false;

      return !!result;
    } catch (e) {
      console.error(e);
    }

    return false;
  };

  const setAcceptCookie = () => {
    try {
      localStorage.setItem(URN_COOKIE_KEY, true);
      setShowCookie(false);
    } catch (e) {
      console.error(e);
    }
  };

  return { showCookie, setAcceptCookie, isCookieExist };
}

export default useCookiePolicy;
