import {
  createAsyncThunk,
  createDraftSafeSelector,
  createSlice,
} from "@reduxjs/toolkit";
import CareersService from "./services/apiService";
import * as SystemSlice from "store/system/systemSlice";
import educationalAttainment from "data/educational-attainment";
import { ModuleOwner } from "constants/moduleOwner";

const initialState = {
  educationLookups: educationalAttainment,
  showModal: false,
  loading: false,
};

// ---------------
// Action Thunks
// ---------------
//

export const submitJobApplication = createAsyncThunk(
  "careers/submitJobApplication",
  async (data, thunkAPI) => {
    try {
      const payload = {
        firstName: data.fname,
        lastName: data.lname,
        email: data.email,
        mobileNumber: data.mobileNumber,
        educationalAttainmentId: data.educationalAttainment.value,
        message: data.message,
        resumeId: data.resume[0].id,
        moduleFileOwner: ModuleOwner.Careers,
      };
      debugger;
      const result = await new CareersService().submitJobApplication(payload);

      thunkAPI.dispatch(
        SystemSlice.showNotification({
          title: "Success",
          message: "You have successfully submitted your application.",
          type: "success",
        })
      );

      return result.data;
    } catch (err) {
      thunkAPI.dispatch(
        SystemSlice.showNotificationError({
          message: "Unable to submit job application.",
          error: err,
        })
      );
      throw err;
    }
  }
);

// ------------
// Slice
// ------------
export const CareersSlice = createSlice({
  name: "careers",
  initialState,
  reducers: {
    showJobApplicationForm: (state, action) => {
      state.showModal = action.payload;
    },
  },
  extraReducers: (builder) => {
    //
    // Submit Resume
    //
    builder.addCase(submitJobApplication.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(submitJobApplication.fulfilled, (state, action) => {
      state.loading = false;
      state.showModal = false;
    });
    builder.addCase(submitJobApplication.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const { showJobApplicationForm } = CareersSlice.actions;

export default CareersSlice.reducer;

// ------------
// Selectors
// ------------
const selectSelf = (state) => state.careers;

export const selectCareers = createDraftSafeSelector(
  selectSelf,
  (state) => state
);
