import React from "react";
import PropTypes from "prop-types";
import { motion } from "framer-motion";
import { ContentPane } from "components/ui/contentPane";
import "./why-apmi.scss";
import { Col, Image, Row } from "react-bootstrap";

function WhyApmi({ containerCss }) {
  return (
    <ContentPane
      isFullWidth={true}
      hasShadow={false}
      containerCss="why-apmi-wrapper"
      contentCss="content-box"
      contentPaddingHorizontal="0"
      contentPaddingVertical="0"
      mainContentCss="why-apmi-content-wrapper px-5x py-5 d-flex flex-column justify-content-center"
    >
      <motion.div
        initial={{ opacity: 0, y: "18vh" }}
        transition={{ ease: "easeInOut", duration: 0.8 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        className="row main-content"
      >
        <Col className="d-flex flex-column mb-5">
          <motion.div whileHover={{ scale: 1.1 }}>
            <Image src="/images/about-us/why-apmi.png" className="right-img" />
          </motion.div>
        </Col>
        <Col
          className="d-flex flex-column justify-content-center"
          md={{ span: 5, offset: 1 }}
        >
          <section className="bullet-list">
            <div className="d-flex flex-row">
              <div>
                <i class="fas fa-check me-3"></i>
              </div>
              <div>
                Home base Chemical Dilution control based on governed standards
              </div>
            </div>
            <div className="d-flex flex-row">
              <div>
                <i class="fas fa-check me-3"></i>
              </div>
              <div>
                Various alternative treatment depending on the objective, budget
                and household/corporate requirement/s and idiosyncrasies
              </div>
            </div>
            <div className="d-flex flex-row">
              <div>
                <i class="fas fa-check me-3"></i>
              </div>
              <div>
                APMI dwells on treating the root cause and not make band aid
                solutions
              </div>
            </div>
            <div className="d-flex flex-row">
              <div>
                <i class="fas fa-check me-3"></i>
              </div>
              <div>Free intensive and progressive consultation</div>
            </div>
            <div className="d-flex flex-row">
              <div>
                <i class="fas fa-check me-3"></i>
              </div>
              <div>Goal oriented treatment</div>
            </div>
            <div className="d-flex flex-row">
              <div>
                <i class="fas fa-check me-3"></i>
              </div>
              <div>Quality assured Treatment</div>
            </div>
          </section>
        </Col>
      </motion.div>
    </ContentPane>
  );
}

WhyApmi.propTypes = {
  containerCss: PropTypes.string,
};

WhyApmi.defaultProps = {};

export default WhyApmi;
