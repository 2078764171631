import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import Label from "../label/Label";
import MarkDown from "components/ui/markdown/MarkDown";
import { renderToStaticMarkup } from "react-dom/server";
import "./checkboxfield.scss";

function CheckboxField({
  name,
  type,
  label,
  value,
  title,
  labelBold,
  showRequiredLabel,
  containerCss,
  helpText,
  renderAsMarkdown,
  validationComponent,
  disabled,
  onChange,
  ...rest
}) {
  const getMarkup = () => {
    return <label htmlFor={name}>{label}</label>;
  };

  return (
    <section className={`rca-checkbox-field-wrapper mb-3  ${containerCss}`}>
      <Label
        label={title}
        labelBold={labelBold}
        showRequiredLabel={showRequiredLabel}
      />
      <Form>
        {type === "switch" ? (
          <Form.Check
            name={name}
            id={`${name}`}
            type={type}
            label={label}
            checked={value}
            onChange={onChange}
            className="cursor-pointer"
            disabled={disabled}
            {...rest}
          />
        ) : (
          <Form.Check
            name={name}
            id={`${name}`}
            checked={value}
            onChange={onChange}
            {...rest}
          >
            <Form.Check.Input
              type={type}
              className="cursor-pointer"
              disabled={disabled}
            />
            <Form.Check.Label className="cursor-pointer">
              {renderAsMarkdown ? (
                <MarkDown
                  text={renderToStaticMarkup(getMarkup())}
                  containerCss="d-inline chkbox-md"
                />
              ) : (
                label
              )}
            </Form.Check.Label>
          </Form.Check>
        )}
        {helpText && (
          <div>
            <Form.Text muted>{helpText}</Form.Text>
          </div>
        )}
        {validationComponent}
      </Form>
    </section>
  );
}

CheckboxField.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  labelBold: PropTypes.bool,
  showRequiredLabel: PropTypes.string,
  validationComponent: PropTypes.object,
  renderAsMarkdown: PropTypes.bool,
  containerCss: PropTypes.string,
  helpText: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

CheckboxField.defaultProps = {};

export default CheckboxField;
