import React from "react";
import PropTypes from "prop-types";
import { motion } from "framer-motion";

function MotionDiv({ containerCss, children, duration }) {
  return (
    <motion.div
      initial={{ opacity: 0, y: "10vh" }}
      transition={{ ease: "easeOut", duration }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      className={containerCss}
    >
      {children}
    </motion.div>
  );
}

MotionDiv.propTypes = {
  containerCss: PropTypes.string,
  duration: PropTypes.number,
};

MotionDiv.defaultProps = { duration: 1 };

export default MotionDiv;
