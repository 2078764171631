import React from "react";
import PropTypes from "prop-types";
import { Carousel } from "react-bootstrap";
import "./rc-carousel.scss";

function RcCarousel({ data, fxType, introTextComponent, ...rest }) {
  return (
    <div className="carousel-wrapper">
      <Carousel {...rest} fade>
        {data.map((o, i) => (
          <Carousel.Item
            key={o.id}
            interval={o.interval}
            className={`${fxType}-fx`}
          >
            <img
              className="d-block w-100 carousel-image"
              src={o.image}
              alt={o.alt}
            />
            {o.heading && (
              <Carousel.Caption>
                <h3>{o.heading}</h3>
                <p>{o.description}</p>
              </Carousel.Caption>
            )}
          </Carousel.Item>
        ))}
      </Carousel>
      {introTextComponent}
    </div>
  );
}

RcCarousel.propTypes = {
  data: PropTypes.array,
  fxType: PropTypes.string, // scale or normal
  introTextComponent: PropTypes.object,
};

RcCarousel.defaultProps = { data: [], fxType: "normal" };

export default React.memo(RcCarousel);
