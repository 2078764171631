import React, { useState } from "react";
import PropTypes from "prop-types";
import { ContentPane } from "components/ui/contentPane";
import SectionTitle from "components/ui/pageSection/SectionTitle";
import "./services.scss";
import { Accordion, Col, Image, Row } from "react-bootstrap";
import ServiceDetailModal from "./serviceDetails/ServiceDetailsModal";
import useRequestQuotation from "hooks/useRequestQuotation";
import ServiceItem2 from "components/ui/serviceItem/ServiceItem2";
import ServicesIcon from "./ServiceIcon/ServiceIcon";
import { useDispatch, useSelector } from "react-redux";
import SectionHeader from "./sectionHeader/SectionHeader";
import * as CommonDataSlice from "store/modules/commonDataSlice";

function Services({ onQuoteNow, onAddToQuote }) {
  const { productServices } = useSelector(CommonDataSlice.selectCommonData);

  //const { showRequestQuotation } = useRequestQuotation();

  const [serviceDetailOptions, setServiceDetailOptions] = useState({
    isOpen: false,
    type: "",
  });

  const showDetails = (type) => {
    setServiceDetailOptions({
      isOpen: true,
      type,
    });
  };

  const hideDetails = () => {
    setServiceDetailOptions({
      isOpen: false,
      type: "",
    });
  };

  return (
    <ContentPane
      id="our-service"
      isFullWidth={true}
      hasShadow={false}
      containerCss="services-wrapper mt-3"
      contentPaddingHorizontal="3"
      contentPaddingVertical="3"
      mainContentCss="section-content-wrapper px-0 px-md-5  py-5"
    >
      <ServiceDetailModal
        isOpen={serviceDetailOptions.isOpen}
        type={serviceDetailOptions.type}
        onHide={hideDetails}
      />

      <SectionTitle
        title="Our Services"
      //subTitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
      //tempor incididunt ut labore"
      />

      <Accordion
        defaultActiveKey={["0", "1", "2"]}
        className="mt-5"
        alwaysOpen
        flush
      >
        {productServices.map((service, i) => {
          return (
            <Accordion.Item eventKey={`${i}`}>
              <Accordion.Header>
                <strong id={`service${i + 1}`}>
                  {`0${i + 1}`} &nbsp;&nbsp;&nbsp;{service.title}
                </strong>
              </Accordion.Header>
              <Accordion.Body>
                <SectionHeader
                  title={`${service.title}`}
                  imageUrl={`images/services/image${i}.webp`}
                  alt="Termite control solution"
                />
                <div className="d-flex flex-column flex-lg-row justify-content-center flex-wrap">
                  {service.items.map((o) => (
                    <ServiceItem2
                      key={o.id}
                      title={o.title}
                      description={o.description}
                      titleHandler={
                        o.hasFuncHandler ? () => showDetails(o.code) : null
                      }
                      containerCss="service-item p-0 p-md-2"
                      buttonText="Quote Now"
                      buttonText2="Add to Quote"
                      imageUrl={o.imageUrl}
                      tag={o.tag}
                      onPrimaryButtonClick={() =>
                        onQuoteNow(service.id, service.title, o.id, o.title)
                      }
                      onSecondaryButtonClick={() =>
                        onAddToQuote(service.id, service.title, o.id, o.title)
                      }
                    />
                  ))}
                </div>
              </Accordion.Body>
            </Accordion.Item>
          );
        })}
      </Accordion>
    </ContentPane>
  );
}

Services.propTypes = {
  onQuoteNow: PropTypes.func,
  onAddToQuote: PropTypes.func,
};

Services.defaultProps = {};

export default Services;
