import { isNil } from "lodash";

function useFileUploaderUtils() {
  const getFormData = (files, fileOwner, mode) => {
    if (isNil(fileOwner)) throw new Error("File owner argument is required.");

    let formData = new FormData();
    let metadata = [];

    files.forEach((file) => {
      metadata.push({
        clientRefId: file.clientRefId,
        filename: file.name,
        fileOwner,
      });
    });

    formData.append("metadata", JSON.stringify(metadata));
    formData.append("fileOwner", fileOwner);
    formData.append("isImage", mode === "Image");

    files.forEach((file) => {
      formData.append("formFiles", file.data, file.clientRefId);
    });

    return formData;
  };

  return { getFormData };
}

export default useFileUploaderUtils;
