import React from "react";
import { ErrorMessage } from "formik";
import "./form-validation-error.scss";

const FormikValidationMessage = ({ name, containerCss }) => (
  <div className={containerCss}>
    <ErrorMessage name={name} component="div">
      {(msg) => <div className="has-error tsmall1 mt-1">{msg}</div>}
    </ErrorMessage>
  </div>
);

export default FormikValidationMessage;
