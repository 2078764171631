import React from "react";
import PropTypes from "prop-types";
import RcHelmet from "components/ui/rcHelmet/RcHelmet";
import { isEmpty, isNil, startCase, toLower } from "lodash";

function SeoTags({ title }) {
  return (
    <RcHelmet
      title={`${
        isNil(title) || isEmpty(title)
          ? "Vista Estates South"
          : `${startCase(toLower(title))} - Vista Estates South`
      }`}
      description="Vista Land is the one of the leading integrated property developers in the Philippines and the largest homebuilder in the country overall. It was incorporated on 28 February 2007 and was listed on the Philippine Stock Exchange on 25 June 2007. It is the market leader by far in terms of total number of houses built, having built over 400,000 homes in 49 provinces, and 147 cities and municipalities in Luzon, Visayas and Mindanao."
      keywords="Real Estate, Condo, Property, High Rise"
      ogTitle="Vista Estate South"
      ogUrl="https://vistaestatesouth.com"
      ogImage="https://rcnetlabs-imaging.sirv.com/Vista/fb-og-image.webp"
      ogType="website"
      ogDescription="Vista Estate South - Contact us for any business inquiries"
      ogLocale="en_US"
    >
      <meta property="og:image:width" content="1030" />
      <meta property="og:image:height" content="630" />
    </RcHelmet>
  );
}

SeoTags.propTypes = {
  title: PropTypes.string,
  containerCss: PropTypes.string,
};

SeoTags.defaultProps = { title: null };

export default SeoTags;
