import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

function RcHelmet({
  title,
  description,
  keywords,
  ogTitle,
  ogUrl,
  ogImage,
  ogType,
  ogDescription,
  ogLocale,
  children,
}) {
  return (
    <Helmet>
      {title && <title>{title}</title>}
      {description && <meta name="description" content={description} />}
      {keywords && <meta name="keywords" content={keywords} />}
      <meta name="robots" content="max-image-preview:large" />
      <link rel="canonical" href={ogUrl} />
      {/* {ogTitle && <meta property="og:title" content={ogTitle} />}
      {ogUrl && <meta property="og:url" content={ogUrl} />}
      {title && <meta property="og:site_name" content={title} />}
      {ogImage && <meta property="og:image" content={ogImage} />}
      {ogType && <meta property="og:type" content={ogType} />}
      {ogDescription && (
        <meta property="og:description" content={ogDescription} />
      )}
      {ogLocale && <meta property="og:locale" content={ogLocale} />}
      {ogUrl && <meta property="og:secure_url" content={ogUrl} />} */}
      {title && <meta name="twitter:card" content="summary" />}
      {title && <meta name="twitter:title" content={title} />}
      {description && <meta name="twitter:description" content={description} />}
      {ogImage && <meta name="twitter:image" content={ogImage} />}
      {children}
    </Helmet>
  );
}

RcHelmet.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  keywords: PropTypes.string,
  ogTitle: PropTypes.string,
  ogUrl: PropTypes.string,
  ogImage: PropTypes.string,
  ogType: PropTypes.string,
  ogDescription: PropTypes.string,
  ogLocale: PropTypes.string,
};

RcHelmet.defaultProps = {};

export default RcHelmet;
