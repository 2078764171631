import React from "react";
import PropTypes from "prop-types";
import { FormInput, FormPhoneInput } from "components/ui/formikFields";
import { Row } from "react-bootstrap";

function FormInputs({ containerCss }) {
  return (
    <section className={`${containerCss}`}>
      <Row>
        <FormInput
          name="fname"
          label="First name *"
          labelBold={true}
          showRequiredLabel={false}
          floatingLabel={true}
          xs={12}
          md={6}
        />
        <FormInput
          name="lname"
          label="Last name *"
          labelBold={true}
          showRequiredLabel={true}
          floatingLabel={true}
          xs={12}
          md={6}
        />
      </Row>
      <Row>
        <FormInput
          name="email"
          label="Email *"
          labelBold={true}
          showRequiredLabel={true}
          floatingLabel={true}
          xs={12}
          md={6}
        />
        {/* <FormInput
          name="phone"
          label="Phone Number *"
          labelBold={true}
          showRequiredLabel={true}
          floatingLabel={true}
          xs={12}
          md={6}
        /> */}
        <FormPhoneInput
          name="phone"
          label="Mobile Number *"
          mask="mobile"
          floatingLabel={true}
          xs={12}
          md={6}
        />
      </Row>
      <Row>
        <FormInput
          name="company"
          label="Company"
          labelBold={true}
          showRequiredLabel={true}
          floatingLabel={true}
          xs={12}
        />
      </Row>
      <Row>
        <FormInput
          type="textarea"
          name="message"
          label="Message"
          labelBold={true}
          showRequiredLabel={true}
          floatingLabel={true}
          xs={12}
        />
      </Row>
    </section>
  );
}

FormInputs.propTypes = {
  containerCss: PropTypes.string,
};

FormInputs.defaultProps = {};

export default FormInputs;
