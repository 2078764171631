import React from "react";
import PropTypes from "prop-types";
import { noop } from "lodash";
import Segmented from "rc-segmented";
import "./segmented.scss";

function RcSegmented({ data, containerCss, onSegmentedClick }) {
  return (
    <section className="rc-segmented-wrapper bold-selected">
      <Segmented
        options={data}
        className={`${containerCss} `}
        onChange={onSegmentedClick}
      />
    </section>
  );
}

RcSegmented.propTypes = {
  data: PropTypes.array,
  containerCss: PropTypes.string,
  onSegmentedClick: PropTypes.func,
};

RcSegmented.defaultProps = { onSegmentedClick: noop };

export default RcSegmented;
