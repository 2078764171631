import React from "react";
import PropTypes from "prop-types";
import { Link, useLocation, useMatch, useResolvedPath } from "react-router-dom";

function HashNavLink({ children, to, ...props }) {
  const resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: true });
  const location = useLocation();

  // If hash link then handle it below
  if (resolved.hash) {
    match =
      location.pathname === resolved.pathname &&
      location.hash === resolved.hash;
  }

  return (
    <Link
      to={to}
      {...props}
      className={`${props.className} ${match ? "active" : ""}`}
    >
      {children}
    </Link>
  );
}

HashNavLink.propTypes = {
  containerCss: PropTypes.string,
};

HashNavLink.defaultProps = {};

export default React.memo(HashNavLink);
