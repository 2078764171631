import useScrollNavigate from "hooks/useScrollNavigate";
import { useMemo, useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToHashElement = () => {
  let location = useLocation();
  const { scrollToSectionOffset } = useScrollNavigate(true, 100);

  let hashElement = useMemo(() => {
    let hash = location.hash;
    const removeHashCharacter = (str) => {
      const result = str.slice(1);
      return result;
    };

    if (hash) {
      let element = document.getElementById(removeHashCharacter(hash));
      return element;
    } else {
      return null;
    }
  }, [location]);

  useEffect(() => {
    if (hashElement) {
      setTimeout(() => {
        scrollToSectionOffset(`#${hashElement.id}`, 70, null, false);
      }, 100);
    }
  }, [hashElement, scrollToSectionOffset]);

  return null;
};

export default ScrollToHashElement;
