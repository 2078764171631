import React from "react";
import PropTypes from "prop-types";
import Gmap from "components/ui/gmap/Gmap";
import Config from "config";
import SectionTitle from "components/ui/pageSection/SectionTitle";
import { ContentPane } from "components/ui/contentPane";
import { Row, Col } from "react-bootstrap";
import "./client-map.scss";

function ClientMap({ containerCss }) {
  return (
    <ContentPane
      id="location"
      isFullWidth={true}
      hasShadow={false}
      containerCss="client-map-wrapper"
      contentCss="content-box"
      contentPaddingHorizontal="3"
      contentPaddingVertical="3"
      mainContentCss="main-content py-5"
    >
      <SectionTitle
        title="Our Location"
        subTitle="263 15th Ave, Cubao, Quezon City, Metro Manila"
      />
      <Row className="section-content">
        <Col xs={12}>
          <Gmap settings={Config.gmap} zoomLevel={20} />
          <h4 className="tsmall1 text-center my-3 text-muted">
            Drag the yellow Pegman onto the map to open Street View.
          </h4>
        </Col>
      </Row>
    </ContentPane>
  );
}

ClientMap.propTypes = {
  containerCss: PropTypes.string,
};

ClientMap.defaultProps = {};

export default ClientMap;
