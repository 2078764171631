export const FixedNavOptions = Object.freeze({
  TOP: "top",
  BOTTOM: "bottom",
  BURGER_MENU_ONLY: "burger",
});

export const MenuPlacementOptions = Object.freeze({
  START: "start",
  END: "end",
  CENTER: "center",
});
