import moment from "moment";
import isNil from "lodash/isNil";
import Config from "config";

export const toIsoDate = (date) => {
  let dt = moment.isMoment(date) ? date.toDate() : date;

  return isNil(dt) ||
    moment(dt, "DD/MM/YYYY").format("DD/MM/YYYY").toUpperCase() ===
      "01 JAN 1900"
    ? ""
    : moment(dt, "DD/MM/YYYY").format("YYYY-MM-DD HH:mm:ss").toUpperCase();
};

export const getDateOneWeekAgo = () => {
  var currentDate = new Date();
  var pastDate = currentDate.getDate() - 6;
  currentDate.setDate(pastDate);

  return currentDate;
};

export const getDateMonthAgo = (numberOfMonth) => {
  let currentDate = new Date();
  currentDate.setMonth(currentDate.getMonth() - numberOfMonth);

  return currentDate;
};

export const getDateRangeOfAWeekAgo = () => {
  var startOfWeek = moment().subtract(1, "weeks").startOf("week").toDate();
  var endOfWeek = moment().subtract(1, "weeks").endOf("week").toDate();

  return { startOfWeek, endOfWeek };
};

/**
 * Valid values from 0 - Sunday to 6 - Saturday
 * @param {*} startDayOfWeek
 * @returns
 */
export const getDateRangeOfAWeekAgoByCustomStartWeek = (startDayOfWeek) => {
  moment.updateLocale("en", {
    week: {
      dow: startDayOfWeek, // 0 to 6 sunday to saturday
    },
  });

  var startOfWeek = moment().subtract(1, "weeks").startOf("week").toDate();
  var endOfWeek = moment().subtract(1, "weeks").endOf("week").toDate();

  // Revert to orig settings
  moment.updateLocale("en", {
    week: {
      dow: 0, // 0 to 6 sunday to saturday
    },
  });

  return { startOfWeek, endOfWeek };
};

export const toShortDate = (date) => {
  //let result = moment(date).format("DD/MM/YYYY");
  let result = moment(date).format(Config.dateTimeSettings.shortDateFormat);
  return result === "Invalid date" ? date : result;
};

export const toShortIsoDate = (date) => {
  let result = moment(date, Config.dateTimeSettings.shortDateFormat).format(
    Config.dateTimeSettings.toShortIsoDate
  );
  return result === "Invalid date" ? date : result;
};

/**
 * Accepts different kinds of Time input pattern like
 * 12pm, 14, 2347, 9am, 10 am etc..
 *
 * Returns date time object
 * @param {*} timeString
 */
export const getTimeFromString = (timeString) => {
  const timeFormat1Regex =
    /^(0[1-9]{1}|1[0-2]|[1-9])[\.:\-]([0-5][0-9])(\ )*([ap][mn])$/im;
  const timeFormat2Regex =
    /^(0[0-9]{1}|1[0-9]|2[0-3]|[1-9])[\.:\-]*([0-5][0-9])$/im;
  const timeFormat3Regex = /^(0[1-9]{1}|1[0-2]|[1-9])(\ )*([ap][mn])$/im;
  const timeFormat4Regex = /^(0[0-9]{1}|1[0-9]|2[0-3]|[1-9])$/im;

  let hours = 0;
  let minutes = 0;

  if (timeFormat1Regex.test(timeString)) {
    const matches = timeFormat1Regex.exec(timeString);

    hours = parseInt(matches[1]);
    minutes = parseInt(matches[2]);

    const period = matches[4].toLowerCase();

    if (hours !== 12 && (period === "pm" || period === "pn")) hours += 12;
    else if (hours === 12 && (period === "am" || period === "an")) hours = 0;
  } else if (timeFormat2Regex.test(timeString)) {
    const matches = timeFormat2Regex.exec(timeString);

    hours = parseInt(matches[1]);
    minutes = parseInt(matches[2]);
  } else if (timeFormat3Regex.test(timeString)) {
    const matches = timeFormat3Regex.exec(timeString);

    hours = parseInt(matches[1]);

    const period = matches[3].toLowerCase();

    if (hours !== 12 && (period === "pm" || period === "pn")) hours += 12;
    else if (hours === 12 && (period === "am" || period === "an")) hours = 0;
  } else if (timeFormat4Regex.test(timeString)) {
    const matches = timeFormat4Regex.exec(timeString);
    hours = parseInt(matches[1]);
  } else return null; // if the time string is not valid, return null

  const dt = new Date();
  const dtYear = dt.getFullYear();
  const dtMonth = dt.getMonth() + 1;
  const dtDate = dt.getDate();

  return new Date(dtYear, dtMonth, dtDate, hours, minutes, 0);
  //return new Date(0, 0, 1, hours, minutes, 0);
};

export const getDateTimeOffset = (dt) => {
  return moment(dt).format();
};
