import { useMediaQuery } from "react-responsive";
import PropTypes from "prop-types";

const Desktop = ({ children, query }) => {
  const isDesktop = useMediaQuery(query);
  return isDesktop ? children : null;
};

Desktop.propTypes = {
  query: PropTypes.object,
};

Desktop.defaultProps = { query: { minWidth: 992 } };

export default Desktop;
