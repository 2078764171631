/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";

const NavigateScrollToTop = ({ exclude }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (exclude.includes(pathname)) return;
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

NavigateScrollToTop.propTypes = {
  exclude: PropTypes.array,
};

NavigateScrollToTop.defaultProps = {
  exclude: [],
};

export default NavigateScrollToTop;
