import Http from "core/http/http";

function BrandService() {
  const apiUrl = "/v1/brands";

  const getBrandsAsync = () => Http().get(`${apiUrl}/items`);

  return {
    getBrandsAsync,
  };
}

export default BrandService;
