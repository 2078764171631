/**
 * Interceptor
 * rcnetlabs
 */

import axios from "axios";
import { noop } from "lodash";
import Auth from "../auth/auth";
import Config from "../../config";
import { HTTP_TIMEOUT } from "./constants";
import CurrentUser from "core/auth/currentUser";

const AUTH_TOKEN_URL = "/v1/authentication/token";

var authTokenRequestPromise;

const HttpInterceptor = ({ onNetworkError, onReLogin, onForbiddenAccess }) => {
  const http = axios.create({ baseURL: Config.apiUrl });
  http.defaults.timeout = HTTP_TIMEOUT;

  /**
   * Interceptor Request
   * @param {*} config
   * @returns
   */
  const interceptorRequestAuth = (config) => {
    const token = Auth.getToken();
    if (token != null) config.headers.Authorization = `Bearer ${token}`;

    return config;
  };

  /**
   * Interceptor Response
   * AuthTokenRequestPromise plays a very important role in this code base, it handles concurrent/parallel requests which puts into queue.
   * To prevent multiple refreshtoken request.
   * @param {*} error
   * @returns
   */
  const interceptorResponseError = (error) => {
    const err = error.response;

    console.log(err);

    // Check if api server is down
    if (
      (error.message === "Network Error" && err === undefined) ||
      error?.code === "ECONNABORTED"
    ) {
      (onNetworkError || noop)();

      return Promise.reject(error);
    }

    if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
      return _getAuthToken()
        .then((resp) => {
          Auth.setToken(
            resp.data.access_token,
            resp.data.refresh_token,
            resp.data.expires_in
          );

          console.log("Successfully requested access token...");
          err.config.headers.Authorization = `Bearer ${resp.data.access_token}`;
          console.log("Replaying request.");

          return axios.request(err.config);
        })
        .catch((e) => {
          let ex = e.response;

          if (ex.status === 400 && ex.data && ex.data.invalid_refreshtoken) {
            console.log(
              "Invalid refresh token. Web App redirecting to login page."
            );

            Auth.removeToken();
            (onReLogin || noop)();
          }

          return Promise.reject(e);
        });
    } else if (err.status === 403) {
      (onForbiddenAccess || noop)();

      let newErrObj = Object.assign({}, error);
      newErrObj.response.data["Message"] = "Unable to access page.";

      return Promise.reject(newErrObj);
    } else {
      return Promise.reject(error);
    }
  };

  /**
   * Request new Access Token
   */
  const _getAuthToken = () => {
    if (!authTokenRequestPromise) {
      console.log("Requesting new access token...");

      const userName = CurrentUser().getUserName();

      const refreshTokenPayload = Auth.getRefreshTokenPayload(Config.clientId, userName);

      // Perform dual guard to prevent thread Race Condition when requesting new Access Token.
      if (!authTokenRequestPromise) {
        authTokenRequestPromise = http.post(
          AUTH_TOKEN_URL,
          refreshTokenPayload
        );

        console.log("Access token requested...");

        authTokenRequestPromise
          .then(_resetAuthTokenRequestPromise)
          .catch(_logAndResetAuthTokenRequestPromise);
      }
    }

    return authTokenRequestPromise;
  };

  /**
   * Reset singleton token promise
   */
  const _resetAuthTokenRequestPromise = () => {
    console.log("authTokenRequestPromise reset...");
    authTokenRequestPromise = null;
  };

  const _logAndResetAuthTokenRequestPromise = (err) => {
    console.log("authTokenRequestPromise reset...");
    console.error(err);
    authTokenRequestPromise = null;
  };

  return {
    interceptorRequestAuth,
    interceptorResponseError,
  };
};

export default HttpInterceptor;
