import React from "react";
import { PropTypes } from "prop-types";
import "./content-pane.scss";

const ContentPane = ({
  id,
  children,
  containerCss,
  containerStyle,
  contentCss,
  contentStyle,
  mainContentCss,
  mainContentStyle,
  contentPaddingHorizontal,
  contentPaddingVertical,
  isContentNoPadding,
  isFullWidth,
  mainContentWithinContainerSize,
  hasShadow,
}) => {
  let innerContentCss = isFullWidth
    ? "rca-content-wrapper full-width"
    : "rca-content-wrapper";

  let contentPaddingCss = isContentNoPadding
    ? "no-padding"
    : `py-${contentPaddingVertical} px-${contentPaddingHorizontal}`;

  // If full width and content need to respect content/container width.
  let defaultMainContentParentContainerCss =
    isFullWidth && mainContentWithinContainerSize
      ? "d-flex flex-row justify-content-center"
      : "";
  let defaultMainContentCss =
    isFullWidth && mainContentWithinContainerSize
      ? "main-content-wrapper w-100"
      : "";

  return (
    <section
      id={id}
      className={`rca-page ${containerCss}`}
      style={containerStyle}
    >
      <div
        className={`container-fluid ${defaultMainContentParentContainerCss} ${innerContentCss} ${contentPaddingCss} ${contentCss} ${
          hasShadow ? "shadow-lg" : ""
        }`}
        style={contentStyle}
      >
        <div
          className={`${defaultMainContentCss} ${mainContentCss}`}
          style={mainContentStyle}
        >
          {children}
        </div>
      </div>
    </section>
  );
};

ContentPane.propTypes = {
  id: PropTypes.string,
  containerCss: PropTypes.string,
  containerStyle: PropTypes.object,
  contentCss: PropTypes.string,
  contentStyle: PropTypes.object,
  mainContentCss: PropTypes.string,
  mainContentStyle: PropTypes.object,
  contentPaddingHorizontal: PropTypes.string,
  contentPaddingVertical: PropTypes.string,
  isContentNoPadding: PropTypes.bool,
  isFullWidth: PropTypes.bool,
  mainContentWithinContainerSize: PropTypes.bool,
  hasShadow: PropTypes.bool,
};

ContentPane.defaultProps = {
  //containerCss: "py-4",
  contentCss: "", //"shadow-lg",
  contentPaddingHorizontal: "5",
  contentPaddingVertical: "5",
  isContentNoPadding: false,
  isFullWidth: false,
  mainContentWithinContainerSize: true,
};

export default ContentPane;
