import React from "react";
import PropTypes from "prop-types";
import "./cookie-policy.scss";
import { Card, CloseButton } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { motion } from "framer-motion";
import { NavLink } from "react-router-dom";

function CookiePolicy({
  title,
  cookieText,
  show,
  containerCss,
  onCloseCookie,
}) {
  const variants = {
    show: {
      opacity: 1,
      x: 0,
      transition: {
        ease: "easeOut",
        duration: 0.4,
      },
    },
    hide: {
      x: "-40vh",
      opacity: 0,
    },
  };

  const handleCloseCookie = () => {
    onCloseCookie();
  };

  if (!show) return null;

  return (
    <motion.div
      key={show}
      variants={variants}
      animate="show"
      initial="hide"
      className={`cookie-policy-wrapper ${containerCss}`}
    >
      <Card className="shadow policy-card">
        <Card.Body>
          <Card.Title className="d-flex flex-row justify-content-center align-items-center py-2 card-title">
            <div className="ms-auto me-auto">{title}</div>
            <CloseButton onClick={handleCloseCookie} />
          </Card.Title>
          <Card.Text className="card-text-container tsmall pb-2">
            <PerfectScrollbar>
              <div>{cookieText}</div>
              <NavLink to="/cookie-policy">Details here</NavLink>
            </PerfectScrollbar>
          </Card.Text>
        </Card.Body>
      </Card>
    </motion.div>
  );
}

CookiePolicy.propTypes = {
  cookieText: PropTypes.string,
  cookieTitle: PropTypes.string,
  containerCss: PropTypes.string,
};

CookiePolicy.defaultProps = {
  title: "Yet another cookie disclaimer!",
  cookieText:
    " This website uses cookies to improve your experience while you navigate through the website. Out of these cookies, the cookies that are categorized as necessary are stored on your browser as they are essential for the working of basic functionalities of the website. We also use third-party cookies that help us analyze and understand how you use this website.",
};

export default CookiePolicy;
