import React from "react";
import PropTypes from "prop-types";
import BasicModal from "../basicModal/BasicModal";

function ConfirmModal({
  title,
  children,
  okButtonType,
  okButtonText,
  cancelButtonType,
  cancelButtonText,
  ...rest
}) {
  return (
    <BasicModal
      {...rest}
      title={title}
      size="md"
      okButtonText={okButtonText}
      okButtonType={okButtonType}
      cancelButtonText={cancelButtonText}
      cancelButtonType={cancelButtonType}
    >
      {children}
    </BasicModal>
  );
}

ConfirmModal.propTypes = {
  title: PropTypes.string,
  okButtonType: PropTypes.string,
  okButtonText: PropTypes.string,
  cancelButtonType: PropTypes.string,
  cancelButtonText: PropTypes.string,
  children: PropTypes.any,
  onOkButton: PropTypes.func,
};

ConfirmModal.defaultProps = {
  title: "Confirm",
  okButtonType: "primary",
  okButtonText: "Yes",
  cancelButtonType: "light", //"outline-secondary",
  cancelButtonText: "No",
};

export default ConfirmModal;
