import React from "react";
import PropTypes from "prop-types";
import { Dropdown, Image } from "react-bootstrap";
import useMediaQueryExt from "hooks/useMediaQueryExt";

function ProfileMenu({name, imageSrc, containerCss }) {
  const { isMobile } = useMediaQueryExt();

  return (
    <section className={`profile-menu-wrapper ${containerCss}`}>
      <Dropdown>
        <Dropdown.Toggle
          id="dropdown-button-dark-example1"
          variant="light"
          as="div"
          className="d-flex flex-row align-items-center profile-btn"
        >
          {!isMobile && <div className="fw-600 mx-2">{name}</div>}
          <Image src={imageSrc} className="small-avatar" roundedCircle />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item href="#/action-1">
            My Profile
          </Dropdown.Item>
          <Dropdown.Item href="#/action-2">Settings</Dropdown.Item>
          <Dropdown.Divider /> 
          <Dropdown.Item href="#/action-3">Sign out</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </section>
  );
}

ProfileMenu.propTypes = {
  containerCss: PropTypes.string,
  name: PropTypes.string,
  imageSrc: PropTypes.string,
};

ProfileMenu.defaultProps = {};

export default ProfileMenu;
