import React, { useContext } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import navBarMenus from "data/navbar-menus";
import Config from "config";
import RcScrollToTop from "components/ui/rcScrollToTop/RcScrollToTop";
import NavHeaderContextProvider, {
  NavHeaderContext,
} from "components/ui/navigationHeader/context/NavHeaderContextProvider";
import OffCanvasCustomMenus from "components/features/pluggableMenus/offCanvasCustomMenus/OffCanvasCustomMenus";
import RcNavBarFx1 from "components/ui/rcNavBar/RcNavBarFx1";
import NavBarCustomButton from "components/features/navBarCustomButton/NavBarCustomButton";
import { useAppTheme } from "hooks";
import "./shop-layout.scss";

const ContentComponent = ({ keyId }) => {
  const headerContext = useContext(NavHeaderContext);

  return (
    <section
      className={`${
        headerContext.isFixedMode()
          ? "content-wrapper-fixed-header shop-layout-wrapper"
          : "content-wrapper shop-layout-wrapper"
      }`}
    >
      <main role="main">
        <Outlet />
      </main>
    </section>
  );
};

const ShopLayout = () => {
  const location = useLocation();
  const { currentTheme } = useAppTheme();

  return (
    <NavHeaderContextProvider>
      <div className={`app-${currentTheme} main-shop-layout-wrapper`}>
        <RcNavBarFx1
          variant="light"
          menus={navBarMenus.menus}
          brandLink="/"
          brandLogo="https://rcnetlabs-imaging.sirv.com/Vista/logo/header-logo-white.png"
          brandLogoHeight={90}
          brandLogoAlternateText="Vista Land logo"
          offCanvasBrandLogo="https://rcnetlabs-imaging.sirv.com/Vista/logo/header-logo-colored.png"
          fixed="top"
          menuPlacement="end"
          burgerMenuColor="#0d4e90"
          isOffCanvasMenu={true}
          scrollTriggerValue={80}
          offCanvasCustomComponent={<OffCanvasCustomMenus />}
          navBarRightPane={(e) => {
            return <NavBarCustomButton showOffCanvas={e?.showOffCanvas} />;
          }}
        />
        <ContentComponent keyId={location.key} />
        <RcScrollToTop />
      </div>
    </NavHeaderContextProvider>
  );
};

export default ShopLayout;
