import React from "react";
import PropTypes from "prop-types";
import InputField from "components/ui/formFields/inputField/InputField";
import { Row } from "react-bootstrap";
import RcFileUpload from "components/ui/formFields/fileUpload/RcFileUpload";

function FormInputDemo({
  data,
  containerCss,
  onUpload,
  onRemove,
  onActivityInProgress,
}) {
  return (
    <section className={containerCss}>
      <InputField
        type="text"
        name="firstName"
        label="First name"
        floatingLabel={true}
        value="Ryu Hoshi"
        plaintext
      />
      <InputField
        type="password"
        name="password"
        label="Password"
        value="blahblah"
        plaintext
        helpText="Your password must be 8-20 characters long, contain letters and numbers, and must not contain spaces, special characters, or emoji."
      />
      <InputField
        type="textarea"
        name="comments"
        label="Comments"
        value="The quick brown fox jumps over the lazy dog."
        floatingLabel={true}
        //plainText
      />
      <Row>
        <InputField
          type="text"
          name="gitUrl"
          label="Git Url"
          floatingLabel={true}
          value="https://github.com/rcnet"
        />
        <InputField
          type="text"
          name="cdn"
          label="CDN"
          floatingLabel={true}
          value="blah"
        />
      </Row>
      <RcFileUpload
        initialFiles={data}
        mode="Image"
        //mode="File"
        onUpload={onUpload}
        onRemove={onRemove}
        onActivityInProgress={onActivityInProgress}
      />
    </section>
  );
}

FormInputDemo.propTypes = {
  containerCss: PropTypes.string,
  data: PropTypes.array,
};

FormInputDemo.defaultProps = { data: [] };

export default FormInputDemo;
