import React from "react";
import PropTypes from "prop-types";
import useFastField from "../hooks/useFastField";
import RcFileUpload from "components/ui/formFields/fileUpload/RcFileUpload";
import { Form } from "react-bootstrap";
import { noop } from "lodash";
import ValidationMessage from "../validationMessage/ValidationMessage";

function FormUpload({
  name,
  validate,
  initialFiles,
  containerCss,
  label,
  labelBold,
  showLabel,
  showRequiredLabel,
  plaintext,
  onUpload,
  onRemove,
  onActivityInProgress,
  ...rest
}) {
  const { field, meta, helpers } = useFastField({ name, validate });

  const onChange = (value) => helpers.setValue(value);

  return (
    <>
      {showLabel && (
        <div className="d-inline">
          <Form.Label
            className={`label-field-style ${
              labelBold ? "fw-bold" : "fw-normal"
            }`}
          >
            {label}
          </Form.Label>
          {showRequiredLabel && <span className="text-danger ms-1">*</span>}
        </div>
      )}
      <RcFileUpload
        initialFiles={initialFiles}
        {...rest}
        onUpload={onUpload}
        onRemove={onRemove}
        onChange={onChange}
        onActivityInProgress={onActivityInProgress}
      />
      <ValidationMessage errorMessage={meta.error} forceDisplay={true} />
    </>
  );
}

FormUpload.propTypes = {
  name: PropTypes.string,
  validate: PropTypes.func,
  containerCss: PropTypes.string,
  labelBold: PropTypes.bool,
  showRequiredLabel: PropTypes.bool,
  label: PropTypes.string,
  showLabel: PropTypes.bool,
  plaintext: PropTypes.bool,
  onUpload: PropTypes.func,
  onRemove: PropTypes.func,
  onActivityInProgress: PropTypes.func,
};

FormUpload.defaultProps = {
  showLabel: true,
  onUpload: noop,
  onRemove: noop,
  onActivityInProgress: noop,
};

export default FormUpload;
