import React from "react";
import { FastField } from "formik";
import DatePicker from "react-datetime";
import PropTypes from "prop-types";
import "./form-date-picker.scss";
import moment from "moment";
import FormikValidationMessage from "../validationMessage/FormikValidationMessage";
import Label from "components/ui/formFields/label/Label";
import Config from "config";
import { PlainText } from "components/ui/formFields";
import useFastField from "../hooks/useFastField";

const FormDatePicker = ({
  name,
  dateFormat,
  label,
  labelBold,
  showRequiredLabel,
  placeholder,
  validate,
  showDatesBy,
  isMonthPicker,
  containerCss,
  plaintext,
  formatValueOnChange,
}) => {
  const { field, meta, helpers } = useFastField({ name });

  const getDateFormat = () => {
    if (isMonthPicker) return Config.dateTimeSettings.monthYearFormat;
    return dateFormat == null
      ? Config.dateTimeSettings.shortDateFormat
      : dateFormat;
  };

  const getPlaceholder = () => {
    return placeholder == null ? getDateFormat().toLowerCase() : placeholder;
  };

  // Disable past dates
  const yesterday = moment().subtract(1, "day");
  const disablePastDates = (current) => {
    return current.isAfter(yesterday);
  };

  // Disable future dates
  const today = moment();
  const disableFutureDates = (current) => {
    return current.isBefore(today);
  };

  const dateValidationOption = () => {
    switch (showDatesBy) {
      case "pastDate":
        return disableFutureDates;
      case "futureDate":
        return disablePastDates;
      default:
        return () => true;
    }
  };

  const formatPlainText = () => {
    let result = null;

    if (isMonthPicker) {
      result = moment(field.value).format("MMM YYYY");
    } else {
      result = moment(field.value).format(
        Config.dateTimeSettings.shortDateFormat
      );
    }

    return result === "Invalid date" ? "--" : result;
  };

  if (plaintext) {
    return (
      <PlainText type="datepicker" label={label} value={formatPlainText()} />
    );
  }

  return (
    <section className={`mb-3 ${containerCss}`}>
      <Label
        label={label}
        labelBold={labelBold}
        showRequiredLabel={showRequiredLabel}
        htmlFor={name}
      />
      <FastField name={name} validate={validate}>
        {({ field, form }) => (
          <div>
            <DatePicker
              {...field}
              timeFormat={false}
              dateFormat={getDateFormat()}
              id={name}
              name={name}
              value={field.value}
              inputProps={{
                placeholder: getPlaceholder(),
              }}
              isValidDate={dateValidationOption()}
              onChange={(value) => {
                let dateValue = value;
                if (value instanceof Date) {
                  dateValue = moment(value);
                }

                dateValue = formatValueOnChange
                  ? formatValueOnChange(dateValue)
                  : dateValue;
                form.setFieldValue(name, dateValue);
              }}
              onBlur={() => form.setFieldTouched(name, true)}
            />
            <FormikValidationMessage name={name} />
          </div>
        )}
      </FastField>
    </section>
  );
};

FormDatePicker.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  showLabel: PropTypes.bool,
  labelBold: PropTypes.bool,
  showRequiredLabel: PropTypes.bool,
  dateFormat: PropTypes.string,
  placeholder: PropTypes.string,
  showDatesBy: PropTypes.string, // options either "pastDate" or "futureDate",
  isMonthPicker: PropTypes.bool,
  containerCss: PropTypes.string,
  plaintext: PropTypes.bool,
  validate: PropTypes.func,
  formatValueOnChange: PropTypes.func,
};

FormDatePicker.defaultProps = {
  showLabel: true,
  labelBold: false,
  showRequiredLabel: false,
};

export default FormDatePicker;
