import React from "react";
import { FastField } from "formik";
import DatePicker from "react-datetime";
import PropTypes from "prop-types";
import "./form-time-picker.scss";
import moment from "moment";
import FormikValidationMessage from "../validationMessage/FormikValidationMessage";
import Label from "components/ui/formFields/label/Label";
import Config from "config";
import { PlainText } from "components/ui/formFields";
import useFastField from "../hooks/useFastField";
import { getDateTimeOffset, getTimeFromString } from "core/utils/dateLib";

const FormTimePicker = ({
  name,
  label,
  labelBold,
  showRequiredLabel,
  placeholder,
  validate,
  containerCss,
  plaintext,
}) => {
  const { field, meta, helpers } = useFastField({ name });

  const getPlaceholder = () => {
    return placeholder == null ? "hh:mm" : placeholder;
  };

  const formatPlainText = () => {
    let result = moment(field.value).format(
      Config.dateTimeSettings.shortTimeFormat
    );

    return result === "Invalid date" ? "--" : result;
  };

    if (plaintext) {
      return (
        <PlainText type="datepicker" label={label} value={formatPlainText()} />
      );
    }

  return (
    <section className={`mb-3 ${containerCss}`}>
      <Label
        label={label}
        labelBold={labelBold}
        showRequiredLabel={showRequiredLabel}
        htmlFor={name}
      />
      <FastField name={name} validate={validate}>
        {({ field, form }) => (
          <div>
            <DatePicker
              {...field}
              dateFormat={false}
              id={name}
              name={name}
              value={field.value}
              inputProps={{
                placeholder: getPlaceholder(),
              }}
              onChange={(value) => {
                const formattedValue = getTime(value);

                let timeDisplayValue = !moment.isDate(formattedValue)
                  ? moment(formattedValue, true)
                  : formattedValue;

                form.setFieldValue(name, timeDisplayValue);
              }}
              onBlur={() => form.setFieldTouched(name, true)}
            />
            <FormikValidationMessage name={name} />
          </div>
        )}
      </FastField>
    </section>
  );
};

// ===============
// Helpers
// ===============

const getTime = (value) => {
  let formattedValue = "";

  if (!moment.isMoment(value) && !moment.isDate(value)) {
    formattedValue = getTimeFromString(value);
  } else {
    formattedValue = getDateTimeOffset(
      moment.isMoment(value) ? value.toDate() : value
    );
  }

  return formattedValue;
};

FormTimePicker.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  labelBold: PropTypes.bool,
  showRequiredLabel: PropTypes.bool,
  placeholder: PropTypes.string,
  isMonthPicker: PropTypes.bool,
  containerCss: PropTypes.string,
  plaintext: PropTypes.bool,
  validate: PropTypes.func,
};

FormTimePicker.defaultProps = {
  showLabel: true,
  labelBold: false,
  showRequiredLabel: false,
};

export default FormTimePicker;
