import React from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import { RcButton } from "components/ui/rcButton";

function BasicModal({
  isOpen,
  onHide,
  title,
  children,
  okButtonType,
  okButtonText,
  showCancel,
  cancelButtonType,
  cancelButtonText,
  disableOkButton,
  size,
  backdrop,
  centered,
  containerCss,
  buttonIsAlignLeft,
  showFooter,
  onOkButton,
  ...rest
}) {
  const handleHide = () => onHide("close");

  const handleCancelHide = () => onHide("cancel");

  return (
    <Modal
      {...rest}
      show={isOpen}
      size={size}
      onHide={handleHide}
      dialogClassName={containerCss}
      backdrop={backdrop}
      aria-labelledby="form-modal"
      centered={centered}
    >
      <Modal.Header closeButton>
        <Modal.Title id="form-modal">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      {showFooter && (
        <Modal.Footer
          className={
            buttonIsAlignLeft ? "d-flex flex-row justify-content-start" : ""
          }
        >
          <RcButton
            variant={okButtonType}
            disabled={disableOkButton}
            onClick={onOkButton}
          >
            {okButtonText}
          </RcButton>

          {showCancel ? (
            <RcButton
              variant={cancelButtonType}
              disabled={disableOkButton}
              onClick={handleCancelHide}
            >
              {cancelButtonText}
            </RcButton>
          ) : null}
        </Modal.Footer>
      )}
    </Modal>
  );
}

BasicModal.propTypes = {
  title: PropTypes.string,
  showCancel: PropTypes.bool,
  disableOkButton: PropTypes.bool,
  okButtonType: PropTypes.string,
  okButtonText: PropTypes.string,
  cancelButtonType: PropTypes.string,
  cancelButtonText: PropTypes.string,
  size: PropTypes.string,
  centered: PropTypes.bool,
  children: PropTypes.any,
  backdrop: PropTypes.string,
  buttonIsAlignLeft: PropTypes.bool,
  showFooter: PropTypes.bool,
  onOkButton: PropTypes.func,
};

BasicModal.defaultProps = {
  title: "Basic Modal",
  showCancel: true,
  disableOkButton: false,
  okButtonType: "primary",
  okButtonText: "Ok",
  cancelButtonType: "secondary",
  cancelButtonText: "Cancel",
  size: "lg",
  backdrop: "static",
  centered: false,
  buttonIsAlignLeft: false,
  showFooter: true,
  onOkButton: () => {},
};

export default BasicModal;
