import React from "react";
import PropTypes from "prop-types";
import MaskedInput from "react-text-mask";
import ValidationMessage from "../validationMessage/ValidationMessage";
import { Form, Col, FloatingLabel } from "react-bootstrap";
import PlainText from "components/ui/formFields/plainText/PlainText";
import useFastField from "../hooks/useFastField";

function FormPhoneInput({
  name,
  mask,
  placeholder,
  label,
  labelBold,
  readableView,
  showLabel,
  showRequiredLabel,
  helpText,
  plaintext,
  showGuide,
  containerCss,
  floatingLabel,
  xs,
  lg,
  md,
  xl,
  validate,
  ...rest
}) {
  const { field, meta, helpers } = useFastField({ name, validate });

  if (plaintext) {
    return <PlainText type="input" label={label} value={field.value} />;
  }

  const maskFormat =
    mask === "mobile"
      ? [
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          " ",
          /\d/,
          /\d/,
          /\d/,
          " ",
          /\d/,
          /\d/,
          /\d/,
          /\d/,
        ]
      : mask === "phone"
      ? [/\d/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, /\d/]
      : mask;

  if (floatingLabel) {
    return (
      <Col xs={xs} md={md} lg={lg} xl={xl}>
        <FloatingLabel
          controlId={name}
          label={label}
          className={`mb-3 ${containerCss}`}
        >
          <MaskedInput
            {...rest}
            mask={maskFormat}
            className="form-control"
            placeholder="Place holder here"
            guide={showGuide}
            value={field.value}
            onChange={(e) => {
              helpers.setValue(e.target.value);
            }}
            onBlur={() => helpers.setTouched(true)}
          />
          {helpText && <Form.Text muted>{helpText}</Form.Text>}
          <ValidationMessage errorMessage={meta.error} forceDisplay={true} />
        </FloatingLabel>
      </Col>
    );
  }

  return (
    <Form.Group
      as={Col}
      controlId={name}
      className="mb-3"
      xs={xs}
      md={md}
      lg={lg}
      xl={xl}
    >
      {showLabel && (
        <>
          <Form.Label
            className={`label-field-style ${
              labelBold ? "fw-bold" : "fw-normal"
            }`}
          >
            {label}
          </Form.Label>
          {showRequiredLabel && <span className="text-danger ms-1">*</span>}
        </>
      )}

      <MaskedInput
        {...rest}
        mask={maskFormat}
        className="form-control"
        placeholder={placeholder}
        guide={showGuide}
        value={field.value}
        onChange={(e) => {
          helpers.setValue(e.target.value);
        }}
        onBlur={() => helpers.setTouched(true)}
      />
      {helpText && <Form.Text muted>{helpText}</Form.Text>}
      <ValidationMessage errorMessage={meta.error} forceDisplay={true} />
    </Form.Group>
  );
}

FormPhoneInput.propTypes = {
  name: PropTypes.string.isRequired,
  labelBold: PropTypes.bool,
  mask: PropTypes.array,
  placeholder: PropTypes.string,
  showRequiredLabel: PropTypes.bool,
  label: PropTypes.string,
  showLabel: PropTypes.bool,
  helpText: PropTypes.string,
  plaintext: PropTypes.bool,
  showGuide: PropTypes.bool,
  floatingLabel: PropTypes.bool,
  containerCss: PropTypes.string,
  validate: PropTypes.func,
};

FormPhoneInput.defaultProps = {
  mask: "phone",
  showLabel: true,
  showGuide: true,
};

export default FormPhoneInput;
