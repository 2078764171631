import React, { useState } from "react";
import PropTypes from "prop-types";
import { Menu, Sidebar, SubMenu } from "react-pro-sidebar";
import { noop } from "lodash";
import SidebarItem from "./components/SidebarItem";
import "./rc-sidebar.scss";
import "react-perfect-scrollbar/dist/css/styles.css";

function RcSidebar({
  data,
  logo,
  brandName,
  collapsed,
  toggled,
  firstLevelMenuTextColor,
  secondLevelMenuTextColor,
  selectedBgColor,
  highlightBgColor,
  sidebarAvatarComponent,
  containerCss,
  isMobileOrTabletView,
  onBackdropClick,
}) {
  const getMenus = (menuItem, index) => {
    if (menuItem?.subMenus) {
      return (
        <SubMenu
          key={index}
          defaultOpen={menuItem?.isOpen}
          label={menuItem.menu}
          icon={<i className={menuItem.icon} />}
          //rootStyles={{ backgroundColor: "transparent" }}
          className="menu-item"
          suffix={menuItem?.suffix ?? undefined}
        >
          {menuItem.subMenus.map((subMenu, i) => (
            <SidebarItem key={i} title={subMenu.menu} to={subMenu.to} />
          ))}
        </SubMenu>
      );
    }

    return (
      <SidebarItem
        key={index}
        title={menuItem.menu}
        icon={menuItem.icon}
        to={menuItem.to}
      />
    );
  };
  
  return (
    <div className="d-flex flex-column admin-side-bar rc-sidebar-wrapper">
      <Sidebar
        collapsed={collapsed}
        toggled={toggled}
        breakPoint={`${isMobileOrTabletView ? "all" : undefined}`}
        transitionDuration={600}
        width="300px"
        rootStyles={{
          backgroundColor: "transparent",
          border: "none",
        }}
        className={containerCss}
        onBackdropClick={onBackdropClick}
      >
        <Menu
          transitionDuration={600}
          closeOnClick
          menuItemStyles={{
            button: ({ level, active, disabled }) => {
              // only apply styles on first level elements of the tree
              if (level === 0)
                return {
                  color: !disabled ? firstLevelMenuTextColor : "#7d7d7c",
                  backgroundColor: active ? selectedBgColor : undefined,
                  margin: `0 ${collapsed ? "0" : "10px"}`,
                  "&:hover": {
                    backgroundColor: `${highlightBgColor} !important`,
                    color: "white !important",
                    borderRadius: "8px !important",
                    //fontWeight: "bold !important",
                    margin: `0 ${collapsed ? "0" : "10px"}`,
                  },
                };

              if (level === 1) {
                return {
                  color: !disabled ? secondLevelMenuTextColor : "#7d7d7c",
                  backgroundColor: active ? selectedBgColor : undefined,
                  margin: `0 ${collapsed ? "0" : "10px"}`,
                  paddingLeft: collapsed ? "15px" : "70px",
                  "&:hover": {
                    backgroundColor: `${highlightBgColor} !important`,
                    color: "white !important",
                    borderRadius: "8px !important",
                    margin: `0 ${collapsed ? "0" : "10px"}`,
                    paddingLeft: collapsed ? "15px" : "70px",
                  },
                };
              }
            },
          }}
          rootStyles={{ backgroundColor: "transparent" }}
        >
          <div
            className={`d-flex flex-row brand-container ${
              collapsed ? "collapsed" : ""
            }`}
          >
            <img src={logo} alt="Sidebar Logo" className="img-logo" />
            <div className="d-flex flex-column justify-content-center logo-text">
              {brandName}
            </div>
          </div>
          {sidebarAvatarComponent(collapsed)}
          {data.map((o, i) => getMenus(o, i))}
        </Menu>
      </Sidebar>
    </div>
  );
}

RcSidebar.propTypes = {
  data: PropTypes.array,
  brandName: PropTypes.string,
  containerCss: PropTypes.string,
  collapsed: PropTypes.bool,
  toggled: PropTypes.bool,
  firstLevelMenuTextColor: PropTypes.string,
  selectedBgColor: PropTypes.string,
  highlightBgColor: PropTypes.string,
  isMobileOrTabletView: PropTypes.bool,
  sidebarAvatarComponent: PropTypes.func,
  onBackdropClick: PropTypes.func,
};

RcSidebar.defaultProps = {
  data: [],
  selectedBgColor: "#29303d",
  highlightBgColor: "#335B8C",
  sidebarAvatarComponent: noop,
  onBackdropClick: noop,
};

export default RcSidebar;
