/**
 * loaderType: "ball-pulse-sync", "ball-beat"
 */
import React from "react";
import PropTypes from "prop-types";
import BlockUi from "@availity/block-ui";
import "loaders.css/loaders.min.css";
import Config from "config";
import "./block-ui.scss";
import BeatLoader from "react-spinners/BeatLoader";

// const override = {
//   display: "block",
//   margin: "0 auto",
//   borderColor: "red",
// };

const RcBlockUi = ({
  blocking,
  zIndex,
  children,
  color,
  indicatorMessage,
  ...rest
}) => (
  <BlockUi
    tag="div"
    blocking={blocking}
    style={{ zIndex: zIndex ? 1 : 12000 }}
    className="d-block position-relativex"
    keepInView={true}
    renderChildren={true}
    loader={
      <div className="d-flex flex-column justify-content-center align-items-center">
        {/* <ScaleLoader
          color={color}
          loading={blocking}
          //cssOverride={override}
          size={32}
          aria-label="Loading Spinner"
          data-testid="loader"
        /> */}

        <BeatLoader
          color={color}
          loading={blocking}
          size={18}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
        {indicatorMessage && (
          <div className="mt-2 text-mutedx fg-primary tsmall2 fw-600">
            {indicatorMessage}
          </div>
        )}
      </div>
    }
    {...rest}
  >
    {children}
  </BlockUi>
);

RcBlockUi.propTypes = {
  color: PropTypes.string,
  zIndex: PropTypes.bool,
  indicatorMessage: PropTypes.string,
};

RcBlockUi.defaultProps = {
  color: Config.components.blockUi.color,
  zIndex: true,
  indicatorMessage: "In-progress...",
};

export default RcBlockUi;
