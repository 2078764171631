import React from "react";
import PropTypes from "prop-types";
import { FacebookIcon, FacebookShareButton } from "react-share";
import RcTooltip from "components/ui/rcTooltip/RcTooltip";
import useMediaQueryExt from "hooks/useMediaQueryExt";

function FbShareButton({ url, hashTag, size, containerCss }) {
  const { isMobile } = useMediaQueryExt();

  return (
    <>
      <FacebookShareButton
        url={url}
        hashtag={hashTag}
        className={`fbshare ${containerCss}`}
      >
        <FacebookIcon size={size} round />
      </FacebookShareButton>
      <RcTooltip
        message="Share page to Facebook"
        selector="fbshare"
        place={`${isMobile ? "bottom" : "left"}`}
      />
    </>
  );
}

FbShareButton.propTypes = {
  url: PropTypes.string,
  hashTag: PropTypes.string,
  size: PropTypes.number,
  containerCss: PropTypes.string,
};

FbShareButton.defaultProps = { size: 28 };

export default FbShareButton;
