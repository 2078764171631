import Http from "../core/http/http";

function AuthorizationService() {
  let apiUrl = "/v1/accounts";
  
  const getAuthorizationDataAsync = () =>
    Http().get(`${apiUrl}/authorization-data`);

  return {
    getAuthorizationDataAsync,
  };
}

export default AuthorizationService;
