import { useMediaQuery } from "react-responsive";
import PropTypes from "prop-types";

const Mobile = ({ children, query }) => {
  const isMobile = useMediaQuery(query);
  return isMobile ? children : null;
};

Mobile.propTypes = {
  query: PropTypes.object,
};

Mobile.defaultProps = { query: { maxWidth: 767 } };

export default Mobile;