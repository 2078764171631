import React from "react";
import PropTypes from "prop-types";
import { motion } from "framer-motion";
import { RcButton } from "components/ui/rcButton";
import "./hero-intro.scss";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import useMediaQueryExt from "hooks/useMediaQueryExt";

function HeroIntro({
  subTitle,
  subTitle2,
  ctaPrimaryText,
  ctaSecondaryText,
  handleCtaPrimaryClick,
  handleCtaSecondaryClick,
}) {
  const { isMobile } = useMediaQueryExt();

  const container = {
    hidden: { opacity: 1 },
    visible: {
      opacity: 1,
      transition: {
        duration: 3,
        delayChildren: 2,
        staggerChildren: 0.09,
      },
    },
  };

  const item = {
    // hidden: { opacity: 0, y: "-90vh" },
    // visible: { opacity: 1, y: 0 },
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  return (
    <>
      <motion.div
        initial={{ opacity: 0, x: "-20vw" }}
        transition={{ ease: "easeOut", duration: 2.8 }}
        animate={{ opacity: 1, x: 0 }}
        className="mb-4"
      >
        <img
          src="https://rcnetlabs-imaging.sirv.com/Vista/intro/moving-text-logo.png"
          className="introImage"
          alt="Vista Introduction Text Logo"
        />
      </motion.div>
      <motion.div
        initial={{ opacity: 0, y: isMobile ? "13vh" : "40vh" }}
        transition={{ ease: "easeOut", duration: isMobile ? 1.8 : 2.5 }}
        animate={{ opacity: 1, y: 0 }}
        className="d-flex flex-row align-items-center"
      >
        {/* Visible only on sm and below */}
        <div className="d-flex flex-column d-md-none align-items-center">
          <h1 className="me-2">{subTitle}</h1>
          <h1 className="h1-pretty">{subTitle2}</h1>
        </div>
        {/* Visible only on md and up */}
        <div className="d-none d-md-flex align-items-center">
          <h1 className="me-2">{subTitle}</h1>{" "}
          <h1 className="h1-pretty">{subTitle2}</h1>
        </div>
      </motion.div>

      {/* Buttons Fx */}
      {/* For Desktop */}
      <motion.div
        className="d-md-flex d-none justify-content-center intro-cta my-5"
        variants={container}
        initial="hidden"
        animate="visible"
      >
        <motion.div variants={item}>
          <RcButton
            variant="primary"
            size="lg"
            containerCss="cta-button me-3"
            onClick={handleCtaPrimaryClick}
          >
            {ctaPrimaryText}
          </RcButton>
        </motion.div>
        <motion.div variants={item}>
          <RcButton
            variant="custom-outline-secondary"
            size="lg"
            containerCss="cta-button"
            onClick={handleCtaSecondaryClick}
          >
            {ctaSecondaryText}
          </RcButton>
        </motion.div>
      </motion.div>

      {/* For Mobile */}
      <motion.div
        className="d-flex d-md-none flex-column justify-content-center intro-cta  w-100 my-5"
        variants={container}
        initial="hidden"
        animate="visible"
      >
        <motion.div
          className="d-flex flex-row justify-content-center"
          variants={item}
        >
          <RcButton
            variant="primary"
            size="lg"
            containerCss="cta-button mb-3 w-75"
            onClick={handleCtaPrimaryClick}
          >
            {ctaPrimaryText}
          </RcButton>
        </motion.div>
        <motion.div
          className="d-flex flex-row justify-content-center"
          variants={item}
        >
          <RcButton
            variant="custom-outline-secondary"
            size="lg"
            containerCss="cta-button w-75"
            onClick={handleCtaSecondaryClick}
          >
            {ctaSecondaryText}
          </RcButton>
        </motion.div>
      </motion.div>
    </>
  );
}

HeroIntro.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  subTitle2: PropTypes.string,
  buttonPrimaryText: PropTypes.string,
  buttonSecondaryText: PropTypes.string,
};

HeroIntro.defaultProps = {};

export default HeroIntro;
