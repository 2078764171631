import { FileUploadStatus } from "./constants";

const toKilobyte = (fileSize) => {
  if (fileSize === "-") return "0";
  if (fileSize <= 0) return 0;

  return (fileSize / 1024).toFixed(2);
};

const readFileAsync = async (inputFile) => {
  let reader = new FileReader();

  return new Promise((resolve) => {
    let { name, size } = inputFile;

    reader.onload = function (e) {
      resolve({
        name,
        size,
        data: e.target.result,
      });
    };

    reader.readAsDataURL(inputFile);
  });
};

async function fileListToBase64(fileList) {
  // create function which return resolved promise
  // with data:base64 string
  function getBase64(file) {
    if (file.type === FileUploadStatus.INIT) {
      return new Promise((res, rej) => {
        res({ name: file.name, data: file.preview, size: file.size });
      });
    }

    const reader = new FileReader();

    return new Promise((resolve) => {
      reader.onloadend = (ev) => {
        resolve({
          name: file.name,
          data: ev.target.result,
          preview: file.preview,
          size: file.size,
          type: FileUploadStatus.NEW,
        });
      };
      reader.readAsDataURL(file);
    });
  }
  // here will be array of promisified functions
  const promises = [];

  // loop through fileList with for loop
  for (let i = 0; i < fileList.length; i++) {
    promises.push(getBase64(fileList[i]));
  }

  // array with base64 strings
  return await Promise.all(promises);
}

const limitFileName = (fileName) => {
  if (fileName.length > 18) {
    let name = fileName.substring(0, fileName.lastIndexOf("."));
    let extension = fileName.substring(fileName.lastIndexOf("."));
    return `${name.substring(0, 16)}...${extension}`;
  }

  return fileName;
};

async function fileListToArrayBuffer(fileList) {
  function getFileBuffer(file) {
    if (file.type === FileUploadStatus.INIT) {
      return new Promise((res, rej) => {
        res({
          name: file.name,
          data: file.data,
          size: file.size,
          isBusy: file.isBusy,
        });
      });
    }

    return new Promise((resolve) => {
      const preview = file.preview;
      const clientRefId = file.clientRefId;
      const isBusy = file.isBusy;

      delete file.preview;
      delete file.clientRefId;
      delete file.isBusy;

      resolve({
        clientRefId,
        name: file.name,
        data: file,
        preview,
        size: file.size,
        type: FileUploadStatus.NEW,
        isBusy,
      });
    });
  }
  // Here will be array of promisified functions
  const promises = [];

  // Loop through fileList with for loop
  for (let i = 0; i < fileList.length; i++) {
    promises.push(getFileBuffer(fileList[i]));
  }

  // array with base64 strings
  return await Promise.all(promises);
}

const getForFileUploading = (data) => {
  let filesForUpload = data.filter((f) => f.type === FileUploadStatus.NEW);

  return filesForUpload;
};

export {
  fileListToBase64,
  fileListToArrayBuffer,
  toKilobyte,
  readFileAsync,
  limitFileName,
  getForFileUploading,
};
