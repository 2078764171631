import React from "react";
import PropTypes from "prop-types";
import { Container, Nav, Navbar } from "react-bootstrap";

function NavBarDemo({ containerCss }) {
  return (
    <section className={containerCss}>
      <h4>Nav sample</h4>
      <Navbar bg="dark" variant="dark">
        <Container>
          <Navbar.Brand href="#home">Navbar</Navbar.Brand>
          <Nav className="me-auto">
            <Nav.Link href="#home">Home</Nav.Link>
            <Nav.Link href="#features">Features</Nav.Link>
            <Nav.Link href="#pricing">Pricing</Nav.Link>
          </Nav>
        </Container>
      </Navbar>
      <div>
        <h5>Icons</h5>
        <div>
          {/* <FontAwesomeIcon icon={faEbay} /> */}
          <i class="fa-solid fa-user"></i>
          <i className="fa-brands fa-github-square"></i>
          <i class="fa-solid fa-cart"></i>
        </div>
      </div>
    </section>
  );
}

NavBarDemo.propTypes = {
  containerCss: PropTypes.string,
};

NavBarDemo.defaultProps = {};

export default NavBarDemo;
