const navBarMenus = {
  menus: [
    {
      id: 1,
      label: "Our Brands",
      url: "/home#our-brands",
    },
    // {
    //   id: 2,
    //   label: "Discover",
    //   url: "home#discover",
    // },
    // {
    //   id: 3,
    //   label: "About Us",
    //   url: "home#about-us",
    // },
    // {
    //   id: 4,
    //   label: "Contact Us",
    //   url: "home#contacts",
    // },
    // {
    //   id: 5,
    //   label: "Location",
    //   url: "home#location",
    // },
    {
      id: 6,
      label: "Careers",
      url: "/careers",
    },
    // {
    //   id: 7,
    //   label: "Client Access",
    //   url: "/auth/client/signin",
    // },
    // {
    //   id: 6,
    //   label: "Gallery",
    //   url: "home#gallery",
    // },
  ],
};

export default navBarMenus;
