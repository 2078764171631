import React from "react";
import PropTypes from "prop-types";
import { Accordion } from "react-bootstrap";
import ImageMdCombo from "../../../ui/imageMdCombo/ImageMdCombo";
import { noop } from "lodash";

function SampleComputation({ data, onPreview, imageContainerCss }) {
  return (
    <Accordion defaultActiveKey={["0"]} className="mt-5" alwaysOpen flush>
      {data.map((item, i) => {
        return (
          <Accordion.Item eventKey={`${i}`} key={item.groupName}>
            <Accordion.Header>
              <strong id={`computation${i + 1}`}>{item.groupName}</strong>
            </Accordion.Header>
            <Accordion.Body>
              <ImageMdCombo
                data={item.computations}
                containerCss={imageContainerCss}
                onPreview={onPreview}
              />
            </Accordion.Body>
          </Accordion.Item>
        );
      })}
    </Accordion>
  );
}

SampleComputation.propTypes = {
  data: PropTypes.array,
  imageContainerCss: PropTypes.string,
  onPreview: PropTypes.func,
};

SampleComputation.defaultProps = { data: [], onPreview: noop };

export default React.memo(SampleComputation);
