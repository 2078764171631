import * as Yup from "yup";

export const requestQuotationValidator = Yup.object().shape({
  fname: Yup.string().required("First name is required."),
  lname: Yup.string().required("Last name is required."),
  email: Yup.string().required("Email is required.").email("Invalid email"),
  phone: Yup.string().required("Telephone number is required."),
  services: Yup.object()
    .shape({
      label: Yup.string(),
      value: Yup.string(),
    })
    .nullable()
    .required("Service type is required."),

  // services: Yup.array()
  //   .of(
  //     Yup.object().shape({
  //       label: Yup.string(),
  //       value: Yup.string(),
  //     })
  //   )
  //   .min(1, "Service type is required."),
});
