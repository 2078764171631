import React from "react";
import PropTypes from "prop-types";
import { Card } from "react-bootstrap";

const ObjectWatch = ({ containerCss, title, value }) => {
  return (
    <Card className={containerCss}>
      <Card.Body className="mt-2">
        <Card.Title>{title}</Card.Title>
        <Card.Text as="div">
          <pre>
            <code>{JSON.stringify(value, null, 5)}</code>
          </pre>
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

ObjectWatch.propTypes = {
  title: PropTypes.string,
  value: PropTypes.object,
  containerCss: PropTypes.string,
};

ObjectWatch.defaultProps = { title: "Object Values" };

export default ObjectWatch;
