import React from "react";
import PropTypes from "prop-types";
import FormModal from "components/ui/formikFields/formModal/FormModal";
import * as RequestQuotationSlice from "./requestQuotationSlice";
import { useDispatch, useSelector } from "react-redux";
import FormInputs from "./formInput/FormInputs";
import { requestQuotationValidator } from "./validator/requestQuotationValidator";
import { FormWatch } from "components/ui/formikFields";
import { Tab, Tabs } from "react-bootstrap";

function RequestQuotation() {
  const dispatch = useDispatch();
  const { showModal, serviceLookups, formEntryDefaults } = useSelector(
    RequestQuotationSlice.selectRequestQuotation
  );

  const handleFormModalHide = () =>
    dispatch(RequestQuotationSlice.showRequestQuotation({ show: false }));

  return (
    <FormModal
      title="Request a Quotation"
      isOpen={showModal}
      initialValues={{
        fname: "",
        lname: "",
        email: "",
        phone: "",
        companyName: "",
        message: "",
        services: formEntryDefaults.services,
        subCategory: formEntryDefaults.subCategory,
      }}
      formSchema={requestQuotationValidator}
      onHide={handleFormModalHide}
    >
      <div>
        <Tabs
          defaultActiveKey="client"
          id="justify-tab-example"
          className="mb-3"
        >
          <Tab eventKey="client" title="Client Info">
            <FormInputs serviceLookups={serviceLookups} />
          </Tab>
          <Tab eventKey="services" title="Services to Quote">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
          </Tab>
        </Tabs>
      </div>
      {/* <FormWatch /> */}
    </FormModal>
  );
}

RequestQuotation.propTypes = {};

RequestQuotation.defaultProps = {};

export default RequestQuotation;
