import * as RequestQuotationSlice from "components/containers/requestQuotation/requestQuotationSlice";
import { useDispatch } from "react-redux";

function useRequestQuotation() {
  const dispatch = useDispatch();

  const showRequestQuotation = (serviceTypeId = null, subCategoryId = null) =>
    dispatch(
      RequestQuotationSlice.showRequestQuotation({
        show: true,
        serviceTypeId,
        subCategoryId,
      })
    );

  return { showRequestQuotation };
}

export default useRequestQuotation;
