import React, { useCallback } from "react";
import PropTypes from "prop-types";
import MarkDown from "components/ui/markdown/MarkDown";
import { Image } from "react-bootstrap";
import RcTooltip from "components/ui/rcTooltip/RcTooltip";
import useMediaQueryExt from "hooks/useMediaQueryExt";

function LocationInfo({
  locationMarkdown,
  locationImage,
  containerCss,
  onPreview,
}) {
  const { isMobile } = useMediaQueryExt();

  const handlePreview = useCallback(
    () => onPreview(locationImage),
    [onPreview, locationImage]
  );

  return (
    <div className={containerCss}>
      <MarkDown text={locationMarkdown} containerCss="mb-4" />
      <Image
        src={locationImage}
        className="loc-image cursor-pointer"
        onClick={handlePreview}
        fluid
        rounded
      />
      <RcTooltip
        message="Click to enlarge the image"
        selector="loc-image"
        place={`${isMobile ? "bottom" : "right"}`}
      />
    </div>
  );
}

LocationInfo.propTypes = {
  locationMarkdown: PropTypes.string,
  locationImage: PropTypes.string,
  containerCss: PropTypes.string,
};

LocationInfo.defaultProps = {};

export default React.memo(LocationInfo);
