import CallUs from "components/features/contact-item-plugins/callUs/CallUs";
import EmailUs from "components/features/contact-item-plugins/emailUs/EmailUs";

const footerData = {
  layout1: {
    webSiteName: "About Rcnet Labs",
    webSiteDescription:
      "Software company that focused on Web and Mobile development services. Rcnet Labs was founded in 2001 by Rcnet. The main goal of this site is to provide quality tips, tricks, hacks, and other WordPress resources that allows WordPress beginners to improve their site(s).",
    groupLink1: {
      groupName: "Site Links",
      links: [
        {
          label: "About Us",
          url: "/",
        },
        {
          label: "Contact Us",
          url: "/",
        },
        {
          label: "Hobbies",
          url: "/",
        },
      ],
    },
    groupLink2: {
      groupName: "Quick Links",
      links: [
        {
          label: "Terms of Service",
          url: "/",
        },
        {
          label: "Office Location",
          url: "/",
        },
        {
          label: "Project Quote",
          url: "/",
        },
      ],
    },
  },
  layout2: {
    webSiteName: "Abbatoir Pest Control",
    webSiteDescription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    groupLink1: {
      groupName: "Site Links",
      links: [
        {
          label: "About Us",
          url: "/",
        },
        {
          label: "Contact Us",
          url: "/",
        },
      ],
    },
    groupLink2: {
      groupName: "Quick Links",
      links: [
        {
          label: "Office Location",
          url: "/",
        },
        {
          label: "Project Quote",
          url: "/",
        },
      ],
    },
    socialLinks: [
      {
        url: "https://github.com",
        alt: "gitHub",
        icon: ["fab", "github"],
        size: "2x",
        className: "me-3",
        hoverClass: "git",
      },
      {
        url: "https://facebook.com",
        alt: "facebook",
        icon: ["fab", "facebook-square"],
        size: "2x",
        className: "me-3",
        hoverClass: "fb",
      },
      {
        url: "https://twitter.com",
        alt: "twitter",
        icon: ["fab", "twitter"],
        size: "2x",
        className: "me-3",
        hoverClass: "twitter",
      },
      {
        url: "https://instagram.com",
        alt: "instagram",
        icon: ["fab", "instagram-square"],
        size: "2x",
        className: "me-3",
        hoverClass: "ig",
      },
    ],
  },
  layout3: {
    webSiteName: "Abbatoir Pest Control",
    webSiteDescription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    groupLink1: {
      groupName: "Site Links",
      links: [
        {
          label: "About Us",
          url: "/",
        },
        {
          label: "Contact Us",
          url: "/",
        },
      ],
    },
    groupLink2: {
      groupName: "Services",
      links: [
        {
          label: "Termite Control Solution",
          url: "/",
        },
        {
          label: "Pest Control Solution",
          url: "/",
        },
        {
          label: "Other Pest Control Services",
          url: "/",
        },
      ],
    },
    socialLinks: [
      {
        url: "https://github.com",
        alt: "gitHub",
        icon: ["fab", "github"],
        size: "2x",
        className: "me-3",
        hoverClass: "git",
      },
      {
        url: "https://facebook.com",
        alt: "facebook",
        icon: ["fab", "facebook-square"],
        size: "2x",
        className: "me-3",
        hoverClass: "fb",
      },
      {
        url: "https://twitter.com",
        alt: "twitter",
        icon: ["fab", "twitter"],
        size: "2x",
        className: "me-3",
        hoverClass: "twitter",
      },
      {
        url: "https://instagram.com",
        alt: "instagram",
        icon: ["fab", "instagram-square"],
        size: "2x",
        className: "me-3",
        hoverClass: "ig",
      },
    ],
  },
  layout4: {
    webSiteName: "Abbatoir Pest Control",
    webSiteDescription:
      "A member of Pest Exterminator Association of the Philippines (PEAP) and Philippine Federation of Pest Management Operator’s Association, Inc. (PFMOA, Inc), APMI is a full-suite Pest and Termite service provider that uses state-of-the art equipment and game-changing technology to provide cost effective pesticide solution.",
    contactItems: [
      {
        title: "Find Us",
        subTitle: "263 15th Ave, Cubao, Quezon City, Metro Manila",
        iconCss: "fas fa-map-marker-alt",
      },
      {
        title: "Call Us",
        subTitle: <CallUs />,
        iconCss: "fas fa-phone-volume",
      },
      {
        title: "Email Us",
        subTitle: <EmailUs />,
        iconCss: "fas fa-envelope",
      },
    ],
    groupLink1: {
      groupName: "Site Links",
      links: [
        {
          label: "About Us",
          url: "/#about-us",
        },
        {
          label: "Contact Us",
          url: "/#contacts",
        },
        {
          label: "Location",
          url: "/#location",
        },
        {
          label: "Gallery",
          url: "/#gallery",
        },
      ],
    },
    groupLink2: {
      groupName: "Quick Links",
      links: [
        {
          label: "Termite Control Solution",
          url: "/#service1",
        },
        {
          label: "Pest Control Solution",
          url: "/#service2",
        },
        {
          label: "Other Pest Control Services",
          url: "/#service3",
        },
      ],
    },
    socialLinks: [
      {
        url: "https://www.facebook.com/Abbatoirpmi",
        alt: "facebook",
        icon: "fa-brands fa-facebook",
        size: "fa-2x",
        className: "me-3",
        hoverClass: "fb",
      },
      {
        url: "https://www.instagram.com/abbatoirpest",
        alt: "instagram",
        icon: "fab fa-instagram",
        size: "fa-2x",
        className: "me-3",
        hoverClass: "ig",
      },
    ],
  },
  layout5: {
    webSiteName: "Vista Land Lifescapes",
    webSiteDescription:
      "Leading Integrated Property Developer <br/>and the Largest HomeBuilder.",
    groupLink1: {
      groupName: "Site Links",
      links: [
        {
          label: "Our Brands",
          url: "/#our-brands",
        },
        {
          label: "Discover",
          url: "/discover/brands",
        },
        {
          label: "Contact Us",
          url: "/#contact-us",
        },
        {
          label: "Location",
          url: "/#location",
        },
      ],
    },
    groupLink2: {
      groupName: "Company",
      links: [
        {
          label: "Careers",
          url: "/careers",
        },
        {
          label: "About Us",
          url: "/#about-us",
        },
      ],
    },
    socialLinks: [
      {
        url: "https://www.facebook.com/profile.php?id=61554458503593&mibextid=LQQJ4d",
        alt: "facebook",
        icon: "fa-brands fa-facebook",
        size: "fa-2x",
        className: "me-3",
        hoverClass: "fb",
      },
      {
        url: "https://www.instagram.com/vistaestatessouth?igsh=MTliMGhkY2RtdmJvdQ%3D%3D&utm_source=qr",
        alt: "instagram",
        icon: "fab fa-instagram",
        size: "fa-2x",
        className: "me-3",
        hoverClass: "ig",
      },
    ],
  },
};

export default footerData;
