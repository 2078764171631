/**
 * Component enhancements to support Formik field
 */
import React from "react";
import PropTypes from "prop-types";
import ValidationMessage from "components/ui/formikFields/validationMessage/ValidationMessage";
import { Col } from "react-bootstrap";
import SelectField from "components/ui/formFields/selectField/SelectField";
import useFastField from "../hooks/useFastField";

const FormSelectField = ({
  name,
  label,
  showRequiredLabel,
  labelBold,
  readableView,
  plaintext,
  plaintextInline,
  validate,
  onChangeOverride,
  xs,
  lg,
  md,
  xl,
  ...rest
}) => {
  const { field, meta, helpers } = useFastField({ name, validate });

  const handleChange = (name, value) => {
    setTimeout(async () => {
      if (onChangeOverride) {
        await helpers.setValue(value, true);
        setTimeout(() => onChangeOverride(name, value), 100);
      } else {
        await helpers.setValue(value, true);
      }
    }, 200);
  };

  return (
    <Col className="mb-3" xs={xs} lg={lg} md={md} xl={xl}>
      <SelectField
        {...rest}
        value={field.value}
        label={label}
        showRequiredLabel={showRequiredLabel}
        labelBold={labelBold}
        plaintext={plaintext}
        plaintextInline={plaintextInline}
        onChange={(value) => {
          handleChange(name, value);
        }}
        onBlur={async () => await helpers.setTouched(true, true)}
      />
      <ValidationMessage
        errorMessage={meta.touched && meta.error}
        forceDisplay={true}
      />
    </Col>
  );
};

FormSelectField.propTypes = {
  name: PropTypes.string,
  options: PropTypes.array,
  value: PropTypes.any,
  onChange: PropTypes.func,
  allowSelectAll: PropTypes.bool,
  allOption: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  label: PropTypes.string,
  showRequiredLabel: PropTypes.bool,
  labelBold: PropTypes.bool,
  plaintext: PropTypes.bool,
  plaintextInline: PropTypes.bool,
  xs: PropTypes.number,
  lg: PropTypes.number,
  md: PropTypes.number,
  xl: PropTypes.number,
  validate: PropTypes.func,
  onChangeOverride: PropTypes.func,
};

FormSelectField.defaultProps = {
  allOption: {
    label: "Select all",
    value: "*",
  },
  showLabel: true,
  showRequiredLabel: false,
  labelBold: false,
  allowSelectAll: false,
  readableView: false,
};

export default FormSelectField;
