/**
 * Preset buttons
 */
import React from "react";
import { Button } from "react-bootstrap";

const getDefaultButton = (btn, clickPayload, clickHandler, disabled, i) => {
  switch (btn.id) {
    case "defAddButton":
      return (
        <Button
          key={i}
          //variant="light"
          className={`${btn?.buttonCss ?? "me-1"}`}
          size="sm"
          onClick={() => clickHandler(clickPayload)}
          disabled={disabled}
        >
          <i className="fas fa-plus" />
        </Button>
      );

    case "defDeleteButton":
      return (
        <Button
          key={i}
          //variant="light"
          className={`${btn?.buttonCss ?? "me-1"}`}
          size="sm"
          onClick={() => clickHandler(clickPayload)}
          disabled={disabled}
        >
          <i className="fas fa-trash-alt" />
        </Button>
      );

    case "defEditButton":
      return (
        <Button
          key={i}
          //variant="light"
          className={`${btn?.buttonCss ?? "me-1"}`}
          size="sm"
          onClick={() => clickHandler(clickPayload)}
          disabled={disabled}
        >
          <i className="fas fa-edit" />
        </Button>
      );

    default:
      return null;
  }
};

export { getDefaultButton };
