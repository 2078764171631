import React from "react";
import { Form } from "react-bootstrap";

const ValidationMessage = ({ errorMessage, forceDisplay, containerCss }) => (
  <>
    <Form.Control.Feedback
      type="invalid"
      className={`${
        forceDisplay ? "d-block tsmall1" : "tsmall1"
      } ${containerCss}`}
    >
      {errorMessage}
    </Form.Control.Feedback>
  </>
);

export default ValidationMessage;
