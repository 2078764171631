import { useDispatch, useSelector } from "react-redux";
import * as SystemSlice from "store/system/systemSlice";

const useSystem = () => {
  const dispatch = useDispatch();
  const { currentLogo } = useSelector(SystemSlice.selectSystem);

  const notifyBase = (title, message, type, detailsUrl = null) => {
    dispatch(
      SystemSlice.showNotification({
        title,
        message,
        type,
        detailsUrl,
      })
    );
  };

  const notify = (message) => {
    dispatch(SystemSlice.showNotification(message));
  };

  const notifySuccess = (title, message, detailsUrl = null) => {
    notifyBase(title, message, "success", detailsUrl);
  };

  const notifyWarning = (title, message, detailsUrl = null) => {
    notifyBase(title, message, "warn", detailsUrl);
  };

  const notifyError = (message, err) => {
    dispatch(
      SystemSlice.showNotificationError({
        message,
        error: err,
      })
    );
  };

  const changeLogo = (logoType) => {
    if (logoType === 1)
      dispatch(
        SystemSlice.changeLogo(
          "https://rcnetlabs-imaging.sirv.com/Vista/logo/header-logo-white.png"
        )
      );

    if (logoType === 2)
      dispatch(
        SystemSlice.changeLogo(
          "https://rcnetlabs-imaging.sirv.com/Vista/logo/header-logo-colored.png"
        )
      );
  };

  return {
    notify,
    notifySuccess,
    notifyWarning,
    notifyError,
    changeLogo,
    currentLogo,
  };
};

export default useSystem;
