import React from "react";
import PropTypes from "prop-types";
import { isObject } from "lodash";

function ContactItem({ title, subTitle, iconCss, containerCss }) {
  return (
    <div
      className={`d-flex flex-row align-items-start contact-us-item ${containerCss}`}
    >
      <div className="pe-3">
        <i className={`${iconCss} fa-2xl location-icon mt-4`} />
      </div>
      <div className="d-flex flex-column align-items-start contact-us-item-value">
        <h5 className="fw-700">{title}</h5>
        {isObject(subTitle) ? (
          <div className="fg-gray-600">{subTitle}</div>
        ) : (
          <div
            className="fg-gray-600 text-start"
            dangerouslySetInnerHTML={{ __html: subTitle }}
          />
        )}
      </div>
    </div>
  );
}

ContactItem.propTypes = {
  iconCss: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  containerCss: PropTypes.string,
};

ContactItem.defaultProps = {};

export default ContactItem;
