import React from "react";
import PropTypes from "prop-types";
import { Image } from "react-bootstrap";

function Detail2point3({ containerCss }) {
  return (
    <section className={containerCss}>
      <h3>Mist Blower Industrial Treatment</h3>
      <ul className="list my-4">
        <li class="mb-2">
          Implements treatment via contact poisoning – spreading the chemical
          throughout the colony as the termites fed on AG system sends their
          nest mates feed on the bait.
        </li>
        <li class="mb-2">
          Suitable solution for structures with visible living termites on wood
          parquet skirting, floors, wooden stairs, door frames and door jams and
          even roof structures.
        </li>
        <li class="mb-2">
          AG contains an active ingredient that stops termites molting process.
          It dries the actual termite and destroy the entire colony.
        </li>
        <li class="mb-2">
          Lesser invasive structured treatment- no drill holes, no noise, no
          chemical odor and service completion in less than 30 minutes without
          any disturbance to the tenants.
        </li>
      </ul>
      <div className="d-flex flex-column align-items-center">
        <Image
          src="images/services/detail2-3.jpg"
          className="image-content mb-3"
        />
      </div>
    </section>
  );
}

Detail2point3.propTypes = {
  containerCss: PropTypes.string,
};

Detail2point3.defaultProps = {};

export default Detail2point3;
