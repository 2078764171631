import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";

const PageNumber = ({
  pageIndex,
  pageOptions,
  pageSize,
  paginationOptions,
  setPageSize,
  gotoPage,
}) => {
  const options = {
    sizeSelection:
      paginationOptions?.sizeSelection === undefined
        ? [10, 20, 30, 40, 50]
        : paginationOptions.sizeSelection,
    selectionLabel:
      paginationOptions?.selectionLabel === undefined
        ? "Show @pageSize"
        : paginationOptions.selectionLabel,
  };

  return (
    <div className="d-flex flex-row justify-content-start align-items-center mb-1 page-number-wrapper mx-2">
      <div className="page-number">
        <Form.Control
          type="number"
          defaultValue={pageIndex + 1}
          value={pageIndex + 1}
          onChange={(e) => {
            const page = e.target.value ? Number(e.target.value) - 1 : 0;
            gotoPage(page);
          }}
        />
      </div>
      <div className="page-size-text">of {pageOptions.length}</div>
      <Form.Select
        name="select"
        aria-label="Data Table page size selector"
        value={pageSize}
        onChange={(e) => {
          setPageSize(Number(e.target.value));
        }}
        className="page-size"
      >
        {options.sizeSelection.map((pageSize) => (
          <option key={pageSize} value={pageSize}>
            {options.selectionLabel.replace("@pageSize", pageSize)}
          </option>
        ))}
      </Form.Select>
    </div>
  );
};

PageNumber.propTypes = {
  pageCount: PropTypes.number,
  pageIndex: PropTypes.number,
  pageOptions: PropTypes.array,
  pageSize: PropTypes.number,
  setPageSize: PropTypes.func,
  gotoPage: PropTypes.func,
  previousPage: PropTypes.func,
  nextPage: PropTypes.func,
  paginationOptions: PropTypes.shape({
    sizeSelection: PropTypes.array,
    selectionLabel: PropTypes.string,
    defaultPageSize: PropTypes.number,
  }),
};

PageNumber.defaultProps = {};

export default PageNumber;
