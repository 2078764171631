import React from "react";
import PropTypes from "prop-types";
import { FloatingLabel, Form, Col } from "react-bootstrap";
import { noop } from "lodash";
import PlainText from "../plainText/PlainText";

function InputField({
  name,
  label,
  type,
  value,
  helpText,
  textAreaSize,
  textAreaRows,
  showLabel,
  showRequiredLabel,
  floatingLabel,
  placeholder,
  plaintext,
  containerCss,
  labelBold,
  validationComponent,
  isInvalid,
  xs,
  lg,
  md,
  xl,
  onChange,
  ...rest
}) {
  const handleChange = ({ target }) => onChange(target.value);

  if (plaintext) {
    return <PlainText type={type} label={label} value={value} />;
  }

  if (!floatingLabel) {
    return (
      <>
        {type === "textarea" ? (
          <Form.Group
            as={Col}
            controlId={name}
            className="mb-3"
            xs={xs}
            md={md}
            lg={lg}
            xl={xl}
          >
            {showLabel && (
              <>
                <Form.Label
                  className={`label-field-style ${
                    labelBold ? "fw-bold" : "fw-normal"
                  }`}
                >
                  {label}
                </Form.Label>
                {showRequiredLabel && (
                  <span className="text-danger ms-1">*</span>
                )}
              </>
            )}
            <Form.Control
              as="textarea"
              rows={textAreaRows}
              value={value}
              placeholder={placeholder}
              onChange={handleChange}
              isInvalid={isInvalid}
              {...rest}
            />
            {helpText && <Form.Text muted>{helpText}</Form.Text>}
            {validationComponent}
          </Form.Group>
        ) : (
          <Form.Group
            as={Col}
            controlId={name}
            className="mb-3"
            xs={xs}
            md={md}
            lg={lg}
            xl={xl}
          >
            {showLabel && (
              <>
                <Form.Label
                  className={`label-field-style ${
                    labelBold ? "fw-bold" : "fw-normal"
                  }`}
                >
                  {label}
                </Form.Label>
                {showRequiredLabel && (
                  <span className="text-danger ms-1">*</span>
                )}
              </>
            )}
            <Form.Control
              type={type}
              value={value}
              placeholder={placeholder}
              onChange={handleChange}
              isInvalid={isInvalid}
              {...rest}
            />
            {helpText && <Form.Text muted>{helpText}</Form.Text>}
            {validationComponent}
          </Form.Group>
        )}
      </>
    );
  }

  return (
    <Col xs={xs} md={md} lg={lg} xl={xl}>
      <FloatingLabel
        controlId={name}
        label={label}
        className={`mb-3 ${containerCss}`}
      >
        {type === "textarea" ? (
          <>
            <Form.Control
              as="textarea"
              value={value}
              placeholder="Input placeholder"
              style={{ height: textAreaSize }}
              onChange={handleChange}
              isInvalid={isInvalid}
              {...rest}
            />
            {helpText && <Form.Text muted>{helpText}</Form.Text>}
            {validationComponent}
          </>
        ) : (
          <>
            <Form.Control
              type={type}
              value={value}
              placeholder="Input placeholder"
              onChange={handleChange}
              isInvalid={isInvalid}
              {...rest}
            />
            {helpText && <Form.Text muted>{helpText}</Form.Text>}
            {validationComponent}
          </>
        )}
      </FloatingLabel>
    </Col>
  );
}

InputField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  helpText: PropTypes.string,
  textAreaSize: PropTypes.string,
  showLabel: PropTypes.bool,
  showRequiredLabel: PropTypes.bool,
  floatingLabel: PropTypes.bool,
  placeholder: PropTypes.string,
  textAreaRows: PropTypes.number,
  plaintext: PropTypes.bool,
  value: PropTypes.any,
  labelBold: PropTypes.bool,
  validationComponent: PropTypes.object,
  isInvalid: PropTypes.bool,
  xs: PropTypes.number,
  lg: PropTypes.number,
  md: PropTypes.number,
  xl: PropTypes.number,
  onChange: PropTypes.func,
  containerCss: PropTypes.string,
};

InputField.defaultProps = {
  textAreaSize: "100px",
  showLabel: true,
  showRequiredLabel: false,
  floatingLabel: false,
  textAreaRows: 3,
  plaintext: false,
  isInvalid: false,
  onChange: noop,
};

export default InputField;
