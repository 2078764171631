import React from "react";
import PropTypes from "prop-types";
import { Badge, Button, Card } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { RcButton } from "components/ui/rcButton";
import "./service-item2.scss";
import PerfectScrollbar from "react-perfect-scrollbar";

function ServiceItem2({
  title,
  description,
  descriptionItems,
  tag,
  buttonText,
  buttonText2,
  imageUrl,
  containerCss,
  titleHandler,
  onPrimaryButtonClick,
  onSecondaryButtonClick,
}) {
  const getDescriptionItems = () => {
    if (descriptionItems == null || descriptionItems?.length === 0) return null;
    return descriptionItems.map((o, i) => (
      <li style={{ marginBottom: "5px;" }} key={i}>
        {o}
      </li>
    ));
  };

  return (
    <section
      className={`service-item-wrapper2 d-flex justify-content-center ${containerCss}`}
    >
      <Card className="shadow card-service">
        <Card.Body>
          <Card.Title className="d-flex flex-column justify-content-center align-items-center rca-custom-link py-3">
            {tag && (
              <h5 className="tsmall2">
                <Badge bg="primary" className="mb-2">
                  {tag}
                </Badge>
              </h5>
            )}
            <div className="d-flex flex-row align-items-start service-title-container">
              {titleHandler == null && (
                <strong className="service-title text-center">{title}</strong>
              )}
              {typeof titleHandler === "string" && (
                <>
                  <NavLink to="/admin" className="service-title">
                    {title}
                  </NavLink>
                  <i className="far fa-arrow-right ms-2 tsmall2"></i>
                </>
              )}
              {typeof titleHandler === "function" && (
                <Button
                  variant="link"
                  onClick={titleHandler}
                  className="service-title service-title-link"
                >
                  {title}
                </Button>
              )}
            </div>
          </Card.Title>
          <Card.Text className="card-text-container tsmall1">
            <PerfectScrollbar>
              {description ? (
                <div dangerouslySetInnerHTML={{ __html: description }} />
              ) : (
                <ul style={{ listStyleType: "square" }}>
                  {getDescriptionItems()}
                </ul>
              )}
            </PerfectScrollbar>
          </Card.Text>
          <div className="d-flex flex-column flex-md-row justify-content-center mb-3">
            <RcButton
              variant="outline-dark-pill-tertiary"
              containerCss="fw-600 me-0 me-md-2 mb-2"
              onClick={onPrimaryButtonClick}
            >
              {buttonText}
            </RcButton>
            <RcButton
              variant="outline-dark-pill-tertiary"
              containerCss="fw-600 mb-2"
              onClick={onSecondaryButtonClick}
            >
              {buttonText2}
            </RcButton>
          </div>
        </Card.Body>
      </Card>
    </section>
  );
}

ServiceItem2.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  descriptionItems: PropTypes.array,
  buttonText: PropTypes.string,
  buttonText2: PropTypes.string,
  containerCss: PropTypes.string,
  imageUrl: PropTypes.string,
  tag: PropTypes.string,
  titleHandler: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  onPrimaryButtonClick: PropTypes.func,
  onSecondaryButtonClick: PropTypes.func,
};

ServiceItem2.defaultProps = {
  title: "--",
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore",
  buttonText: "Button 1",
  imageUrl: "images/services/termite.jpg",
};

export default ServiceItem2;
