import React, { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import "./app.scss";
import ErrorBoundary from "components/ui/errorBoundary/ErrorBoundary";
import SplashLoader from "components/containers/splashLoader/SplashLoader";
import NavigateScrollToTop from "components/ui/navigateScrollToTop/NavigateScrollToTop";
import SuspenseLabel from "components/ui/suspenseLabel/SuspenseLabel";
import { ThemeContextProvider } from "context";
import mainRoutes from "./routes/mainRoutes";
import adminRoutes from "routes/adminRoutes";
import { AdminLayout, Blanklayout, FullLayout } from "layouts";
import HttpInterceptorHandler from "components/features/httpInterceptorHandler/HttpInterceptorHandler";
import ProtectedRoute from "components/ui/protectedRoute/ProtectedRoute";
import authRoutes from "routes/authRoutes";
import NotificationCenter from "components/containers/notificationCenter/NotificationCenter";
import ScrollToHashElement from "components/ui/scrollToHashElement/ScrollToHashElement";
import FacebookChat from "components/ui/facebookChat/FacebookChat";
import Config from "config";
import miscRoutes from "routes/miscRoutes";
import ShopLayout from "layouts/shopLayout/ShopLayout";
import discoverRoutes from "routes/discoverRoutes";

//const ShopLayout = lazy(() => import("layouts/shopLayout/ShopLayout"));

function App() {
  return (
    <div className="App">
      <ErrorBoundary>
        <HttpInterceptorHandler />
        <NotificationCenter />
        <NavigateScrollToTop exclude={["/quotation/add-service"]} />
        <SplashLoader>
          <ScrollToHashElement />
          <Suspense fallback={<SuspenseLabel />}>
            <ThemeContextProvider
              defaultTheme="light"
              adminDefaultTheme="light"
            >
              <Routes>
                <Route path="/" element={<FullLayout />}>
                  {mainRoutes()}
                </Route>
                <Route path="discover" element={<ShopLayout />}>
                  {discoverRoutes()}
                </Route>
                <Route
                  path="admin123"
                  element={
                    // <ProtectedRoute>
                    <AdminLayout />
                    // </ProtectedRoute>
                  }
                >
                  {adminRoutes()}
                </Route>
                <Route path="auth" element={<FullLayout />}>
                  {authRoutes()}
                </Route>
                <Route path="pages" element={<FullLayout />}>
                  {miscRoutes()}
                </Route>
              </Routes>
              <FacebookChat
                appId={Config.components.fbChat.appId}
                pageId={Config.components.fbChat.pageId}
              />
            </ThemeContextProvider>
          </Suspense>
        </SplashLoader>
      </ErrorBoundary>
    </div>
  );
}

export default App;
