import React, { useRef } from "react";
import PropTypes from "prop-types";
import { Row, Col, Tab, Tabs } from "react-bootstrap";
import { RcButton } from "components/ui/rcButton";
import { FormInput, FormPhoneInput } from "components/ui/formikFields";
import PhAddressField from "components/containers/phAddressField/PhAddressField";
import useFormErrorDetection from "hooks/useFormErrorDetection";
import FormCheckbox from "components/ui/formikFields/formCheckbox/FormCheckbox";
import RecaptchaInvisible from "components/ui/recaptchaInvisible/RecaptchaInvisible";

const SignupFormInputs = ({ containerCss, onSubmit }) => {
  useFormErrorDetection();

  return (
    <section className={containerCss}>
      <Tabs
        defaultActiveKey="client"
        id="justify-tab-example"
        variant="underline"
        className="mb-3"
      >
        <Tab eventKey="client" title="Basic Info" className="py-2 px-3">
          <Row>
            <FormInput
              name="firstName"
              label="First name"
              showRequiredLabel={true}
              xs={12}
              md={6}
            />
            <FormInput
              name="lastName"
              label="Last name"
              showRequiredLabel={true}
              xs={12}
              md={6}
            />
          </Row>
          <Row>
            <FormInput
              name="email"
              label="Email"
              showRequiredLabel={true}
              xs={12}
              //md={4}
            />
          </Row>
          <Row>
            <FormPhoneInput
              name="mobileNumber"
              label="Mobile Number"
              mask="mobile"
              showRequiredLabel
              xs={12}
              md={6}
            />
            <FormPhoneInput
              name="landlineNumber"
              label="Landline Number"
              mask="phone"
              xs={12}
              md={6}
            />
          </Row>
        </Tab>
        <Tab eventKey="services" title="Address" className="py-2 px-3">
          <Row>{/* <PhAddressField /> */}</Row>
        </Tab>
      </Tabs>
      <Row>
        <Col>
          <FormCheckbox
            type="switch"
            name="allowPromotion"
            label="Send me digital ads and promotional newsletter"
            containerCss="ms-3"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <RcButton
            variant="pill-primary"
            size="lg"
            containerCss="w-100"
            //disabled={!values.captchaVerified}
            onClick={onSubmit}
          >
            Submit
          </RcButton>
        </Col>
      </Row>
    </section>
  );
};

SignupFormInputs.propTypes = {
  serviceLookups: PropTypes.array,
  containerCss: PropTypes.string,
};

SignupFormInputs.defaultProps = { serviceLookups: [] };

export default SignupFormInputs;
