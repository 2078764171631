import React, { useCallback, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import useStateWithRef from "hooks/useStateWithRef";
import RcNavBar from "./RcNavBar";
import { useAppTheme } from "hooks";
import "./rc-navbar-fx1.scss";
import useSystem from "hooks/useSystem";
import { NavHeaderContext } from "../navigationHeader/context/NavHeaderContextProvider";
import { NavHeaderTypes } from "constants/navHeaderTypes";

function RcNavBarFx1({ scrollTriggerValue, ...rest }) {
  const headerContext = useContext(NavHeaderContext);
  const { currentTheme } = useAppTheme();
  const navbarTheme = `navbar-fx1-${currentTheme}`;
  const { stateValue, stateRefValue, setStateValue } =
    useStateWithRef(`navbar-default `);
  const systemState = useSystem();

  const ANIMATED_STICKY = `navbar-animated-sticky ${navbarTheme}`;

  useEffect(() => {
    document.addEventListener("scroll", scrollFn, { passive: true });
    checkIfPageWasScrolled();

    return () => {
      document.removeEventListener("scroll", scrollFn);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headerContext.currentMode]);

  useEffect(() => {
    // Check if Fixed Mode
    if (headerContext.currentMode === NavHeaderTypes.FIXED_MODE) {
      systemState.changeLogo(2);
      setStateValue(ANIMATED_STICKY);
      return;
    }

    if (window.pageYOffset > scrollTriggerValue) {
      setStateValue(ANIMATED_STICKY);
      return;
    }

    systemState.changeLogo(1);
    setStateValue("navbar-default");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname, headerContext.currentMode]);

  const checkIfPageWasScrolled = () => {
    // Check if Fixed Mode
    if (headerContext.currentMode === NavHeaderTypes.FIXED_MODE) {
      systemState.changeLogo(2);
      setStateValue(ANIMATED_STICKY);
      return;
    }

    if (window.pageYOffset > scrollTriggerValue) {
      setStateValue(ANIMATED_STICKY);
    }
  };

  const scrollFn = (e) => {
    // Check if Fixed mode
    if (headerContext.currentMode === NavHeaderTypes.FIXED_MODE) {
      systemState.changeLogo(2);
      setStateValue(ANIMATED_STICKY);
      return;
    }

    if (
      window.pageYOffset > scrollTriggerValue //&&
      //stateRefValue !== ANIMATED_STICKY
    ) {
      systemState.changeLogo(2);
      setStateValue(ANIMATED_STICKY);
    } else {
      systemState.changeLogo(1);
      setStateValue("navbar-default");
    }
  };

  return (
    <RcNavBar
      {...rest}
      brandLogo={systemState.currentLogo}
      navBarCss={stateValue}
    />
  );
}

RcNavBarFx1.propTypes = {
  scrollTriggerValue: PropTypes.number,
};

RcNavBarFx1.defaultProps = { scrollTriggerValue: 30 };

export default RcNavBarFx1;
