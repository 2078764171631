import { useContext } from "react";
import { ThemeContext } from "./../context/themingContextProvider";

function useAppTheme() {
  const { currentTheme, changeThemeTo, adminCurrentTheme, changeAdminThemeTo } =
    useContext(ThemeContext);

  return { currentTheme, changeThemeTo, adminCurrentTheme, changeAdminThemeTo };
}

export default useAppTheme;
