import Http from "core/http/http";

function ProductService() {
  const apiUrl = "/v1/productServices";

  const getProductsAsync = () => Http().get(apiUrl);

  return {
    getProductsAsync,
  };
}

export default ProductService;
