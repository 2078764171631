/**
 * DataTable Extensions of react-table
 */
import React, { useMemo } from "react";
import PropTypes from "prop-types";
import {
  useTable,
  usePagination,
  useRowSelect,
  useGlobalFilter,
  useFlexLayout,
  useResizeColumns,
  useSortBy,
} from "react-table";
import "./data-table.scss";
import { noop } from "lodash";
import SearchBox from "./search/SearchBox";
import { cellProps, customHooks, headerProps } from "./helper";
import Pagination from "./pagination/Pagination";
import { Mobile, DefaultMediaQuery } from "components/ui/responsive";
import MobileLayoutFactory from "./layouts/MobileLayoutFactory";
import { customActionButtonHooks } from "./actionButtons/actionButtonHelper";
import { ObjectWatch } from "components/ui/formFields";
import NoRecord from "./noRecord/NoRecord";

const RcTable = ({
  columns,
  data,
  actionButtons,
  dataFlowLayout,
  containerCss,
  allowDebug,
  headerSettings,
  rowSettings,
  disableSortBy,
  rowItemHover,
  showSelectionCheckbox,
  paginationOptions,
  showSearch,
  showPagination,
  showFooter,
  ...rest
}) => {
  // Initialize settings
  const finalHeaderSettings = useMemo(
    () => ({
      headerTextCss: headerSettings.bold ? "isBold" : "",
      thSortableCss: !disableSortBy ? "sortable" : "",
    }),
    [headerSettings.bold, disableSortBy]
  );

  const finalRowSettings = useMemo(
    () => ({
      trStripedCss: rowSettings.striped ? "striped" : "",
      trHoverCss: rowItemHover ? "tr-item" : "",
    }),
    [rowSettings.striped, rowItemHover]
  );

  const finalHeaderGroupPropStyle =
    dataFlowLayout === "contain" ? { style: { paddingRight: "17px" } } : {};

  const finalBodySettings = {
    tbodyCss: dataFlowLayout === "contain" ? "isContain" : "isOverflow",
  };

  const defaultColumn = React.useMemo(
    () => ({
      // When using the useFlexLayout:
      minWidth: 30, // minWidth is only used as a limit for resizing
      width: 150, // width is used for both the flex-basis and flex-grow
      maxWidth: 200, // maxWidth is only used as a limit for resizing
    }),
    []
  );

  // Page size
  let defaultFinalPageSize =
    paginationOptions?.sizeSelection !== undefined &&
    paginationOptions?.defaultPageSize === undefined
      ? paginationOptions.sizeSelection[0]
      : paginationOptions?.defaultPageSize === undefined
      ? 10
      : paginationOptions?.defaultPageSize;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page, which has only the rows for the active page
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
    preGlobalFilteredRows,
    setGlobalFilter,
    visibleColumns,
    state: { pageIndex, pageSize, selectedRowIds, globalFilter },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      disableSortBy,
      initialState: {
        pageSize: defaultFinalPageSize,
      },
    },
    useResizeColumns,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useFlexLayout,
    useRowSelect,
    customHooks(showSelectionCheckbox),
    customActionButtonHooks(actionButtons)
  );

  const renderTable = () => {
    return (
      <>
        {showSearch && (
          <div
            className="search-box"
            colSpan={visibleColumns.length}
            style={{
              textAlign: "left",
            }}
          >
            <SearchBox
              {...rest}
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
            />
          </div>
        )}
        <div {...getTableProps()} className="table">
          <div className="table-container">
            <div className="table-items">
              <div>
                {headerGroups.map((headerGroup) => (
                  <div
                    {...headerGroup.getHeaderGroupProps(
                      finalHeaderGroupPropStyle
                    )}
                    className="tr tr-header"
                  >
                    {headerGroup.headers.map((column) => (
                      <div
                        {...column.getHeaderProps(headerProps)}
                        className={`th th-item  ${
                          finalHeaderSettings.thSortableCss
                        } ${
                          column.isSorted
                            ? column.isSortedDesc
                              ? "sortDesc"
                              : "sortAsc"
                            : ""
                        }`}
                        onClick={() => (column.toggleSortBy || noop)()}
                      >
                        <span
                          className={`header-text ${finalHeaderSettings.headerTextCss}`}
                        >
                          {column.render("Header")}
                        </span>
                        {/* Use column.getResizerProps to hook up the events correctly */}
                        {column.canResize && (
                          <div
                            {...column.getResizerProps()}
                            className={`resizer ${
                              column.isResizing ? "isResizing" : ""
                            }`}
                          />
                        )}
                      </div>
                    ))}
                  </div>
                ))}
              </div>
              <div
                {...getTableBodyProps()}
                className={`tbody ${finalBodySettings.tbodyCss}`}
              >
                {page.length === 0 && <NoRecord />}

                {page.map((row, i) => {
                  prepareRow(row);

                  return (
                    <div
                      {...row.getRowProps()}
                      className={`tr ${finalRowSettings.trStripedCss} ${finalRowSettings.trHoverCss}`}
                    >
                      {row.cells.map((cell) => {
                        return (
                          <div {...cell.getCellProps(cellProps)} className="td">
                            {cell.render("Cell")}
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
              {showFooter && (
                <div className="tfoot">
                  {footerGroups.map((group) => (
                    <div className="tr" {...group.getFooterGroupProps()}>
                      {group.headers.map((column) => (
                        <div className="td" {...column.getFooterProps()}>
                          {column.render("Footer")}
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <section className={`rca-table ${containerCss}`}>
      <Mobile>
        {showPagination && (
          <Pagination
            gotoPage={gotoPage}
            previousPage={previousPage}
            nextPage={nextPage}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            pageCount={pageCount}
            pageIndex={pageIndex}
            pageOptions={pageOptions}
            pageSize={pageSize}
            setPageSize={setPageSize}
            paginationOptions={paginationOptions}
          />
        )}
        <MobileLayoutFactory
          headerGroups={headerGroups}
          page={page}
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          showSearch={showSearch}
        />
        {showPagination && (
          <Pagination
            gotoPage={gotoPage}
            previousPage={previousPage}
            nextPage={nextPage}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            pageCount={pageCount}
            pageIndex={pageIndex}
            pageOptions={pageOptions}
            pageSize={pageSize}
            setPageSize={setPageSize}
            paginationOptions={paginationOptions}
          />
        )}
      </Mobile>

      <DefaultMediaQuery>
        {renderTable()}
        {showPagination && (
          <Pagination
            gotoPage={gotoPage}
            previousPage={previousPage}
            nextPage={nextPage}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            pageCount={pageCount}
            pageIndex={pageIndex}
            pageOptions={pageOptions}
            pageSize={pageSize}
            setPageSize={setPageSize}
            paginationOptions={paginationOptions}
          />
        )}
      </DefaultMediaQuery>

      {allowDebug && (
        <div>
          <ObjectWatch
            value={{
              selectedRowIds: selectedRowIds,
              "selectedFlatRows[].original": selectedFlatRows.map(
                (d) => d.original
              ),
            }}
          />
        </div>
      )}
    </section>
  );
};

RcTable.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  dataFlowLayout: PropTypes.string, // contain or overflow options
  actionButtons: PropTypes.object,
  containerCss: PropTypes.string,
  allowDebug: PropTypes.bool,
  headerSettings: PropTypes.object,
  rowSettings: PropTypes.object,
  disableSortBy: PropTypes.bool,
  rowItemHover: PropTypes.bool,
  showSelectionCheckbox: PropTypes.bool,
  // Search box props
  searchBoxContainerCss: PropTypes.string,
  searchLabel: PropTypes.string,
  showLabel: PropTypes.bool,
  searchPlaceholder: PropTypes.string,
  showSearch: PropTypes.bool,
  // Pagination
  paginationOptions: PropTypes.shape({
    sizeSelection: PropTypes.array,
    selectionLabel: PropTypes.string,
    defaultPageSize: PropTypes.number,
  }),
  showPagination: PropTypes.bool,
  showFooter: PropTypes.bool,
};

RcTable.defaultProps = {
  columns: [],
  data: [],
  dataFlowLayout: "contain",
  headerSettings: {
    bold: true,
  },
  rowSettings: {
    striped: true,
  },
  disableSortBy: false,
  allowDebug: false,
  rowItemHover: true,
  showSelectionCheckbox: false,
  showSearch: true,
  showPagination: true,
  showFooter: false,
};

export default RcTable;
