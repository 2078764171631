import React from "react";
import PropTypes from "prop-types";

function MemberName({ name, title, containerCss }) {
  return (
    <section
      className={`d-flex flex-column align-items-center ${containerCss}`}
    >
      <h6>{name}</h6>
      <span className="tsmall1">{title}</span>
    </section>
  );
}

MemberName.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
  containerCss: PropTypes.string,
};

MemberName.defaultProps = {};

export default MemberName;
