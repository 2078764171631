import React from "react";
import PropTypes from "prop-types";
import Measure from "react-measure";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { Image } from "react-bootstrap";
import ImagePreview from "components/ui/imagePreview/ImagePreview";
import { useState } from "react";

function ImageMasonry({ data, gutter, rounded }) {
  const [previewInfo, setPreviewInfo] = useState({
    imageUrl: null,
    isOpen: false,
  });

  const handleOpenPreview = (imageUrl) => {
    setPreviewInfo({
      imageUrl,
      isOpen: true,
    });
  };

  const handleClosePreview = () => {
    setPreviewInfo({ isOpen: false, imageUrl: "" });
  };

  const items = data.map((o) => (
    <Measure key={o.id}>
      {({ measureRef }) => (
        <Image
          ref={measureRef}
          key={o.id}
          src={o.image}
          alt={o.alt}
          className="image-item"
          onClick={() => handleOpenPreview(o.image)}
          rounded={rounded}
        />
      )}
    </Measure>
  ));

  return (
    <>
      <ImagePreview
        isOpen={previewInfo.isOpen}
        imageLargeUrl={previewInfo.imageUrl}
        showRotate={true}
        closePreview={handleClosePreview}
      />
      <ResponsiveMasonry
        columnsCountBreakPoints={{ 300: 2, 500: 2, 700: 4, 900: 5 }}
      >
        <Masonry columnsCount={3} gutter={gutter}>
          {items}
        </Masonry>
      </ResponsiveMasonry>
    </>
  );
}

ImageMasonry.propTypes = {
  data: PropTypes.array,
  gutter: PropTypes.string,
  rounded: PropTypes.bool,
  containerCss: PropTypes.string,
};

ImageMasonry.defaultProps = { data: [], gutter: "4px", rounded: false };

export default ImageMasonry;
