export const handleTextValidation =
  (errorMessage = "Field is required") =>
  (value) => {
    return !value ? errorMessage : undefined;
  };

export const handleDropdownValidation =
  (errorMessage = "Field is required") =>
  (value) => {
    return !value ? errorMessage : undefined;
  };
