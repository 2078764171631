import React from "react";
import PropTypes from "prop-types";

function FileRejections({ data }) {
  const getErrorMessage = (e) => {
    if (e.code === "file-too-large") {
      let fileSize = e.message.match(/\d+/);
      fileSize = Math.ceil(parseInt(fileSize, 10) / 1024);

      return `File is larger than ${fileSize} kb`;
    }

    return e.message;
  };

  const getFileSizeOfActualFile = (fileSize) =>
    Math.ceil(parseInt(fileSize, 10) / 1024);

  return (
    <>
      <ul className="list-unstyled mb-0">
        {data.map(({ file, errors }) => (
          <div key={file.path} className="mb-2 tsmall1 text-danger text-center">
            <li>
              {file.path} - {getFileSizeOfActualFile(file.size)} kb
            </li>
            {errors.map((e) => (
              <li key={e.code} className="tsmall">{getErrorMessage(e)}</li>
            ))}
          </div>
        ))}
      </ul>
    </>
  );
}

FileRejections.propTypes = {
  data: PropTypes.array,
};

FileRejections.defaultProps = { data: [] };

export default FileRejections;
