export const PRIMARY_PHONE = "(63) 916 492 6578";
export const PRIMARY_PHONE2 = "(63) 939 915 2047";
export const LANDLINE_PHONE = "8994 4554";
export const LANDLINE_PHONE2 = "8352 7089";
export const LANDLINE_PHONE3 = "8352 4763";
export const FOOTER_PHONE1 = "(63) 923 278 6034";
export const FOOTER_PHONE2 = "8994 4554";
export const FOOTER_PHONE3 = "8352 7089";
export const FOOTER_PHONE4 = "8352 4763";
export const CLIENT_EMAIL = "avillarante.vistaestates@gmail.com";
export const CLIENT_EMAIL2 = "sales@vistaestatesouth.com";
export const CLIENT_EMAIL3 = "avillarante@vistaestatesouth.com";
export const OPENING_DAY_TIME = "Monday-Saturday 7 AM - 5 PM";
