const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  minHeight: "120px",
  padding: "20px",
  borderWidth: "2px",
  borderRadius: "2px",
  borderColor: "#717579",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#343a40",
  outline: "none",
  transition: "border 0.24s ease-in-out",
  cursor: "pointer",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#6a89cc",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

export { baseStyle, focusedStyle, acceptStyle, rejectStyle };
