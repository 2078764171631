import React from "react";
import PropTypes from "prop-types";
import { Col } from "react-bootstrap";

function PlainText({ type, label, value, containerCss }) {
  const getType = () => {
    switch (type) {
      case "password":
        return <div>&#9679;&#9679;&#9679;&#9679;&#9679;</div>;

      case "html":
        return (
          <div
            className={containerCss}
            dangerouslySetInnerHTML={{ __html: value }}
          />
        );

      default:
        return <div>{value}</div>;
    }
  };

  return (
    <Col className={`mb-3 ${containerCss}`}>
      <label className="tsmall1 text-muted mb-1">{label}</label>
      {getType()}
    </Col>
  );
}

PlainText.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  containerCss: PropTypes.string,
};

PlainText.defaultProps = {};

export default PlainText;
