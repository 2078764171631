import React from "react";
import PropTypes from "prop-types";
import { noop } from "lodash";
import { Card, Col, Row } from "react-bootstrap";
import IconProvider from "../utils/IconProvider";
import { limitFileName } from "../utils/helper";
import RcBlockUi from "components/ui/rcBlockUi/RcBlockUi";
import RcTooltip from "components/ui/rcTooltip/RcTooltip";
import { ReactComponent as CloudSvg } from "assets/images/feather/download-cloud.svg";

function FileListing({ files, onRemoveFile, onDownloadFile }) {
  return (
    <>
      {files.map((file, index) => {
        const ext = file.name.substring(file.name.lastIndexOf(".") + 1);
        const icon = IconProvider().getIcon(ext);
        let limitedName = limitFileName(file.name);

        return (
          <Col key={file.id} className="my-2" xs="6" md="4" lg="3">
            <RcBlockUi blocking={file.isBusy}>
              <Card>
                <Card.Body>
                  <div className="remove-button">
                    <div className="d-flex flex-column align-items-center">
                      <i
                        id={`remove${index}`}
                        className="fas fa-times cursor-pointer text-muted file-remove mb-2"
                        onClick={() => onRemoveFile(file)}
                      />
                      <RcTooltip message="Remove File" selector="file-remove" />
                    </div>
                  </div>
                  <Row>
                    <Col
                      className="d-flex flex-row justify-content-center"
                      md="12"
                      xs="12"
                    >
                      <i
                        id={`download${index}`}
                        className={`${icon} fa-6x cursor-pointer p-2`}
                      />
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <Row>
                    <Col>
                      <span className="text-muted small">{limitedName}</span>
                      <div className="d-flex flex-row justify-content-between">
                        <div className="font-weight-bold small">
                          ({Math.round(file.size / 1024)} kb)
                        </div>
                        {file.type === "init" && (
                          <>
                            <CloudSvg
                              height={16}
                              className="file-download cursor-pointer"
                              onClick={() => onDownloadFile(file)}
                            />
                            <RcTooltip
                              message="Download File"
                              selector="file-download"
                            />
                          </>
                        )}
                      </div>
                    </Col>
                  </Row>
                  {file.hasError && (
                    <Row>
                      <div className="d-inline">
                        <i className="fas fa-exclamation-circle fg-error small me-2"></i>
                        <span className="fg-error small file-operation-error">
                          Error
                        </span>
                        <RcTooltip
                          message={file.errorMessage}
                          selector="file-operation-error"
                        />
                      </div>
                    </Row>
                  )}
                </Card.Footer>
              </Card>
            </RcBlockUi>
          </Col>
        );
      })}
    </>
  );
}

FileListing.propTypes = {
  files: PropTypes.array,
  onRemoveFile: PropTypes.func,
  onDownloadFile: PropTypes.func,
};

FileListing.defaultProps = {
  files: [],
  onRemoveFile: noop,
  onDownloadFile: noop,
};

export default FileListing;
