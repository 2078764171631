import React from "react";
import PropTypes from "prop-types";

function LocationPin({ text }) {
  return (
    <div className="pin">
      <i className="fas fa-map-marker-alt location-icon mt-4" />
      <p className="pin-text" dangerouslySetInnerHTML={{ __html: text }} />
    </div>
  );
}

LocationPin.propTypes = {
  text: PropTypes.string,
};

LocationPin.defaultProps = {};

export default LocationPin;
