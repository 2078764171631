import React from "react";
import PropTypes from "prop-types";
import "./intro-text-center.scss";

function IntroTextCenter({ containerCss, children }) {
  return (
    <section
      className={`intro-text-wrapper custom-heading d-flex flex-column justify-content-center align-items-center ${containerCss}`}
    >
      {children}
    </section>
  );
}

IntroTextCenter.propTypes = {
  containerCss: PropTypes.string,
};

IntroTextCenter.defaultProps = {};

export default React.memo(IntroTextCenter);
