import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import Label from "../label/Label";
import PlainTextList from "../plainText/PlainTextList";

function CheckboxGroup({
  name,
  type,
  options,
  containerCss,
  values,
  label,
  helpText,
  validationComponent,
  inline,
  labelBold,
  showRequiredLabel,
  plaintext,
  onChange,
  ...rest
}) {
  let plainTextValues = useMemo(() => {
    return values.map((o) => o.label);
  }, [values]);

  if (plaintext) {
    return <PlainTextList label={label} data={plainTextValues} />;
  }

  return (
    <section className={`mb-3 ${containerCss}`}>
      <Label
        label={label}
        labelBold={labelBold}
        showRequiredLabel={showRequiredLabel}
      />
      <Form>
        {options.map((item, i) => (
          <Form.Check
            key={i}
            name={name}
            type={type}
            id={`${name}-${item.value}`}
            label={item.label}
            checked={values?.some(({ value }) => value === item.value)}
            inline={inline}
            disabled={item?.disabled}
            onChange={() => onChange(item)}
            {...rest}
          />
        ))}
        {helpText && (
          <div>
            <Form.Text muted>{helpText}</Form.Text>
          </div>
        )}
        {validationComponent}
      </Form>
    </section>
  );
}

CheckboxGroup.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.array,
  values: PropTypes.array,
  validationComponent: PropTypes.object,
  containerCss: PropTypes.string,
  helpText: PropTypes.string,
  inline: PropTypes.bool,
  plaintext: PropTypes.bool,
  onChange: PropTypes.func,
};

CheckboxGroup.defaultProps = { options: [], values: [] };

export default CheckboxGroup;
