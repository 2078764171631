import {
  createAsyncThunk,
  createDraftSafeSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { uniqueId } from "lodash";
import ContactUsService from "services/modules/contactUsService";
import * as SystemSlice from "store/system/systemSlice";

const initialState = {
  formId: "1",
  loading: false,
};

// ---------------
// Action Thunks
// ---------------
export const createMessage = createAsyncThunk(
  "contactUs/createMessage",
  async (data, thunkAPI) => {
    try {
      const payload = {
        firstName: data.fname,
        lastName: data.lname,
        email: data.email,
        phoneNumber: data.phone,
        company: data.company,
        message: data.message,
      };

      const result = await new ContactUsService().createMessageAsync(payload);

      thunkAPI.dispatch(
        SystemSlice.showNotification("Message has been successfully sent.")
      );

      return result.data;
    } catch (err) {
      thunkAPI.dispatch(
        SystemSlice.showNotificationError({
          message: "Unable to create message.",
          error: err,
        })
      );
      throw err;
    }
  }
);

// ------------
// Slice
// ------------
export const contactUsSlice = createSlice({
  name: "contactUs",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //
    // Create Message Service
    //
    builder.addCase(createMessage.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createMessage.fulfilled, (state, action) => {
      state.formId = uniqueId("form");
      state.loading = false;
    });
    builder.addCase(createMessage.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default contactUsSlice.reducer;

// ------------
// Selectors
// ------------
const selectSelf = (state) => state.contactUs;

export const selectContactUs = createDraftSafeSelector(
  selectSelf,
  (state) => state
);
