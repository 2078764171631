import React, { createContext, useState } from "react";

export const ThemeContext = createContext();

const ThemeContextProvider = ({
  defaultTheme,
  adminDefaultTheme,
  children,
}) => {
  const [currentTheme, setCurrentTheme] = useState(defaultTheme);
  const [adminCurrentTheme, setAdminCurrentTheme] = useState(adminDefaultTheme);

  /**
   * Toggle theme only by Light or Dark mode.
   */
  const toggleTheme = () => {
    let themeName = currentTheme === "light" ? "dark" : "light";
    setCurrentTheme(themeName);
  };

  /**
   * Toggle Admin theme only by Light or Dark mode.
   */
  const toggleAdminTheme = () => {
    let themeName = adminCurrentTheme === "light" ? "dark" : "light";
    setAdminCurrentTheme(themeName);
  };

  const changeThemeTo = (name) => {
    setCurrentTheme(name);
  };

  const changeAdminThemeTo = (name) => {
    setAdminCurrentTheme(name);
  };

  return (
    <ThemeContext.Provider
      value={{
        currentTheme,
        adminCurrentTheme,
        toggleTheme,
        changeThemeTo,
        toggleAdminTheme,
        changeAdminThemeTo,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeContextProvider;
