import useFileUploaderUtils from "components/ui/formFields/fileUpload/hooks/useFileUploaderUtils";
import FileUploadService from "services/fileUploadService";

function useFileUpload({ moduleOwner }) {
  const { getFormData } = useFileUploaderUtils();

  const uploadFile = async (files, mode, resolve, reject) => {
    const formData = getFormData(files, moduleOwner, mode);

    try {
      const resp = await FileUploadService().uploadFile(formData);
      resolve(resp.data);
    } catch (ex) {
      console.log(ex);
      reject(ex);
    }
  };

  const removeFile = (id, isImage) => {
    return FileUploadService().removeFile(id, isImage);
  };

  const getFiles = async (ids, isImage) => {
    const payload = {
      ids,
      isImage,
    };

    const resp = await FileUploadService().getFiles(payload);

    return resp.data;
  };

  return { getFiles, uploadFile, removeFile };
}

export default useFileUpload;
