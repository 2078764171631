import React from "react";
import PropTypes from "prop-types";
import ImageMasonry from "./ImageMasonry";
import { ContentPane } from "components/ui/contentPane";
import SectionTitle from "components/ui/pageSection/SectionTitle";
import { Col, Row } from "react-bootstrap";
import "./gallery.scss";
import galleryData from "data/gallery";

function Gallery({ containerCss }) {
  return (
    <ContentPane
      id="gallery"
      isFullWidth={true}
      hasShadow={false}
      containerCss="gallery-wrapper"
      contentCss="content-box"
      contentPaddingHorizontal="3"
      contentPaddingVertical="3"
      mainContentCss="main-content py-5"
    >
      <SectionTitle title="Team's Gallery" subTitle="Abbatoir in Action" />
      <Row className="section-content">
        <Col xs={12}>
          <ImageMasonry data={galleryData} />
        </Col>
      </Row>
    </ContentPane>
  );
}

Gallery.propTypes = {
  containerCss: PropTypes.string,
};

Gallery.defaultProps = {};

export default Gallery;
