import React from "react";
import PropTypes from "prop-types";
import BasicModal from "components/ui/formFields/modals/basicModal/BasicModal";
import DetailsFactory from "../detailsFactory/DetailsFactory";

function ServiceDetailModal({ isOpen, type, onHide, containerCss }) {
  return (
    <BasicModal
      title="Service Details"
      isOpen={isOpen}
      onHide={onHide}
      onOkButton={onHide}
      okButtonText="Close"
      showFooter={false}
      showCancel={false}
    >
      <DetailsFactory type={type} />
    </BasicModal>
  );
}

ServiceDetailModal.propTypes = {
  isOpen: PropTypes.bool,
  type: PropTypes.string,
  onHide: PropTypes.func,
  containerCss: PropTypes.string,
};

ServiceDetailModal.defaultProps = {};

export default ServiceDetailModal;
