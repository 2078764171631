import React from "react";
import PropTypes from "prop-types";
import FeatureItem from "./FeatureItem";
import RcTooltip from "components/ui/rcTooltip/RcTooltip";
import useMediaQueryExt from "hooks/useMediaQueryExt";

function FeaturesAndAmenities({ data, containerCss, onPreview }) {
  const { isMobile } = useMediaQueryExt();

  return (
    <div className={containerCss}>
      {data.map((o, i) => (
        <FeatureItem
          key={o.sequenceOrder}
          isImage={o.isImage}
          imageUrl={o.imageUrl}
          featureMarkDown={o.featureMarkdownContent}
          isLastItem={i === data.length - 1}
          onPreview={onPreview}
        />
      ))}
      <RcTooltip
        message="Click to enlarge the image"
        selector="feature-image"
        place={`${isMobile ? "bottom" : "right"}`}
      />
    </div>
  );
}

FeaturesAndAmenities.propTypes = {
  data: PropTypes.array,
  containerCss: PropTypes.string,
};

FeaturesAndAmenities.defaultProps = { data: [] };

export default React.memo(FeaturesAndAmenities);
