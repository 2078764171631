import PropTypes from "prop-types";
import "./off-canvas-menus.scss";

function OffCanvasCustomMenus({ containerCss }) {
  return (
    <section className={`off-canvas-custom-menu-wrapper w-100 ${containerCss}`}>
      <hr className="fg-white" />
      {/* <div className="fg-white">
        Connect with me <li className="fa-solid fa-heart fg-red" />
      </div>
      <div className=" d-flex flex-row ">
        <a
          href="https://www.linkedin.com/"
          alt="instagram"
          target="_blank"
          rel="noreferrer"
          className="menu-options me-3"
        >
          <li className="fa-brands fa-linkedin social-icon"></li>
        </a>
        <a
          href="https://github.com/rcnet4"
          alt="gitHub"
          target="_blank"
          rel="noreferrer"
          className="menu-options me-4"
        >
          <li className="fa-brands fa-github social-icon"></li>
        </a>
        <a
          href="https://facebook.com"
          alt="instagram"
          target="_blank"
          rel="noreferrer"
          className="menu-options me-3"
        >
          <li className="fa-brands fa-facebook-square social-icon"></li>
        </a>
        <a
          href="https://twitter.com"
          alt="instagram"
          target="_blank"
          rel="noreferrer"
          className="menu-options me-3"
        >
          <li className="fa-brands fa-twitter me-3 social-icon"></li>
        </a>
      </div> */}
    </section>
  );
}

OffCanvasCustomMenus.propTypes = {
  containerCss: PropTypes.string,
};

OffCanvasCustomMenus.defaultProps = {};

export default OffCanvasCustomMenus;
