import Http from "../core/http/http";

function ProfileService() {
  let apiUrl = "/v1/profile";

  const getProfileAsync = () => Http().get(`${apiUrl}`);

  const updateProfileAsync = (payload) =>
    Http().patch(`${apiUrl}/${payload.id}`, payload);

  return {
    getProfileAsync,
    updateProfileAsync,
  };
}

export default ProfileService;
