/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { useEffect } from "react";
import IsScrolling from "react-is-scrolling";
import "./rc-scroll-to-top.scss";

const RcScrollToTop = ({ isScrollingUp, isScrolling, iconCss }) => {
  const [isVisible, setIsVisible] = useState(false);
  const scrollDirectionUpRef = useRef(false);

  useEffect(() => {
    document.addEventListener("scroll", scrollFn);

    return () => {
      //console.log("cleanup!");
      document.removeEventListener("scroll", scrollFn);
    };
  }, []);

  useEffect(() => {
    if (isScrolling) {
      scrollDirectionUpRef.current = isScrollingUp;
    }
  });

  const scrollFn = (e) => {
    toggleVisibility();
  };

  const toggleVisibility = () => {
    //console.log(window.pageYOffset);
    setIsVisible(window.pageYOffset > 300);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // console.log(`Scrolling: ${isScrolling}, Up: ${isScrollingUp}`);
  // console.log(scrollDirectionUpRef.current);

  return (
    <section className="rc-scroll-to-top">
      {isVisible && scrollDirectionUpRef.current && (
        <div onClick={() => scrollToTop()}>
          <i className={`fas fa-caret-up fa-3x ${iconCss}`} />
        </div>
      )}
    </section>
  );
};

RcScrollToTop.propTypes = {
  iconCss: PropTypes.string,
};

RcScrollToTop.defaultProps = {
  iconCss: "rca-scroll",
};

export default IsScrolling(RcScrollToTop);
