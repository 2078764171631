/**
 * Mini reducer for small or utility components
 * No need to create full blown reducers in Redux.
 */
 import isEmpty from "lodash/isEmpty";

 const initialState = {
   id: "",
   accountId: "",
   firstName: "",
   lastName: "",
   nickName: "",
   email: "",
   mobile: "",
   isAuthenticated: false,
   authData: [],
   isProfileCompleted: false,
 
   loadingProfile: false,
   loginError: "",
   authLoadError: "",
 };
 
 const miniReducer = (state, action) => {
   switch (action.type) {
     case "LOGIN_USER":
       return {
         ...state,
         id: action.payload.userId,
         firstName: action.payload.firstName,
         lastName: action.payload.lastName,
         email: action.payload.userName,
         mobile: action.payload?.mobile,
         isAuthenticated: !isEmpty(action.payload.userId),
       };
 
     case "LOGIN_ERROR":
       return {
         ...state,
         loginError: action.payload,
       };
 
     case "LOGOUT_USER":
       return {
         ...state,
         ...initialState,
         loadingProfile: false,
       };
 
     case "LOAD_PROFILE_FAILURE":
       return {
         ...state,
         ...initialState,
         loadingProfile: false,
         loginError: action.payload,
       };
 
     case "LOAD_PROFILE_REQUEST":
       return {
         ...state,
         loadingProfile: true,
       };
 
     case "LOAD_PROFILE_SUCCESS":
       const profileData = action.payload.profileData;
 
       return {
         ...state,
         id: action.payload.userId,
         accountId: profileData.accountId,
         firstName:
           profileData.firstName == null
             ? profileData.firstName
             : action.payload.firstName,
         lastName:
           profileData.lastName == null
             ? profileData.lastName
             : action.payload.lastName,
         nickName: profileData.nickName,
         email: action.payload.userName,
         mobile: action.payload?.mobile,
         isAuthenticated: !isEmpty(action.payload.userId),
         authData: profileData.authorizationData,
         isProfileCompleted: profileData.isProfileCompleted,
         loadingProfile: false,
       };
 
     case "LOAD_AUTH_DATA":
       return {
         ...state,
         authData: action.payload.authData,
       };
 
     case "LOAD_AUTH_FAILURE":
       return {
         ...state,
         authLoadError: action.payload,
       };
 
     default:
       return state;
   }
 };
 
 export { initialState, miniReducer };
 