import React from "react";
import PropTypes from "prop-types";
import "./signin.scss";
import { Row, Container, Col, Card } from "react-bootstrap";
import { FormInput } from "components/ui/formikFields";
import { Formik } from "formik";
import { noop } from "lodash";
import { RcButton } from "components/ui/rcButton";
import { useNavigate } from "react-router-dom";

function SignInPage({ containerCss }) {
  const navigate = useNavigate();

  const handleSendMagicLink = () => navigate("/auth/client/magic-link-sent");

  return (
    <section
      className={`sign-in-wrapper d-flex flex-column align-items-center w-100 ${containerCss}`}
    >
      <Card className="w-100 py-3 shadow-sm">
        <Card.Title className="text-center">
          <h4>Sign In</h4>
        </Card.Title>
        <Card.Body>
          <Formik
            enableReinitialize={true}
            initialValues={{
              email: "",
            }}
            onSubmit={noop}
          >
            {({ handleSubmit, isSubmitting, ...rest }) => (
              <Container>
                <Row>
                  <FormInput
                    name="email"
                    label="Email"
                    labelBold={true}
                    showRequiredLabel={true}
                    xs={12}
                    md={6}
                    lg={12}
                  />
                </Row>
                <Row>
                  <Col>
                    <RcButton
                      variant="pill-primary"
                      size="lg"
                      containerCss="w-100"
                      onClick={handleSendMagicLink}
                    >
                      Send Magic Link
                    </RcButton>
                  </Col>
                </Row>
              </Container>
            )}
          </Formik>
        </Card.Body>
      </Card>
    </section>
  );
}

SignInPage.propTypes = {
  containerCss: PropTypes.string,
};

SignInPage.defaultProps = {};

export default SignInPage;
