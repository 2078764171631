import React from "react";
import PropTypes from "prop-types";
import { Badge, Col } from "react-bootstrap";

function PlainTextList({ label, data, inline, isBadge, containerCss }) {
  const getDataStyle = () => {
    if (data.length === 0) return <div>--</div>;

    if (inline) {
      return (
        <div className="d-flex flex-row">
          {data.map((item, i) => (
            <React.Fragment key={i}>
              {isBadge ? (
                <Badge className="me-2 bg-gray-300">
                  <span className="fg-black">{item}</span>
                </Badge>
              ) : (
                <div className="me-2">{item}, </div>
              )}
            </React.Fragment>
          ))}
        </div>
      );
    } else {
      return (
        <>
          {data.map((item, i) => (
            <div key={i}>{item}</div>
          ))}
        </>
      );
    }
  };

  return (
    <Col className={`mb-3 ${containerCss}`}>
      <label className="tsmall1 text-muted mb-1">{label}</label>
      {getDataStyle()}
    </Col>
  );
}

PlainTextList.propTypes = {
  data: PropTypes.array,
  inline: PropTypes.bool,
  isBadge: PropTypes.bool,
  containerCss: PropTypes.string,
};

PlainTextList.defaultProps = { inline: false, isBadge: false };

export default PlainTextList;
