import React, { useCallback } from "react";
import PropTypes from "prop-types";
import CheckboxGroup from "components/ui/formFields/checkboxGroup/CheckboxGroup";
import useFastField from "../hooks/useFastField";
import ValidationMessage from "../validationMessage/ValidationMessage";

function FormCheckboxGroup({
  type,
  name,
  options,
  validate,
  inline,
  containerCss,
  ...rest
}) {
  const { field, meta, helpers } = useFastField({ name, validate });

  const handleChange = useCallback(
    (selected) => {
      var found = field.value?.find((f) => f.value === selected.value);

      if (found) {
        helpers.setValue(field.value.filter((f) => f.value !== selected.value));
      } else {
        type !== "radio"
          ? helpers.setValue([...(field.value || []), { ...selected }])
          : helpers.setValue([{ ...selected }]);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [type, field, helpers]
  );

  return (
    <CheckboxGroup
      type={type}
      name={name}
      {...field}
      {...rest}
      options={options}
      values={field.value}
      isInvalid={!!(meta.touched && meta.error)}
      inline={inline}
      validationComponent={
        <ValidationMessage
          errorMessage={meta.touched && meta.error}
          forceDisplay={true}
        />
      }
      onChange={handleChange}
    />
  );
}

FormCheckboxGroup.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.array,
  inline: PropTypes.bool,
  validate: PropTypes.func,
  containerCss: PropTypes.string,
};

FormCheckboxGroup.defaultProps = {};

export default FormCheckboxGroup;
