import React, { Suspense, useContext } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import navBarMenus from "data/navbar-menus";
import Footer from "components/features/footer/Footer";
//import NavigationBar from "components/features/navigationBar/NavigationBar";
import Config from "config";
import RcScrollToTop from "components/ui/rcScrollToTop/RcScrollToTop";
//import HeaderRightMenus from "components/features/pluggableMenus/headerRightMenus/HeaderRightMenus";
import NavHeaderContextProvider, {
  NavHeaderContext,
} from "components/ui/navigationHeader/context/NavHeaderContextProvider";
import RcNavBar from "components/ui/rcNavBar/RcNavBar";
import OffCanvasCustomMenus from "components/features/pluggableMenus/offCanvasCustomMenus/OffCanvasCustomMenus";
import RcNavBarFx1 from "components/ui/rcNavBar/RcNavBarFx1";
import { RcButton } from "components/ui/rcButton";
import NavBarCustomButton from "components/features/navBarCustomButton/NavBarCustomButton";
import { useAppTheme } from "hooks";
import footerData from "data/footer-data";
import SuspenseLabel from "components/ui/suspenseLabel/SuspenseLabel";
//import NavigationHeader from "components/features/navigationHeader/NavigationHeader";

const ContentComponent = ({ keyId }) => {
  const headerContext = useContext(NavHeaderContext);

  return (
    <section
      className={`${
        headerContext.isFixedMode()
          ? "content-wrapper-fixed-header"
          : "content-wrapper"
      }`}
    >
      {Config.content.allowFx ? (
        <TransitionGroup>
          <CSSTransition
            key={keyId}
            timeout={{ enter: 250, exit: 250 }}
            classNames={"fade"}
          >
            <>
              <section className="route-section">
                <main role="main">
                  <Outlet />
                </main>
              </section>
            </>
          </CSSTransition>
        </TransitionGroup>
      ) : (
        <section className="route-section">
          <main role="main">
            <Suspense fallback={<SuspenseLabel />}>
              <Outlet />
            </Suspense>
          </main>
        </section>
      )}
    </section>
  );
};

const FullLayout = () => {
  const location = useLocation();
  const { currentTheme } = useAppTheme();

  return (
    <NavHeaderContextProvider>
      <div className={`app-${currentTheme}`}>
        <RcNavBarFx1
          variant="light"
          menus={navBarMenus.menus}
          brandLink="/"
          brandLogo="https://rcnetlabs-imaging.sirv.com/Vista/logo/header-logo-white.png"
          brandLogoHeight={90}
          brandLogoAlternateText="Rcnet Labs Logo"
          offCanvasBrandLogo="https://rcnetlabs-imaging.sirv.com/Vista/logo/header-logo-colored.png"
          fixed="top"
          menuPlacement="end"
          burgerMenuColor="#0d4e90"
          isOffCanvasMenu={true}
          scrollTriggerValue={80}
          offCanvasCustomComponent={<OffCanvasCustomMenus />}
          navBarRightPane={(e) => {
            return <NavBarCustomButton showOffCanvas={e?.showOffCanvas} />;
          }}
        />
        <ContentComponent keyId={location.key} />
        {/* <section className="content-wrapper">
          {Config.content.allowFx ? (
            <TransitionGroup>
              <CSSTransition
                key={location.key}
                timeout={{ enter: 250, exit: 250 }}
                classNames={"fade"}
              >
                <>
                  <section className="route-section">
                    <main role="main">
                      <Outlet />
                    </main>
                  </section>
                </>
              </CSSTransition>
            </TransitionGroup>
          ) : (
            <section className="route-section">
              <main role="main">
                <Outlet />
              </main>
            </section>
          )}
        </section> */}
        <Footer
          type={Config.footer.fatFooterType}
          copyrightText={Config.footer.copyrightText}
          data={footerData}
          //data={footerData.default}
          showFatFooter={Config.footer.showFatFooter}
        />
        <RcScrollToTop />
      </div>
    </NavHeaderContextProvider>
  );
};

export default FullLayout;
