/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useImperativeHandle, useRef } from "react";
import PropTypes from "prop-types";
import ImageGallery from "react-image-gallery";
import { useState } from "react";
import { useEffect } from "react";
import { isEmpty, isNil } from "lodash";
import "./image-gallery.scss";

const RcImageGallery = React.forwardRef(
  (
    {
      data,
      showNav,
      slideDuration,
      thumbnailPosition,
      infinite,
      showBullets,
      showFullscreenButton,
      showPlayButton,
      showThumbnails,
      showIndex,
      slideInterval,
      slideOnThumbnailOver,
      useWindowKeyDown,
      containerCss,
    },
    ref
  ) => {
    const galleryRef = useRef();
    const [showVideo, setShowVideo] = useState(false);
    const [showGalleryPlayButton, setShowGalleryPlayButton] = useState(true);
    const [showGalleryFullscreenButton, setShowGalleryFullscreenButton] =
      useState(true);
    const [images, setImages] = useState([]);
    const [imageSize, setImageSize] = useState("normal");
    const showVideoRef = useRef(false);

    useImperativeHandle(
      ref,
      () => {
        return {
          stopVideo() {
            _resetVideo();
          },
        };
      },
      []
    );

    const _resetVideo = () => {
      if (!showVideoRef.current) return;

      setShowVideo(false);
      showVideoRef.current = false;
      setShowGalleryPlayButton(true);
      setShowGalleryFullscreenButton(true);
    };

    const _onImageClick = (event) => {
      console.debug(
        "clicked on image",
        event.target,
        "at index",
        galleryRef.current.getCurrentIndex()
      );
    };

    const _onImageLoad = (event) => {
      //console.debug("loaded image", event.target.src);
    };

    const _onSlide = (index) => {
      _resetVideo();
      //console.debug("slid to index", index);
    };

    const _onPause = (index) => {
      //console.debug("paused on index", index);
    };

    const _onScreenChange = (fullScreenElement) => {
      setImageSize(fullScreenElement ? "fullscreen" : "normal");
      console.debug("isFullScreen?", !!fullScreenElement);
    };

    const _onPlay = (index) => {
      console.debug("playing from index", index);
    };

    function _toggleShowVideo() {
      showVideoRef.current = !showVideoRef.current;
      setShowVideo(showVideoRef.current);

      if (showVideoRef.current) {
        setShowGalleryPlayButton(false);
        setShowGalleryFullscreenButton(false);
      }
    }

    const _renderVideo = (item) => {
      return (
        <div>
          {showVideoRef.current ? (
            <div className="video-wrapper">
              <button className="close-video" onClick={_toggleShowVideo} />
              <iframe
                title="sample video"
                width="560"
                height="315"
                src={item.embedUrl}
                style={{ border: "none" }}
                className="iframe-video"
                allowFullScreen
              />
            </div>
          ) : (
            <>
              <button className="play-button" onClick={_toggleShowVideo} />
              <img
                alt="sample video cover"
                className="image-gallery-image"
                src={item.original}
              />
              {item.description && (
                <span
                  className="image-gallery-description"
                  style={{ right: "0", left: "initial" }}
                >
                  {item.description}
                </span>
              )}
            </>
          )}
        </div>
      );
    };

    useEffect(() => {
      const dataImages = data.map((item) => {
        if (!isNil(item.embedUrl) && !isEmpty(item.embedUrl)) {
          return {
            ...item,
            renderItem: _renderVideo,
          };
        }

        return {
          ...item,
        };
      });

      setImages(dataImages);
    }, [data]);

    if (images.length === 0) {
      return <></>;
    }

    return (
      <section className={`rc-gallery-wrapper ${imageSize} ${containerCss}`}>
        <ImageGallery
          ref={galleryRef}
          items={images}
          showNav={showNav}
          thumbnailPosition={thumbnailPosition}
          onClick={_onImageClick}
          onImageLoad={_onImageLoad}
          onSlide={_onSlide}
          onPause={_onPause}
          onScreenChange={_onScreenChange}
          onPlay={_onPlay}
          infinite={infinite}
          showBullets={showBullets}
          showFullscreenButton={
            showFullscreenButton && showGalleryFullscreenButton
          }
          showPlayButton={showPlayButton && showGalleryPlayButton}
          showThumbnails={showThumbnails}
          showIndex={showIndex}
          slideDuration={slideDuration}
          slideInterval={slideInterval}
          slideOnThumbnailOver={slideOnThumbnailOver}
          additionalClass="app-image-gallery"
          useWindowKeyDown={useWindowKeyDown}
        />
      </section>
    );
  }
);

RcImageGallery.propTypes = {
  data: PropTypes.array,
  showNav: PropTypes.bool,
  slideDuration: PropTypes.number,
  thumbnailPosition: PropTypes.string,
  infinite: PropTypes.bool,
  showBullets: PropTypes.bool,
  showFullscreenButton: PropTypes.bool,
  showPlayButton: PropTypes.bool,
  showThumbnails: PropTypes.bool,
  showIndex: PropTypes.bool,
  slideInterval: PropTypes.number,
  slideOnThumbnailOver: PropTypes.bool,
  useWindowKeyDown: PropTypes.bool,
  containerCss: PropTypes.string,
};

RcImageGallery.defaultProps = {
  data: [],
  showNav: false,
  slideDuration: 500,
  thumbnailPosition: "right",
  infinite: true,
  showBullets: false,
  showFullscreenButton: true,
  showPlayButton: true,
  showThumbnails: true,
  showIndex: false,
  slideInterval: 4000,
  slideOnThumbnailOver: true,
  useWindowKeyDown: true,
};

export default React.memo(RcImageGallery);
