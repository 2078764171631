import React from "react";
import PropTypes from "prop-types";
import { motion } from "framer-motion";
import "./section-title.scss";

function SectionTitle({ title, subTitle, containerCss }) {
  return (
    <motion.div
      initial={{ opacity: 0, scale: 0 }}
      whileInView={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.5 }}
      viewport={{ once: true }}
      className={`section-title-wrapper d-flex flex-column align-items-center ${containerCss}`}
    >
      <h3 className="rca-heading3 text-center">{title}</h3>
      <p className="text-center">{subTitle}</p>
      <div className="title-separator" />
    </motion.div>
  );
}

SectionTitle.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  containerCss: PropTypes.string,
};

SectionTitle.defaultProps = {};

export default SectionTitle;
