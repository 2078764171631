const data = [
  {
    value: "1",
    label: "Termite Control Solution",
    subCategories: [
      {
        value: "1.1",
        label: "Chemical Barrier/Reticulation Treatment",
      },
      {
        value: "1.2",
        label: "Pre-Construction Soil Treatment",
      },
      {
        value: "1.3",
        label: "Post-Structure Soil Treatment",
      },
      {
        value: "1.4",
        label: "Above Ground Bait Device Compliment",
      },
    ],
  },
  {
    value: "2",
    label: "Pest Control Solution",
    subCategories: [
      {
        value: "2.1",
        label: "Thermal Fogging",
      },
      {
        value: "2.2",
        label: "ULV Cold Fogging",
      },
      {
        value: "2.3",
        label: "Mist Blower Industrial Treatment",
      },
    ],
  },
  {
    value: "3",
    label: "Other Pest Control Services",
    subCategories: [
      {
        value: "3.1",
        label: "Rat Abatement",
      },
      {
        value: "3.2",
        label: "Rat Poisoning",
      },
      {
        value: "3.3",
        label: "Garden Spraying",
      },
      {
        value: "3.4",
        label: "Cockcroach Control",
      },
      {
        value: "3.5",
        label: "Worm Elimination",
      },
      {
        value: "3.6",
        label: "Ant Control",
      },
      {
        value: "3.7",
        label: "Flies and Bugs Elimination",
      },
    ],
  },
];

export default data;
