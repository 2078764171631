import React from "react";
import PropTypes from "prop-types";
import { motion } from "framer-motion";

function Framer({ containerCss }) {
  return (
    <section className={containerCss}>
      <div className="d-flex flex-column justify-content-center align-items-center">
        <motion.div
          initial={{ opacity: 0, x: "-40vh" }}
          transition={{ ease: "easeOut", duration: 1.2 }}
          animate={{ opacity: 1, x: 0 }}
        >
          <h1 style={{ fontSize: "100px" }}>Hello Rcnet!</h1>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: "40vh" }}
          transition={{ ease: "easeOut", duration: 1.3 }}
          animate={{ opacity: 1, y: 0 }}
        >
          <h1>Confidence</h1>
        </motion.div>
      </div>
    </section>
  );
}

Framer.propTypes = {
  containerCss: PropTypes.string,
};

Framer.defaultProps = {};

export default Framer;
