import React from "react";
import PropTypes from "prop-types";
import { ToastContainer } from "react-bootstrap";
import RcNotification from "./RcNotification";
import "./notification-section.scss";

function RcNotificationSection({ data, position, onClose }) {
  return (
    <ToastContainer
      className="p-3"
      position={position}
      style={{ zIndex: 9999 }}
    >
      {data.map((o, i) => (
        <RcNotification key={i} {...o} onClose={onClose} />
      ))}
    </ToastContainer>
  );
}

RcNotificationSection.propTypes = {
  data: PropTypes.array,
  position: PropTypes.string,
  onClose: PropTypes.func,
};

RcNotificationSection.defaultProps = {
  data: [],
  position: "top-end",
};

export default RcNotificationSection;
