import HttpConfiguration from "core/http/component/HttpConfiguration";
import { useNavigate } from "react-router-dom";

function HttpInterceptorHandler() {
  const navigate = useNavigate();

  const handleReLogin = () => {
    alert("Relogin");
  };

  const handleNetworkError = () => {
    navigate("/pages/error-500");
  };

  const handleForbiddenAccess = () => {
    alert("Forbidden");
  };

  return (
    <HttpConfiguration
      onReLogin={handleReLogin}
      onNetworkError={handleNetworkError}
      onForbiddenAccess={handleForbiddenAccess}
    />
  );
}

export default HttpInterceptorHandler;
