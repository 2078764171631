import React, { useImperativeHandle, useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import ReCAPTCHA from "react-google-recaptcha";
// import useSystem from "hooks/useSystem";
// import useRecaptcha from "hooks/useRecaptcha";

window.recaptchaOptions = {
  useRecaptchaNet: true,
  enterprise: true,
};

const RecaptchaInvisible = React.forwardRef(({ siteKey }, ref) => {
  // const { notify } = useSystem();
  // const { verifyTokenAsync, busy } = useRecaptcha();
  const captchaRef = useRef();
  const [show, setShow] = useState(false);

  useImperativeHandle(
    ref,
    () => {
      return {
        async executeAsync() {
          return new Promise((resolve, reject) => {
            captchaRef.current
              .execute()
              .then(async (token) => {
                resolve(token);
              })
              .catch((err) => {
                reject(err);
              });
          });
        },
        reset() {
          captchaRef.current.reset();
        },
      };
    },
    []
  );

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 1500);
  }, []);

  const handleAsyncScriptOnLoad = () => {
    console.log("Captcha loaded!");
  };

  const handleError = (err) => {
    console.error(err);
  };

  const handleChange = (value) => {
    console.log(`Captcha value set`);
  };

  if (!show) return null;

  return (
    <ReCAPTCHA
      style={{ display: "inline-block" }}
      theme="dark"
      ref={captchaRef}
      size="invisible"
      sitekey={siteKey}
      badge="bottomleft" // inline
      onChange={handleChange}
      asyncScriptOnLoad={handleAsyncScriptOnLoad}
      onErrored={handleError}
    />
  );
});

RecaptchaInvisible.propTypes = {
  siteKey: PropTypes.string,
};

RecaptchaInvisible.defaultProps = {};

export default React.memo(RecaptchaInvisible);
