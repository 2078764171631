import React from "react";
import FormAddressField from "components/ui/formikFields/formAddressField/FormAddressField";
import * as CommonDataSlice from "store/modules/commonDataSlice";
import { useSelector } from "react-redux";

function PhAddressField() {
  const { provinces } = useSelector(CommonDataSlice.selectCommonData);

  return <FormAddressField data={provinces} />;
}

export default PhAddressField;
