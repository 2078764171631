export const ourClientsData = [
  // {
  //   id: 1,
  //   image: "images/clients/client1.webp",
  //   alt: "Client 1",
  // },
  // {
  //   id: 2,
  //   image: "images/clients/client2.webp",
  //   alt: "Client 2",
  // },
  // {
  //   id: 3,
  //   image: "images/clients/client3.webp",
  //   alt: "Client 3",
  // },
  // {
  //   id: 4,
  //   image: "images/clients/client4.webp",
  //   alt: "Client 4",
  // },
  {
    id: 1,
    image: "images/clients/IMG_1.webp",
    alt: "Edmark",
  },
  {
    id: 2,
    image: "images/clients/IMG_2.webp",
    alt: "China Bank",
  },
  {
    id: 3,
    image: "images/clients/IMG_3.webp",
    alt: "TGP",
  },
  {
    id: 4,
    image: "images/clients/IMG_4.webp",
    alt: "Pancake",
  },
  {
    id: 5,
    image: "images/clients/IMG_5.webp",
    alt: "Suzuki",
  },
  {
    id: 6,
    image: "images/clients/IMG_6.webp",
    alt: "Ford",
  },
  {
    id: 7,
    image: "images/clients/IMG_7.webp",
    alt: "7 Eleven",
  },
  {
    id: 8,
    image: "images/clients/IMG_8.webp",
    alt: "Krispy Kreme",
  },
  {
    id: 9,
    image: "images/clients/IMG_9.webp",
    alt: "Petron",
  },
  {
    id: 10,
    image: "images/clients/IMG_10.webp",
    alt: "Promax",
  },
  {
    id: 11,
    image: "images/clients/IMG_11.webp",
    alt: "Sycwin",
  },
  {
    id: 12,
    image: "images/clients/IMG_12.webp",
    alt: "DaTriMa",
  },
  {
    id: 13,
    image: "images/clients/IMG_13.webp",
    alt: "JT's Manukan",
  },
  {
    id: 14,
    image: "images/clients/IMG_14.webp",
    alt: "Croco Tape",
  },
];
