const educationalAttainment = [
  {
    value: 0,
    label: "Elementary",
  },
  {
    value: 1,
    label: "High School",
  },
  {
    value: 2,
    label: "Bachelors Degree",
  },
  {
    value: 3,
    label: "Masters Degree",
  },
  {
    value: 4,
    label: "Doctoral",
  },
];

export default educationalAttainment;
