import React from "react";
import PropTypes from "prop-types";

  function DashboardPage ({containerCss}) {
      return (
          <section className={containerCss}>
            <h1>Welcome!</h1>
          </section>
      );
   };

   DashboardPage.propTypes = {
    containerCss: PropTypes.string
   };

   DashboardPage.defaultProps = {

   };
   
export default DashboardPage;