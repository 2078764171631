import React from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import { RcButton } from "components/ui/rcButton";
import { Formik } from "formik";
import RcBlockUi from "components/ui/rcBlockUi/RcBlockUi";

function FormModal({
  isOpen,
  onHide,
  className,
  title,
  children,
  okButtonType,
  okButtonText,
  showCancel,
  cancelButtonType,
  cancelButtonText,
  disableOkButton,
  initialValues,
  formSchema,
  size,
  backdrop,
  centered,
  containerCss,
  loading,
  onFormSubmit,
  ...rest
}) {
  const handleHide = () => onHide("close");

  const handleCancelHide = () => onHide("cancel");

  return (
    <Modal
      {...rest}
      show={isOpen}
      size={size}
      onHide={handleHide}
      dialogClassName={containerCss}
      backdrop={backdrop}
      aria-labelledby="form-modal"
      centered={centered}
    >
      <Modal.Header closeButton>
        <Modal.Title id="form-modal">{title}</Modal.Title>
      </Modal.Header>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={formSchema}
        onSubmit={onFormSubmit}
      >
        {({ handleSubmit, isSubmitting, ...rest }) => (
          <>
            <RcBlockUi blocking={isSubmitting || loading}>
              <Modal.Body>{children}</Modal.Body>
            </RcBlockUi>
            <Modal.Footer>
              <RcButton
                variant={okButtonType}
                //size="lg"
                loading={isSubmitting || loading}
                disabled={isSubmitting || disableOkButton}
                onClick={handleSubmit}
              >
                {okButtonText}
              </RcButton>

              {showCancel ? (
                <RcButton
                  variant={cancelButtonType}
                  //size="lg"
                  //loading={isSubmitting || loading}
                  disabled={isSubmitting || disableOkButton}
                  onClick={handleCancelHide}
                >
                  {cancelButtonText}
                </RcButton>
              ) : null}
            </Modal.Footer>
          </>
        )}
      </Formik>
    </Modal>
  );
}

FormModal.propTypes = {
  title: PropTypes.string,
  showCancel: PropTypes.bool,
  disableOkButton: PropTypes.bool,
  okButtonType: PropTypes.string,
  okButtonText: PropTypes.string,
  cancelButtonType: PropTypes.string,
  cancelButtonText: PropTypes.string,
  initialValues: PropTypes.object,
  formSchema: PropTypes.object,
  size: PropTypes.string,
  centered: PropTypes.bool,
  children: PropTypes.any,
  backdrop: PropTypes.string,
  modalClick: PropTypes.func,
  onFormSubmit: PropTypes.func,
};

FormModal.defaultProps = {
  title: "Form Modal",
  showCancel: true,
  disableOkButton: false,
  okButtonType: "primary",
  okButtonText: "Submit",
  cancelButtonType: "secondary",
  cancelButtonText: "Cancel",
  initialValues: {},
  formSchema: null,
  size: "lg",
  backdrop: "static",
  centered: false,
  modalClick: () => {},
  onFormSubmit: () => {},
};

export default FormModal;
