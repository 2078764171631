import React from "react";
import PropTypes from "prop-types";

const FooterBase = ({
  fatFooter,
  children,
  containerCss,
  containerStyle,
  contentCss,
}) => {
  return (
    <>
      <footer className={containerCss} style={containerStyle}>
        {fatFooter}
        <div className={contentCss}>{children}</div>
      </footer>
    </>
  );
};

FooterBase.propTypes = {
  fatFooter: PropTypes.object,
  containerCss: PropTypes.string,
  containerStyle: PropTypes.object,
  contentCss: PropTypes.string,
};

FooterBase.defaultProps = {
  containerCss: "text-center shadow",
  contentCss: "footer-content",
};

export default FooterBase;
