import Http from "core/http/http";

function QuoteService() {
  const apiUrl = "/v1/quotations";

  const createQuotationAsync = (payload) =>
    Http().post(`${apiUrl}/request`, payload);

  return {
    createQuotationAsync,
  };
}

export default QuoteService;
