import React from "react";
import PropTypes from "prop-types";
import "./details-factory.scss";
import Detail1point4 from "./components/Detail1point4";
import Detail2point3 from "./components/Detail2point3";

function DetailsFactory({ type, containerCss }) {
  const getComponent = () => {
    switch (type) {
      case "AGBDC":
        return <Detail1point4 type={type} />;

      case "MBIT":
        return <Detail2point3 type={type} />;

      default:
        return <div>Type not supported.</div>;
    }
  };

  return (
    <section className={`details-factory-wrapper ${containerCss}`}>
      {getComponent()}
    </section>
  );
}

DetailsFactory.propTypes = {
  type: PropTypes.string,
  containerCss: PropTypes.string,
};

DetailsFactory.defaultProps = {};

export default DetailsFactory;
