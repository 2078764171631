import React from "react";
import PropTypes from "prop-types";
import { Table } from "react-bootstrap";
import UnitType from "./UnitType";

function UnitTypes({ data }) {
  return (
    <Table responsive="md">
      <thead>
        <tr>
          <th>#</th>
          <th>Unit</th>
          <th>Unit Area</th>
          <th>Description</th>
        </tr>
      </thead>
      <tbody>
        {data.map((o, i) => (
          <UnitType
            key={o.unitName}
            sequence={i + 1}
            unitName={o.unitName}
            unitArea={o.floorAreaText}
            description={o.description}
          />
        ))}
      </tbody>
    </Table>
  );
}

UnitTypes.propTypes = {
  data: PropTypes.array,
};

UnitTypes.defaultProps = { data: [] };

export default React.memo(UnitTypes);
