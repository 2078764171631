import React from "react";
import PropTypes from "prop-types";
import "./call-us.scss";
import fatFooterData from "data/fat-footer-data";

function CallUs() {
  return (
    <ul class="list-unstyled text-start call-us-wrapper">
      <>
        {fatFooterData.callUs.map((o, i) => (
          <li key={i}>
            <a href={`tel:${o}`}>{o}</a>
          </li>
        ))}
      </>
    </ul>
  );
}

CallUs.propTypes = {};

CallUs.defaultProps = {};

export default CallUs;
