import React from "react";
import PropTypes from "prop-types";
import { Card } from "react-bootstrap";
import "./signup.scss";
import ClientSignup from "components/containers/clientSignup/ClientSignup";
import { signupValidator } from "./validators/signupValidator";
import SignupService from "./services/signup-service";

function SignUpPage({ containerCss }) {
  const handleFormSubmit = (values) => {
    SignupService().registerLead({
      token: values.token,
      firstName: values.firstName,
      lastName: values.lastName,
    });
  };

  return (
    <section
      className={`signup-wrapper d-flex flex-column align-items-center ${containerCss}`}
    >
      <Card className="w-100 py-3 shadow-sm">
        <Card.Title className="text-center">
          <h4>Sign Up</h4>
        </Card.Title>
        <Card.Body>
          <ClientSignup
            validator={signupValidator}
            onFormSubmit={handleFormSubmit}
          />
        </Card.Body>
      </Card>
    </section>
  );
}

SignUpPage.propTypes = {
  containerCss: PropTypes.string,
};

SignUpPage.defaultProps = {};

export default SignUpPage;
