import { ReactComponent as ArrowRight } from "assets/images/feather/arrow-right.svg";

export function populateBrandsLookup(data) {
  return data.map((o) => ({ label: o.name, value: o.id }));
}

export function populateModelsLookup(data) {
  return data.reduce((acc, curr) => {
    const mappedModels = curr.models.map((model) => ({
      menu: model.name,
      to: `model/${model.id}`,
      matchTo: `/discover/brands/${curr.id}/model/${model.id}`,
      brandId: curr.id,
      icon: <ArrowRight height={18} />,
    }));

    return [...acc, ...mappedModels];
  }, []);
}

export function populateImagesLookup(imageData, videoData) {
  const images = imageData.map((o) => ({
    thumbnail: o.imageUrl,
    original: o.imageUrl,
  }));

  const videos = videoData.map((o) => ({
    thumbnail: o.imageThumbnailUrl,
    original: o.imageThumbnailUrl,
    embedUrl: o.videoUrl,
  }));

  return [...images, ...videos];
}
