/**
 * react-select + allOption
 *
 * Gives users of the fine component react-select the ability to select
 * all options with a single click.
 *
 * We're basically wrapping react-select but using a special option,
 * the "allOption". This option is exclusive of any other option
 * and it never hits the user's onChange() handler because it gets
 * replaced with the whole options list.
 *
 * There are many ways to implement this feature, but this one doesn't
 * affect the rest of your app, and it doesn't take any UI space.
 */
import React, { useMemo } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import PlainTextList from "../plainText/PlainTextList";
import Label from "../label/Label";
import { isArray } from "lodash";

// specify props.allowSelectAll = true to enable!
const SelectField = ({
  allowSelectAll,
  value,
  options,
  allOption,
  onChange,
  label,
  labelBold,
  showRequiredLabel,
  plaintext,
  plaintextInline,
  ...rest
}) => {
  let plainTextValues = useMemo(() => {
    if (value == null) return;
    return isArray(value) ? value.map((o) => o.label) : value.label;
  }, [value]);

  if (plaintext) {
    return (
      <PlainTextList
        label={label}
        data={plainTextValues}
        inline={plaintextInline}
        isBadge={true}
      />
    );
  }

  if (allowSelectAll && value != null) {
    if (value.length === options.length) {
      return (
        <>
          <Label
            label={label}
            labelBold={labelBold}
            showRequiredLabel={showRequiredLabel}
          />
          <Select
            {...rest}
            value={[allOption]}
            onChange={(selected) => onChange(selected.slice(1))}
          />
        </>
      );
    }

    return (
      <>
        <Label
          label={label}
          labelBold={labelBold}
          showRequiredLabel={showRequiredLabel}
        />
        <Select
          {...rest}
          options={[allOption, ...options]}
          onChange={(selected) => {
            if (selected == null) {
              selected = [];
            }

            if (
              selected.length > 0 &&
              selected[selected.length - 1].value === allOption.value
            ) {
              return onChange(options);
            }

            return onChange(selected);
          }}
        />
      </>
    );
  }

  return (
    <>
      <Label
        label={label}
        labelBold={labelBold}
        showRequiredLabel={showRequiredLabel}
      />
      <Select options={options} value={value} onChange={onChange} {...rest} />
    </>
  );
};

SelectField.propTypes = {
  options: PropTypes.array,
  value: PropTypes.any,
  onChange: PropTypes.func,
  allowSelectAll: PropTypes.bool,
  allOption: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  labelBold: PropTypes.bool,
  plaintext: PropTypes.bool,
  plaintextInline: PropTypes.bool,
  showRequiredLabel: PropTypes.bool,
};

SelectField.defaultProps = {
  allOption: {
    label: "Select all",
    value: "*",
  },
};

export default SelectField;
