import { lazy } from "react";
import { Navigate, Route } from "react-router-dom";
import BrandContentPage from "pages/brandContent/BrandContentPage";

const BrandsPage = lazy(() => import("pages/brand/BrandsPage"));

const discoverRoutes = () => {
  return (
    <>
      <Route path="brands/:bid" element={<BrandsPage />}>
        <Route path="model/:mid" element={<BrandContentPage />} />
      </Route>
      <Route path="*" element={<Navigate to="/pages/error-404" />} />
    </>
  );
};

export default discoverRoutes;
