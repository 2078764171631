import React from "react";
import PropTypes from "prop-types";
import { Card, Col, Row } from "react-bootstrap";
import { limitFileName } from "../utils/helper";
import { noop } from "lodash";
import RcBlockUi from "components/ui/rcBlockUi/RcBlockUi";
import { FileUploadStatus } from "../utils/constants";
import RcTooltip from "components/ui/rcTooltip/RcTooltip";
import { ReactComponent as CloudSvg } from "assets/images/feather/download-cloud.svg";

function ImageListing({
  files,
  isReadonly,
  onRemoveFile,
  onOpenPreview,
  onDownloadFile,
}) {
  return (
    <>
      {files.map((file) => {
        const limitedName = limitFileName(file.name);

        return (
          <Col key={file.id} className="my-2" xs="6" md="4" lg="3">
            <RcBlockUi blocking={file.isBusy}>
              <Card>
                <Card.Body>
                  {!isReadonly && (
                    <div className="remove-button">
                      <div className="d-flex flex-column align-items-center">
                        <i
                          id={`remove${file.id}`}
                          className="fas fa-times cursor-pointer text-muted file-remove mb-2"
                          onClick={() => onRemoveFile(file)}
                        />
                        <RcTooltip
                          message="Remove File"
                          selector="file-remove"
                        />
                      </div>
                    </div>
                  )}
                  <Row>
                    <Col
                      className="d-flex flex-row justify-content-center"
                      md="12"
                      xs="12"
                    >
                      <img
                        id={`image${file.id}`}
                        src={file.preview}
                        className="img cursor-pointer thumb-img p-2 file-upload-image"
                        alt={file.name}
                        onClick={() => onOpenPreview(file)}
                      />
                      <RcTooltip
                        message="Open Image Preview"
                        selector="file-upload-image"
                      />
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <Row>
                    <Col>
                      <span className="text-muted small">{limitedName}</span>
                      <div className="d-flex flex-row justify-content-between">
                        <div className="font-weight-bold small">
                          ({Math.round(file.size / 1024)} kb)
                        </div>
                        {file.type === "init" && (
                          <>
                            <CloudSvg
                              height={16}
                              className="file-download cursor-pointer"
                              onClick={() => onDownloadFile(file)}
                            />
                            <RcTooltip
                              message="Download File"
                              selector="file-download"
                            />
                          </>
                        )}
                      </div>
                    </Col>
                  </Row>
                  {file.hasError && (
                    <Row>
                      <div className="d-inline">
                        <i className="fas fa-exclamation-circle fg-error small me-2"></i>
                        <span className="fg-error small img-operation-error">
                          Error
                        </span>
                        <RcTooltip
                          message={file.errorMessage}
                          selector="img-operation-error"
                        />
                      </div>
                    </Row>
                  )}
                </Card.Footer>
              </Card>
            </RcBlockUi>
          </Col>
        );
      })}
    </>
  );
}

ImageListing.propTypes = {
  files: PropTypes.array,
  isReadonly: PropTypes.bool,
  onRemoveFile: PropTypes.func,
  onOpenPreview: PropTypes.func,
  onDownloadFile: PropTypes.func,
};

ImageListing.defaultProps = {
  onRemoveFile: noop,
  onOpenPreview: noop,
  onDownloadFile: noop,
};

export default ImageListing;
