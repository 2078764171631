import React, { useState } from "react";
import PropTypes from "prop-types";
import Playground from "components/features/demo/Playground";
import BasicModal from "components/ui/formFields/modals/basicModal/BasicModal";
import { RcButton } from "components/ui/rcButton";
import ConfirmModal from "components/ui/formFields/modals/confirmModal/ConfirmModal";

function PlaygroundPage({ containerCss }) {
  const [showModal, setShowModal] = useState(false);

  const handleHide = () => setShowModal(false);

  return (
    <section className={containerCss}>
      <h1>Playground</h1>
      <RcButton onClick={() => setShowModal(true)}>Show Modal</RcButton>
      {/* <BasicModal
        title="Sample Modal"
        isOpen={showModal}
        onHide={handleHide}
        onOk={handleHide}
      >
        <h2>Hello World!</h2>
      </BasicModal> */}
      <ConfirmModal
        //title="Sample Modal"
        isOpen={showModal}
        onHide={handleHide}
        onOk={handleHide}
      >
        Who is the strongest Avenger?
      </ConfirmModal>

      <Playground />
    </section>
  );
}

PlaygroundPage.propTypes = {
  containerCss: PropTypes.string,
};

PlaygroundPage.defaultProps = {};

export default PlaygroundPage;
