import React from "react";
import PropTypes from "prop-types";
import Buttons from "components/features/demo/Buttons";

function ButtonPage({ containerCss }) {
  return (
    <section className={containerCss}>
      <h1>Buttons</h1>
      <Buttons />
    </section>
  );
}

ButtonPage.propTypes = {
  containerCss: PropTypes.string,
};

ButtonPage.defaultProps = {};

export default ButtonPage;
