import React from "react";
import PropTypes from "prop-types";
import { Image } from "react-bootstrap";

function SidebarAvatar({ imageSrc, avatarName, collapsed, containerCss }) {
  if (collapsed) return null;

  return (
    <section
      className={`d-flex flex-column justify-content-center align-items-center mb-3  ${containerCss}`}
    >
      <Image src={imageSrc} className="avatar" roundedCircle />
      <div className="mt-2">
        <h6 className="fg-white">{avatarName}</h6>
      </div>
    </section>
  );
}

SidebarAvatar.propTypes = {
  collapsed: PropTypes.bool,
  imageSrc: PropTypes.string,
  avatarName: PropTypes.string,
  containerCss: PropTypes.string,
};

SidebarAvatar.defaultProps = {};

export default React.memo(SidebarAvatar);
