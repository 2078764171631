import React, { useCallback, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Stack from "react-bootstrap/Stack";
import { ReactComponent as HeartSvg } from "assets/images/feather/heart.svg";
import CardView from "components/ui/card/CardView";
import { RcButton } from "components/ui/rcButton";
import RcImageGallery from "components/ui/rcImageGallery/RcImageGallery";
import useBrandContent from "./hooks/useBrandContent";
import { BlockUi } from "components/ui/rcBlockUi";
import BrandInformation from "components/features/brand/brandInformation/BrandInformation";
import "./brand-content.scss";
import UnitTypes from "components/features/brand/unitTypes/UnitTypes";
import FeaturesAndAmenities from "components/features/brand/featuresAndAmenities/FeaturesAndAmenities";
import LocationInfo from "components/features/brand/location/LocationInfo";
import ImagePreview from "components/ui/imagePreview/ImagePreview";
import useImagePreview from "hooks/useImagePreview";
import SampleComputation from "components/features/brand/sampleComputation/SampleComputation";
import MarkDown from "components/ui/markdown/MarkDown";
import useMediaQueryExt from "hooks/useMediaQueryExt";
import { useLocation, useNavigate } from "react-router-dom";
import { CLIENT_EMAIL2, PRIMARY_PHONE2 } from "constants/appConstants";
import {
  FacebookIcon,
  FacebookMessengerShareButton,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  XIcon,
} from "react-share";
import FbShareButton from "components/ui/socialShare/fbShareButton/FBShareButton";
import XShareButton from "components/ui/socialShare/xShareButton/XShareButton";
import SeoTags from "components/features/seoTags/SeoTags";

function BrandContentPage({ containerCss }) {
  const navigate = useNavigate();
  const { currentData, loading } = useBrandContent();
  const { brand, model } = currentData;
  const { showImagePreview, imagePreviewUrl, previewImage } = useImagePreview();
  const { isMobile } = useMediaQueryExt();
  const imageGalleryRef = useRef();
  const location = useLocation();

  const handleClosePreview = useCallback(() => {
    previewImage(null, false);
  }, [previewImage]);

  const handlePreview = useCallback(
    (imageUrl) => {
      previewImage(imageUrl, true);
    },
    [previewImage]
  );

  useEffect(() => {
    imageGalleryRef.current?.stopVideo();
  }, [location.pathname]);

  if (currentData == null || Object.keys(currentData).length <= 0)
    return (
      <div className="brand-content-wrapper">
        <section className="brand-content-empty"></section>
      </div>
    );

  const shareUrl = typeof window !== "undefined" ? window.location.href : "";

  return (
    <BlockUi blocking={false}>
      <SeoTags title={brand?.title} />
      <ImagePreview
        isOpen={showImagePreview}
        imageLargeUrl={imagePreviewUrl}
        closePreview={handleClosePreview}
      />
      <section className={`brand-content-wrapper ${containerCss}`}>
        <div className="d-flex flex-row justify-content-between">
          <h1 className="fg-primary">{brand?.title}</h1>
          <div className="">
            <FbShareButton
              url={shareUrl}
              // url={
              //   "https://vistaestatesouth.com/discover/brands/9e6fde81-c9ff-4f71-5f08-08dc29e12e72/model/d7dd52cd-d95b-4077-f399-08dc29e13946"
              // }
              hashTag="#condophilippines"
              containerCss="mx-1 mb-1"
            />
            <XShareButton
              url={shareUrl}
              title={`Vista Estate South - ${brand?.title}`}
              // url="https://vistaestatesouth.com/discover/brands/9e6fde81-c9ff-4f71-5f08-08dc29e12e72/model/d7dd52cd-d95b-4077-f399-08dc29e13946"
              hashTags={[
                "#condophilippines",
                "#condoliving",
                "#condominium",
                "#house",
                "#ofwdubai",
                "#ofwcanada",
                "#ofwsg",
                "#investments",
                // "#highrisecondominium",
                // "#investments",
                // "#propertyforsale",
                // "#propertysearch",
                // "#propertyinvestment",
                // "#property",
                // "#realestate",
                // "#condo",
                // "#condos",
                // "#home",
                // "#brokerlife",
                // "#ofw",
                // "#ofwdubai",
                // "#ofwcanada",
                // "#ofwsingapore",
                // "#ofwhongkong",
                // "#ofwusa",
                // "#ofwtaiwan",
                // "#ofweurope",
                // "#ofwaustralia",
                // "#ofwjapan",
                // "#investmentphilippines",
                // "#investmentopportunity",
                // "#vistaestates",
                // "#realestateexpert",
              ]}
              containerCss="mx-1 mb-1"
            />
            {/* <HeartSvg height={22} /> */}
          </div>
        </div>
        <BrandInformation
          location={brand?.locationText}
          price={model?.unitPriceText}
          brandTypeText={brand?.brandTypeText}
          brandStatus={brand?.status}
        />
        <CardView className="my-3">
          <RcImageGallery
            ref={imageGalleryRef}
            data={model?.modelImages}
            slideOnThumbnailOver={false}
            thumbnailPosition={isMobile ? "bottom" : "right"}
            showNav
          />
        </CardView>
        <CardView title="Overview" className="my-3">
          <MarkDown text={brand?.overview} />
        </CardView>
        <CardView title="Unit Types" className="my-3">
          <UnitTypes data={model?.unitTypes} />
        </CardView>
        <CardView title="Features and Amenities" className="my-3">
          <FeaturesAndAmenities
            data={model?.features}
            containerCss="features-container"
            onPreview={handlePreview}
          />
        </CardView>
        <CardView title="Location" className="my-3">
          <LocationInfo
            locationMarkdown={brand?.locationMarkdownContent}
            locationImage={brand?.mapImageUrl}
            containerCss="location-container"
            onPreview={handlePreview}
          />
        </CardView>
        <CardView title="Sample Computation" className="my-3">
          <SampleComputation
            data={model?.computationGroups}
            imageContainerCss="computation-container"
            onPreview={handlePreview}
          />
        </CardView>
        <CardView title="For Inquiries" className="my-3">
          <strong>
            <span className="me-2">FEEL FREE TO REACH US AT</span>
            <a href={`emailto:${CLIENT_EMAIL2}`}>{CLIENT_EMAIL2}</a> |{" "}
            <a href={`tel:${PRIMARY_PHONE2}`}>{PRIMARY_PHONE2}</a>
          </strong>
        </CardView>
        {/* <Stack direction="horizontal" gap={2} className="my-3">
          <RcButton
            variant="pill-primary"
            onClick={() => navigate("/auth/client")}
          >
            Inquire Now
          </RcButton>
          <RcButton
            variant="pill-secondary"
            onClick={() => alert("Sorry, feature still in-progress.")}
          >
            Add to Inquiries
          </RcButton>
        </Stack> */}
      </section>
    </BlockUi>
  );
}

BrandContentPage.propTypes = {
  containerCss: PropTypes.string,
};

BrandContentPage.defaultProps = {};

export default BrandContentPage;
