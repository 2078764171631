/* eslint-disable react-hooks/exhaustive-deps */
import { deepClone } from "core/utils/objectUtils";
import * as BrandsSlice from "pages/brand/brands-slice";
import { populateImagesLookup } from "pages/brand/helpers/mapping";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

function useBrandContent() {
  //const dispatch = useDispatch();
  const { brandsData, loading } = useSelector(BrandsSlice.selectBrands);
  const { bid, mid } = useParams();
  const [currentData, setCurrentData] = useState({});

  useEffect(() => {
    if (brandsData == null) return;

    const searchedBrand = brandsData.find((f) => f.id === bid);
    let searchedModel = searchedBrand.models.find((f) => f.id === mid);

    let modelImages = populateImagesLookup(
      searchedModel.images,
      searchedModel.videos
    );

    setCurrentData({
      brand: {
        ...searchedBrand,
        title: `${searchedBrand.name.toUpperCase()} - ${searchedModel.name.toUpperCase()}`,
      },
      model: { ...searchedModel, modelImages: [...modelImages] },
    });
  }, [mid, brandsData]);

  return { currentData, loading };
}

export default useBrandContent;
