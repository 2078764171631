import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";

const PageNumberMobileLayout1 = ({ pageIndex, pageOptions, gotoPage }) => {
  return (
    <div className="d-flex flex-row justify-content-center align-items-center mx-2 bg-redx">
      <Form.Control
        type="number"
        defaultValue={pageIndex + 1}
        value={pageIndex + 1}
        className="me-1 w50"
        onChange={(e) => {
          const page = e.target.value ? Number(e.target.value) - 1 : 0;
          gotoPage(page);
        }}
      />
      <div className="d-flex flex-row justify-content-center" style={{ minWidth: "50px" }}>
        <span>of {pageOptions.length}</span>
      </div>
    </div>
  );
};

PageNumberMobileLayout1.propTypes = {
  pageCount: PropTypes.number,
  pageIndex: PropTypes.number,
  pageOptions: PropTypes.array,
  pageSize: PropTypes.number,
  setPageSize: PropTypes.func,
  gotoPage: PropTypes.func,
  previousPage: PropTypes.func,
  nextPage: PropTypes.func,
  paginationOptions: PropTypes.shape({
    sizeSelection: PropTypes.array,
    selectionLabel: PropTypes.string,
    defaultPageSize: PropTypes.number,
  }),
};

PageNumberMobileLayout1.defaultProps = {};

export default PageNumberMobileLayout1;
