import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Row } from "react-bootstrap";
import FormInput from "../formInput/FormInput";
import FormSelectField from "../formSelect/FormSelectField";
import useFastField from "../hooks/useFastField";
import { handleDropdownValidation, handleTextValidation } from "./validator";
import FormWatch from "../formWatch/FormWatch";

function FormAddressField({
  data,
  propertyNamePrefix,
  addressLine1Label,
  addressLine2Label,
  provinceLabel,
  cityLabel,
  zipcodeLabel,
  containerCss,
}) {
  const [cityMunicipalities, setCityMunicipalities] = useState();
  const provinceField = useFastField({ name: "provinceState" });
  const cityMunicipalityField = useFastField({ name: "cityMunicipality" });

  useEffect(() => {
    if (provinceField.field.value == null) return;

    setCityMunicipalities(provinceField.field.value.municipalities);
  }, [provinceField.field.value]);

  const handleChangeOverride = () => {
    cityMunicipalityField.helpers.setValue(null);
  };

  return (
    <section className={containerCss}>
      <Row>
        <FormInput
          name={`${propertyNamePrefix ? "-addressLine1" : "addressLine1"}`}
          label={addressLine1Label}
          xs={12}
          md={6}
          placeholder="Street / Building no."
          showRequiredLabel
          validate={handleTextValidation("Address Line 1 is required")}
        />
        <FormInput
          name={`${propertyNamePrefix ? "-addressLine2" : "addressLine2"}`}
          label={addressLine2Label}
          placeholder="Village / Subdivision"
          xs={12}
          md={6}
          //validate={handleTextValidation("Address Line 2 is required")}
        />
        <FormSelectField
          name={`${propertyNamePrefix ? "-provinceState" : "provinceState"}`}
          label={provinceLabel}
          options={data}
          showRequiredLabel
          closeMenuOnSelect
          xs={12}
          md={6}
          onChangeOverride={handleChangeOverride}
          validate={handleDropdownValidation("Province / State is required")}
        />
        <FormSelectField
          name={`${
            propertyNamePrefix ? "-cityMunicipality" : "cityMunicipality"
          }`}
          label={cityLabel}
          options={cityMunicipalities}
          showRequiredLabel
          closeMenuOnSelect
          xs={12}
          md={6}
          validate={handleDropdownValidation("City / Municipality is required")}
        />
        <FormInput
          name={`${propertyNamePrefix ? "-zipcode" : "zipcode"}`}
          label={zipcodeLabel}
          xs={12}
          //validate={handleTextValidation("Zip Code is required")}
        />
      </Row>
    </section>
  );
}

FormAddressField.propTypes = {
  data: PropTypes.array,
  propertyNamePrefix: PropTypes.string,
  addressLine1Label: PropTypes.string,
  addressLine2Label: PropTypes.string,
  provinceLabel: PropTypes.string,
  cityLabel: PropTypes.string,
  zipcodeLabel: PropTypes.string,
  containerCss: PropTypes.string,
};

FormAddressField.defaultProps = {
  addressLine1Label: "Address Line 1",
  addressLine2Label: "Address Line 2",
  provinceLabel: "Province / State",
  cityLabel: "City / Municipality",
  zipcodeLabel: "Zip Code",
};

export default FormAddressField;
