import React from "react";
import PropTypes from "prop-types";
import { noop } from "lodash";
import { Button, Spinner } from "react-bootstrap";

const RcButton = ({
  name,
  children,
  buttonCss,
  containerCss,
  variant,
  targetName,
  loading,
  disabled,
  size,
  spinnerAnimation,
  onClick,
  ...rest
}) => {
  let loadSpinner = (targetName === name || targetName === "") && loading;
  let disableBtn = disabled;
  let inProgressDisable = false;

  if (!loadSpinner && targetName !== "" && loading) {
    disableBtn = true;
    loadSpinner = false;
  } else if (loadSpinner) {
    inProgressDisable = true;
  }

  // 2 types of state disabled ("disabled by component prop" or "disabled by active in-Progress activity")
  let btnCss = !disableBtn
    ? buttonCss
      ? buttonCss
      : `rca-btn-${variant} ${inProgressDisable ? "in-progress-disabled" : ""}`
    : `rca-btn-${variant} btn-disabled`; // rca-btn-disabled`;

  //console.log(`rca-btn-${variant} ${name} ${btnCss}`);

  const spinnerVariant = variant.includes("secondary") ? "light" : "secondary";

  return (
    <Button
      {...rest}
      variant={variant}
      className={`${btnCss} ${containerCss}`}
      size={size}
      onClick={disableBtn ? noop : onClick}
      disabled={loading || disabled || disableBtn}
    >
      {loadSpinner ? (
        <>
          <Spinner
            animation={spinnerAnimation}
            size="sm"
            variant={spinnerVariant}
            role="status"
          >
            <span className="visually-hidden">In-Progress...</span>
          </Spinner>
          <div className="d-inline-flex ms-2">{children}</div>
        </>
      ) : (
        <span>{children}</span>
      )}
    </Button>
  );
};

RcButton.propTypes = {
  name: PropTypes.string,
  buttonCss: PropTypes.string,
  variant: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  targetName: PropTypes.string,
  size: PropTypes.string,
  spinnerAnimation: PropTypes.string,
  onClick: PropTypes.func,
};

RcButton.defaultProps = {
  variant: "success",
  name: "rcButton",
  targetName: "",
  disabled: false,
  loading: false,
  size: "md",
  spinnerAnimation: "grow",
  onClick: noop,
};

export default RcButton;
