import { isArray } from "lodash";

// Convert to claims format
export function sanitizeAuthorizationData(data) {
  let authData = data.reduce((acc, current) => {
    if (acc[current.type] == null) {
      acc[current.type] = current.value;
      return acc;
    }

    if (acc[current.type] !== null) {
      if (isArray(acc[current.type])) {
        acc[current.type] = [...acc[current.type], current.value];
      } else {
        acc[current.type] = [acc[current.type], current.value];
      }

      return acc;
    }
  }, []);

  return authData;
}
