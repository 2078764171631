import React, { useState } from "react";
import PropTypes from "prop-types";
import { RcButton } from "components/ui/rcButton";
import { Button, Card, Col, Row, Stack } from "react-bootstrap";

const Buttons = ({ containerCss }) => {
  const [targetButton, setTargetButton] = useState("");
  const [loading, setLoading] = useState(false);

  return (
    <section className={containerCss}>
      <Card className="mb-2">
        <Card.Body>
          <Card.Title>
            <h3>React-Bootstrap Buttons</h3>
            <div className="tsmall1 text-muted">
              Usage: primary, secondary etc.
            </div>
          </Card.Title>
          <Stack direction="horizontal" gap={2} className="mt-4">
            <Button variant="primary">Primary</Button>
            <Button variant="secondary">Secondary</Button>
            <Button variant="success">Success</Button>
            <Button variant="warning">Warning</Button>
            <Button variant="danger">Danger</Button>
            <Button variant="info">Info</Button>
            <Button variant="light">Light</Button>
            <Button variant="dark">Dark</Button>
            <Button variant="link">Link</Button>
          </Stack>
        </Card.Body>
      </Card>
      <Card className="mb-2">
        <Card.Body>
          <Card.Title>
            <h3>RCA Buttons</h3>
            <div className="tsmall1 text-muted">
              Usage: primary, secondary, tertiary etc...
            </div>
          </Card.Title>
          <Stack direction="horizontal" gap={2} className="mt-4 py-2">
            <RcButton variant="primary" onClick={() => {}}>
              Primary
            </RcButton>
            <RcButton variant="secondary" onClick={() => {}}>
              Secondary
            </RcButton>
            <RcButton variant="tertiary" onClick={() => {}}>
              Tertiary
            </RcButton>
          </Stack>

          <Stack direction="horizontal" gap={2}>
            <RcButton variant="success" onClick={() => {}}>
              Success
            </RcButton>
            <RcButton variant="warn" onClick={() => {}}>
              Warning
            </RcButton>
            <RcButton variant="danger" onClick={() => {}}>
              Danger
            </RcButton>
            <RcButton variant="info" onClick={() => {}}>
              Info
            </RcButton>
            <RcButton variant="light" onClick={() => {}}>
              Light
            </RcButton>
            <RcButton variant="dark" onClick={() => {}}>
              Dark
            </RcButton>
          </Stack>
        </Card.Body>
      </Card>

      <Card className="mb-2">
        <Card.Body>
          <Card.Title>
            <h3>Outline Buttons</h3>
            <div className="tsmall1 text-muted">
              Usage: outline-primary, outline-secondary, outline-tertiary etc...
            </div>
          </Card.Title>
          <Stack direction="horizontal" gap={2} className="pt-4">
            <RcButton variant="outline-primary" onClick={() => {}}>
              Primary
            </RcButton>
            <RcButton variant="outline-secondary" onClick={() => {}}>
              Secondary
            </RcButton>
            <RcButton variant="outline-tertiary" onClick={() => {}}>
              Tertiary
            </RcButton>
            <RcButton variant="outline-success" onClick={() => {}}>
              Success
            </RcButton>
            <RcButton variant="outline-warn" onClick={() => {}}>
              Warning
            </RcButton>
            <RcButton variant="outline-danger" onClick={() => {}}>
              Danger
            </RcButton>
            <RcButton variant="outline-info" onClick={() => {}}>
              Info
            </RcButton>
            <RcButton variant="outline-light" onClick={() => {}}>
              Light
            </RcButton>
            <RcButton variant="outline-dark" onClick={() => {}}>
              Dark
            </RcButton>
          </Stack>
        </Card.Body>
      </Card>

      <Card className="mb-2">
        <Card.Body>
          <Card.Title>
            <h3>Pill Buttons</h3>
            <div className="tsmall1 text-muted">
              Usage: pill-primary, pill-secondary, pill-tertiary etc...
            </div>
          </Card.Title>
          <Stack direction="horizontal" gap={2} className="pt-4">
            <RcButton variant="pill-primary" onClick={() => {}}>
              Primary
            </RcButton>
            <RcButton variant="pill-secondary" onClick={() => {}}>
              Secondary
            </RcButton>
            <RcButton variant="pill-tertiary" onClick={() => {}}>
              Tertiary
            </RcButton>
            <RcButton variant="pill-success" onClick={() => {}}>
              Success
            </RcButton>
            <RcButton variant="pill-warn" onClick={() => {}}>
              Warning
            </RcButton>
            <RcButton variant="pill-danger" onClick={() => {}}>
              Danger
            </RcButton>
            <RcButton variant="pill-info" onClick={() => {}}>
              Info
            </RcButton>
            <RcButton variant="pill-light" onClick={() => {}}>
              Light
            </RcButton>
            <RcButton variant="pill-dark" onClick={() => {}}>
              Dark
            </RcButton>
          </Stack>
        </Card.Body>
      </Card>

      <Card className="mb-2">
        <Card.Body>
          <Card.Title>
            <h3>Outline Pill Buttons</h3>
            <div className="tsmall1 text-muted">
              Usage: outline-pill-primary, outline-pill-secondary,
              outline-pill-tertiary etc...
            </div>
          </Card.Title>
          <Stack direction="horizontal" gap={2} className="pt-4">
            <RcButton variant="outline-pill-primary" onClick={() => {}}>
              Primary
            </RcButton>
            <RcButton variant="outline-pill-secondary" onClick={() => {}}>
              Secondary
            </RcButton>
            <RcButton variant="outline-pill-tertiary" onClick={() => {}}>
              Tertiary
            </RcButton>
            <RcButton variant="outline-pill-success" onClick={() => {}}>
              Success
            </RcButton>
            <RcButton variant="outline-pill-warn" onClick={() => {}}>
              Warning
            </RcButton>
            <RcButton variant="outline-pill-danger" onClick={() => {}}>
              Danger
            </RcButton>
            <RcButton variant="outline-pill-info" onClick={() => {}}>
              Info
            </RcButton>
            <RcButton variant="outline-pill-light" onClick={() => {}}>
              Light
            </RcButton>
            <RcButton variant="outline-pill-dark" onClick={() => {}}>
              Dark
            </RcButton>
          </Stack>
        </Card.Body>
      </Card>

      <Card className="mb-2 bg-gray-800">
        <Card.Body>
          <Card.Title>
            <h3 className="fg-white">Outline Light Pill Buttons</h3>
            <div className="tsmall1 fg-gray-200">
              Usage: outline-light-pill-primary, outline-light-pill-secondary,
              outline-light-pill-tertiary etc...
            </div>
          </Card.Title>
          <Stack direction="horizontal" gap={2} className="pt-4">
            <RcButton variant="outline-light-pill-primary" onClick={() => {}}>
              Primary
            </RcButton>
            <RcButton variant="outline-light-pill-secondary" onClick={() => {}}>
              Secondary
            </RcButton>
            <RcButton variant="outline-light-pill-tertiary" onClick={() => {}}>
              Tertiary
            </RcButton>
            <RcButton variant="outline-light-pill-success" onClick={() => {}}>
              Success
            </RcButton>
            <RcButton variant="outline-light-pill-warn" onClick={() => {}}>
              Warning
            </RcButton>
            <RcButton variant="outline-light-pill-danger" onClick={() => {}}>
              Danger
            </RcButton>
            <RcButton variant="outline-light-pill-info" onClick={() => {}}>
              Info
            </RcButton>
            <RcButton variant="outline-light-pill-light" onClick={() => {}}>
              Light
            </RcButton>
            <RcButton variant="outline-light-pill-dark" onClick={() => {}}>
              Dark
            </RcButton>
          </Stack>
        </Card.Body>
      </Card>

      <Card className="mb-2">
        <Card.Body>
          <Card.Title>
            <h3>Outline Dark Pill Buttons</h3>
            <div className="tsmall1 text-muted">
              Usage: outline-dark-pill-primary, outline-dark-pill-secondary,
              outline-dark-pill-tertiary etc...
            </div>
          </Card.Title>
          <Stack direction="horizontal" gap={2} className="pt-4">
            <RcButton variant="outline-dark-pill-primary" onClick={() => {}}>
              Primary
            </RcButton>
            <RcButton variant="outline-dark-pill-secondary" onClick={() => {}}>
              Secondary
            </RcButton>
            <RcButton variant="outline-dark-pill-tertiary" onClick={() => {}}>
              Tertiary
            </RcButton>
            <RcButton variant="outline-dark-pill-success" onClick={() => {}}>
              Success
            </RcButton>
            <RcButton variant="outline-dark-pill-warn" onClick={() => {}}>
              Warning
            </RcButton>
            <RcButton variant="outline-dark-pill-danger" onClick={() => {}}>
              Danger
            </RcButton>
            <RcButton variant="outline-dark-pill-info" onClick={() => {}}>
              Info
            </RcButton>
            <RcButton variant="outline-dark-pill-light" onClick={() => {}}>
              Light
            </RcButton>
            <RcButton variant="outline-dark-pill-dark" onClick={() => {}}>
              Dark
            </RcButton>
          </Stack>
        </Card.Body>
      </Card>

      <Card className="mb-2">
        <Card.Body>
          <Card.Title>
            <h3>Sizes</h3>
          </Card.Title>
          <Stack direction="horizontal" gap={2}>
            <RcButton
              variant="secondary"
              name="update"
              size="sm"
              onClick={() => {}}
            >
              Small (sm)
            </RcButton>
            <RcButton variant="secondary" name="update" onClick={() => {}}>
              Normal (default)
            </RcButton>
            <RcButton
              variant="secondary"
              name="update"
              size="lg"
              onClick={() => {}}
            >
              Large (lg)
            </RcButton>
          </Stack>
        </Card.Body>
      </Card>

      <Card className="mb-2">
        <Card.Body>
          <Card.Title>
            <h3>Block</h3>
          </Card.Title>
          <Stack direction="vertical" gap={2}>
            <RcButton
              variant="primary"
              name="update"
              size="lg"
              onClick={() => {}}
            >
              Small (sm)
            </RcButton>
            <RcButton
              variant="primary"
              name="update"
              size="lg"
              onClick={() => {}}
            >
              Large (lg)
            </RcButton>
          </Stack>
        </Card.Body>
      </Card>

      <Card className="mb-2">
        <Card.Body>
          <Card.Title>
            <h3>Button Size Based On Content (Column View)</h3>
          </Card.Title>
          <RcButton
            variant="success"
            name="update"
            size="sm"
            containerCss="mb-2"
            onClick={() => {}}
          >
            View Products
          </RcButton>
          <br />
          <RcButton
            variant="success"
            name="update"
            size="sm"
            containerCss="mb-2"
            onClick={() => {}}
          >
            Lodge an Incident
          </RcButton>

          <div>
            <Row className="mb-2">
              <Col>
                <RcButton
                  variant="success"
                  name="update"
                  size="sm"
                  onClick={() => {}}
                >
                  Manage Orders
                </RcButton>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col>
                <RcButton
                  variant="success"
                  name="update"
                  size="sm"
                  onClick={() => {}}
                >
                  Promo Codes
                </RcButton>
              </Col>
            </Row>
            <Row>
              <Col>
                <RcButton
                  variant="success"
                  name="update"
                  size="sm"
                  onClick={() => {}}
                >
                  Manage Sales and Inventory
                </RcButton>
              </Col>
            </Row>
          </div>
        </Card.Body>
      </Card>

      <Card className="mb-2">
        <Card.Body>
          <Card.Title>
            <h3>Button State</h3>
          </Card.Title>
          <div>
            <RcButton
              variant="primary"
              disabled={true}
              loading={false}
              containerCss="mb-2"
              onClick={() => {}}
            >
              Disabled button
            </RcButton>
          </div>
          <div className="mb-2">
            <RcButton
              variant="primary"
              name="submit"
              targetName="submit"
              loading={true}
              onClick={() => {}}
            >
              Saving...
            </RcButton>
          </div>
          <Stack direction="horizontal" gap={2}>
            {/* Set of buttons */}
            <RcButton
              variant="primary"
              name="update"
              targetName={targetButton}
              loading={loading}
              onClick={() => {
                setTargetButton("update");
                setLoading(true);
                setTimeout(() => {
                  setLoading(false);
                }, 3000);
              }}
            >
              Update
            </RcButton>
            <RcButton
              variant="secondary"
              name="delete"
              targetName={targetButton}
              loading={loading}
              onClick={() => {
                setTargetButton("delete");
                setLoading(true);
                setTimeout(() => {
                  setLoading(false);
                }, 3000);
              }}
            >
              Delete
            </RcButton>
            <RcButton
              variant="secondary"
              name="create"
              targetName={targetButton}
              loading={loading}
              onClick={() => {
                setTargetButton("create");
                setLoading(true);
                setTimeout(() => {
                  setLoading(false);
                }, 3000);
              }}
            >
              Create
            </RcButton>
          </Stack>
        </Card.Body>
      </Card>
    </section>
  );
};

Buttons.propTypes = {
  containerCss: PropTypes.string,
};

Buttons.defaultProps = {};

export default Buttons;
