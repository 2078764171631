import React from "react";
import PropTypes from "prop-types";

function UnitType({ unitName, unitArea, description, sequence }) {
  return (
    <tr>
      <td>{sequence}</td>
      <td>{unitName}</td>
      <td>{unitArea}</td>
      <td>{description}</td>
    </tr>
  );
}

UnitType.propTypes = {
  sequence: PropTypes.number,
  unitName: PropTypes.string,
  unitArea: PropTypes.string,
  description: PropTypes.string,
  containerCss: PropTypes.string,
};

UnitType.defaultProps = {};

export default React.memo(UnitType);
