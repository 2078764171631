import React from "react";
import PropTypes from "prop-types";
import { ContentPane } from "components/ui/contentPane";
import { Col, Image, Row } from "react-bootstrap";
import { motion } from "framer-motion";
import SectionTitle from "components/ui/pageSection/SectionTitle";
import "./our-team.scss";
import MemberName from "./memberName/MemberName";

function OurTeam({ containerCss }) {
  return (
    <ContentPane
      isFullWidth={true}
      hasShadow={false}
      containerCss="our-team-wrapper"
      contentCss="content-box"
      contentPaddingHorizontal="3"
      contentPaddingVertical="3"
      mainContentCss="p-5"
    >
      <SectionTitle
        title="Our Team"
        subTitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore"
      />
      <Row className="section-content">
        <Col className="d-flex flex-column flex-md-row justify-content-center align-items-center">
          <motion.div
            initial={{ opacity: 0, y: "10vh" }}
            transition={{ ease: "easeInOut", duration: 0.8 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="motion-div-container"
          >
            <Image
              src="images/our-team/pic1.jpg"
              className="round-framex"
              roundedCircle
            />
            <MemberName name="Wanda Maximoff" title="CEO" />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: "10vh" }}
            transition={{ ease: "easeInOut", duration: 0.9 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="motion-div-container"
          >
            <Image src="images/our-team/pic2.jpg" roundedCircle />
            <MemberName name="John Wick" title="Manager" />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: "10vh" }}
            transition={{ ease: "easeInOut", duration: 1 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="motion-div-container"
          >
            <Image src="images/our-team/pic3.jpg" roundedCircle />
            <MemberName name="Elon Musk" title="Sales Manager" />
          </motion.div>
        </Col>
      </Row>
    </ContentPane>
  );
}

OurTeam.propTypes = {
  containerCss: PropTypes.string,
};

OurTeam.defaultProps = {};

export default OurTeam;
