import React from "react";
import PropTypes from "prop-types";
import { Image } from "react-bootstrap";
import { motion, AnimatePresence } from "framer-motion";
import "./picture.scss";

function Picture({ src, rounded, imageCss, imageCreditName, imageCreditUrl }) {
  return (
    <motion.div
      initial={{ opacity: 0, y: "10vh" }}
      transition={{ ease: "easeOut", duration: 1 }}
      animate={{ opacity: 1, y: 0 }}
      className="picture-wrapper"
    >
      <Image src={src} rounded={rounded} className={imageCss} fluid />
      <div className="image-credits">
        <a href={imageCreditUrl}>{imageCreditName}</a>
      </div>
    </motion.div>
  );
}

Picture.propTypes = {
  src: PropTypes.string,
  rounded: PropTypes.bool,
  imageCss: PropTypes.string,
  imageCreditName: PropTypes.string,
  imageCreditUrl: PropTypes.string,
};

Picture.defaultProps = {};

export default Picture;
