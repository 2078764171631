/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import "rc-segmented/assets/index.css";
import SeoTags from "components/features/seoTags/SeoTags";
import { ContentPane } from "components/ui/contentPane";
import { NavHeaderContext } from "components/ui/navigationHeader/context/NavHeaderContextProvider";
import { NavHeaderTypes } from "constants/navHeaderTypes";
import SectionTitle from "components/ui/pageSection/SectionTitle";
//import * as CareersSlice from "./careers-slice";
import "./signin-or-signup.scss";
import Picture from "components/ui/picture/Picture";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import { RcButton } from "components/ui/rcButton";
import { useNavigate, Outlet } from "react-router-dom";
import RcSegmented from "components/ui/rcSegmented/RcSegmented";

function SigninOrSignupPage({ containerCss }) {
  const context = useContext(NavHeaderContext);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    context.changeMode(NavHeaderTypes.FIXED_MODE);

    return () => {
      context.changeMode(NavHeaderTypes.DUAL_MODE);
    };
  }, []);

  const handleSegmentClick = (e) => {
    if (e === "Existing Client Sign In") navigate("signin");
    if (e === "New Client Sign Up") navigate("signup");
  };

  return (
    <>
      <SeoTags title="Client SignIn/SignUp" />
      <ContentPane
        id="signin-signup"
        isFullWidth={true}
        hasShadow={false}
        containerCss="signin-signup-wrapper "
        contentCss="content-box"
        contentPaddingHorizontal="0"
        contentPaddingVertical="0"
        mainContentCss="about-content-wrapper"
      >
        <Row className="account-content gx-0">
          <Col
            className="d-flex flex-column justify-content-center align-items-center p-5"
            xs={12}
            md={12}
            lg={6}
          >
            <div className="signupin-container">
              <h1 className="rca-heading4">Client Sign In / Sign Up</h1>
              <h5 className="mb-2">
                You should have an account to proceed
                <br /> with your Inquiry
              </h5>
              <RcSegmented
                data={["Existing Client Sign In", "New Client Sign Up"]}
                onSegmentedClick={handleSegmentClick}
              />
            </div>
            <div className="outlet-container">
              <Outlet />
            </div>
          </Col>
          <Col className="d-flex flex-column justify-content-center">
            <Picture
              src="/images/signin/login.webp"
              imageCss="right-pane-image"
            />
          </Col>
        </Row>
      </ContentPane>
    </>
  );
}

//  <RcButton
//   variant="tertiary"
//   size="lg"
//   containerCss="cta-button me-3"
//   onClick={handleSignUp}
// >
//   New Client Sign Up
// </RcButton>
// <RcButton
//   variant="secondary"
//   size="lg"
//   containerCss="cta-button me-3"
//   onClick={handleSignIn}
// >
//   Existing Client Sign In
// </RcButton>

SigninOrSignupPage.propTypes = {
  containerCss: PropTypes.string,
};

SigninOrSignupPage.defaultProps = {};

export default SigninOrSignupPage;
