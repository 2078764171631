import { useMediaQuery } from "react-responsive";
import PropTypes from "prop-types";

const DefaultMediaQuery = ({ children, query }) => {
  const isNotMobile = useMediaQuery(query);
  return isNotMobile ? children : null;
};

DefaultMediaQuery.propTypes = {
  query: PropTypes.object,
};

DefaultMediaQuery.defaultProps = { query: { minWidth: 768 } };

export default DefaultMediaQuery;
