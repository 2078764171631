import { useMediaQuery } from "react-responsive";
import PropTypes from "prop-types";

const Tablet = ({ children, query }) => {
  const isTablet = useMediaQuery(query);
  return isTablet ? children : null;
};

Tablet.propTypes = {
  query: PropTypes.object,
};

Tablet.defaultProps = { query: { minWidth: 768, maxWidth: 991 } };

export default Tablet;