import IndeterminateCheckbox from "./checkbox/IndeterminateCheckbox";
import React from "react";

/**
 * Helpers for Datatable
 */
const headerProps = (props, { column }) => _getStyles(props, column.align);

const cellProps = (props, { cell }) => _getStyles(props, cell.column.align);

const customHooks = (showSelectionCheckbox) => {
  return (hooks) => {
    // Extensibility point in the future!
    if (!showSelectionCheckbox) return;

    // Add checkbox selector
    hooks.visibleColumns.push((columns) => [
      // Let's make a column for selection
      {
        id: "selection",
        disableResizing: true,
        minWidth: 35,
        width: 35,
        maxWidth: 35,

        // The header can use the table's getToggleAllRowsSelectedProps method
        // to render a checkbox
        Header: ({ getToggleAllPageRowsSelectedProps }) => (
          <div>
            <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
          </div>
        ),
        // The cell can use the individual row's getToggleRowSelectedProps method
        // to the render a checkbox
        Cell: ({ row }) => (
          <div>
            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
          </div>
        ),
      },
      ...columns,
    ]);

    hooks.useInstanceBeforeDimensions.push(({ headerGroups }) => {
      // fix the parent group of the selection button to not be resizable
      const selectionGroupHeader = headerGroups[0].headers[0];
      selectionGroupHeader.canResize = false;
    });
  };
};

const _getStyles = (props, align = "left") => [
  props,
  {
    style: {
      justifyContent: align === "right" ? "flex-end" : "flex-start",
      alignItems: "flex-start",
      display: "flex",
    },
  },
];

export { headerProps, cellProps, customHooks };
