import React from "react";
import PropTypes from "prop-types";
import RcTooltip from "components/ui/rcTooltip/RcTooltip";
import ImageMdComboItem from "./ImageMdComboItem";
import useMediaQueryExt from "hooks/useMediaQueryExt";

function ImageMdCombo({ data, containerCss, onPreview }) {
  const { isMobile } = useMediaQueryExt();

  return (
    <div className={containerCss}>
      {data.map((o, i) => (
        <ImageMdComboItem
          key={o.sequenceOrder}
          isImage={o.isImage}
          imageUrl={o.imageUrl}
          markDown={o.markDown}
          isLastItem={i === data.length - 1}
          onPreview={onPreview}
        />
      ))}
      <RcTooltip
        message="Click to enlarge the image"
        selector="image-md-combo-image"
        place={`${isMobile ? "bottom" : "right"}`}
      />
    </div>
  );
}

ImageMdCombo.propTypes = {
  data: PropTypes.array,
  containerCss: PropTypes.string,
};

ImageMdCombo.defaultProps = { data: [] };

export default React.memo(ImageMdCombo);
