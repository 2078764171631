import React from "react";
import PropTypes from "prop-types";
import { FacebookProvider, CustomChat } from "react-facebook";
import Config from "config";

function FacebookChat({ appId, pageId }) {
  if (
    navigator.userAgent === "ReactSnap" ||
    Config.environmentMode.toLowerCase() !== "production"
  ) {
    console.log(`Environment: ${Config.environmentMode.toLowerCase()}`);
    return null;
  }

  return (
    <FacebookProvider appId={appId} chatSupport>
      <CustomChat pageId={pageId} minimized={true} />
    </FacebookProvider>
  );
}

FacebookChat.propTypes = {
  appId: PropTypes.string,
  pageId: PropTypes.string,
};

FacebookChat.defaultProps = {};

export default React.memo(FacebookChat);
