import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import useBrands from "./useBrands";

function useHero() {
  const navigate = useNavigate();
  const { brandItems } = useBrands();

  const handleCtaPrimaryClick = useCallback(() => {
    navigate("/home#our-brands");
  }, [navigate]);

  const handleCtaSecondaryClick = useCallback(() => {
    // Todo: Temporary only!!! Discover should display list of houses with search box
    const defaultItem = brandItems[0];

    if (defaultItem == null) return;

    navigate(
      `/discover/brands/${defaultItem.id}/model/${defaultItem.defaultModelId}`
    );
    //navigate("/home#discover");
  }, [navigate, brandItems]);

  return { handleCtaPrimaryClick, handleCtaSecondaryClick };
}

export default useHero;
