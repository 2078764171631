import React from "react";
import PropTypes from "prop-types";
import ReactPlayer from "react-player/lazy";

function MediaPlayer({ url, containerCss, ...rest }) {
  return (
    <section className={containerCss}>
      <ReactPlayer {...rest} url={url} />
    </section>
  );
}

MediaPlayer.propTypes = {
  url: PropTypes.string,
  containerCss: PropTypes.string,
};

MediaPlayer.defaultProps = {};

export default MediaPlayer;
