/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { noop } from "lodash";
//import Loader from "react-loaders";
import "loaders.css/loaders.min.css";
import { BarLoader, PropagateLoader } from "react-spinners";
import { motion } from "framer-motion";

const Splash = ({ children, color, sourceUrl, onDataLoad }) => {
  const [showSplash, setShowSplash] = useState(true);

  useEffect(() => {
    onDataLoad().finally(() => setTimeout(() => setShowSplash(false), 1000));
  }, []);

  return (
    <>
      {showSplash ? (
        <div className="rca-splash">
          <div className="app-splash-container">
            <motion.div
              initial={{ opacity: 0 }}
              transition={{ ease: "easeOut", duration: 2.5 }}
              animate={{ opacity: 1 }}
            >
              <img
                src={sourceUrl}
                className="app-splash-logo mb-2"
                alt="logo"
              />
            </motion.div>
            {/* <BarLoader color="#6f582e" /> */}
            <PropagateLoader color="#6f582e" size={8} />
          </div>
        </div>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

Splash.propTypes = {
  color: PropTypes.string,
  sourceUrl: PropTypes.string,
  onDataLoad: PropTypes.func,
};

Splash.defaultProps = {
  color: "#d7d8d1",
  sourceUrl: "/images/logo/logo-splash.png",
  onDataLoad: noop,
};

export default Splash;
