import React from "react";
import "./email-us.scss";
import fatFooterData from "data/fat-footer-data";

function EmailUs() {
  return (
    <ul class="list-unstyled text-start email-us-wrapper">
      {fatFooterData.emailUs.map((o, i) => (
        <li key={i}>
          <a href={`mailto:${o}`} className="text-break">
            {o}
          </a>
        </li>
      ))}
    </ul>
  );
}

export default EmailUs;
