import React from "react";
import FooterLayoutFactory from "./FooterLayoutFactory";

const FatFooter = (props) => {
  return (
    <section className="fat-footer">
      <div className="container-fluid">
        <FooterLayoutFactory {...props} />
      </div>
    </section>
  );
};

export default FatFooter;
