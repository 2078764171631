import React, { useState } from "react";
import PropTypes from "prop-types";
import Hamburger from "hamburger-react";
import { noop } from "lodash";

function AdminToolbar({
  containerCss,
  collapsed,
  theme,
  leftToolbarComponent,
  rightToolbarComponent,
  isMobileOrTabletView,
  onCollapseSidebar,
}) {
  const [toggledStateInMobileView, setToggledStateInMobileView] =
    useState(false);

  const handleToggleSidebar = () => {
    if (isMobileOrTabletView) {
      setToggledStateInMobileView(true);
      setTimeout(() => {
        setToggledStateInMobileView(false);
      }, 500);
    }

    onCollapseSidebar();
  };

  return (
    <section
      className={`d-flex flex-row toolbar align-items-center ${containerCss}`}
    >
      <Hamburger
        toggled={isMobileOrTabletView ? toggledStateInMobileView : !collapsed}
        toggle={handleToggleSidebar}
        color={theme === "dark" ? "#f8f9fa" : "#343a40"}
        rounded={true}
        size={18}
        aria-controls="Hamburger menu"
      />
      <div className="me-auto">{leftToolbarComponent()}</div>
      <div>{rightToolbarComponent()}</div>
    </section>
  );
}

AdminToolbar.propTypes = {
  containerCss: PropTypes.string,
  collapsed: PropTypes.bool,
  theme: PropTypes.string,
  onCollapseSidebar: PropTypes.func,
  leftToolbarComponent: PropTypes.func,
  rightToolbarComponent: PropTypes.func,
};

AdminToolbar.defaultProps = {
  onCollapseSidebar: noop,
  leftToolbarComponent: noop,
  rightToolbarComponent: noop,
};

export default AdminToolbar;
