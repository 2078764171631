import React from "react";
import PropTypes from "prop-types";
import { motion } from "framer-motion";
import "./playground.scss";

function Playground({ containerCss }) {
  return (
    <section className={`playground-wrapper ${containerCss}`}>
      {/* <div className="mb-4">
        <h3>Animate</h3>
        <motion.div
          className="box"
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.6 }}
        ></motion.div>
      </div> */}

      <div>
        <h3>Keyframe</h3>
        <motion.div
          className="supabox"
          animate={{
            scale: [0.5, 1, 2, 1, 0.5],
            rotate: [0, 0, 180, 180, 0],
            borderRadius: ["20%", "20%", "50%", "50%", "20%"],
          }}
          transition={{
            duration: 2,
            ease: "easeInOut",
            times: [0, 0.2, 0.5, 0.8, 1],
            repeat: Infinity,
            repeatDelay: 1,
          }}
        ></motion.div>
      </div>

      <div>
        <h3>Gesture</h3>
        <motion.div
          className="gestureBox"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.7 }}
        ></motion.div>
      </div>
    </section>
  );
}

Playground.propTypes = {
  containerCss: PropTypes.string,
};

Playground.defaultProps = {};

export default Playground;
