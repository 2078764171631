import React from "react";
import PropTypes from "prop-types";
import PaginationMobileLayout1 from "./PaginationMobileLayout1";

const PagingMobileLayoutFactory = ({ type, ...rest }) => {
  switch (type) {
    case 1:
      return <PaginationMobileLayout1 {...rest} />;
    default:
      return <p>Mobile layout not available.</p>;
  }
};

PagingMobileLayoutFactory.propTypes = {
  type: PropTypes.number,
};

PagingMobileLayoutFactory.defaultProps = { type: 1 };

export default PagingMobileLayoutFactory;
