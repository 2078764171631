import React from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import FormInputs from "./FormInputs";
import { RcButton } from "components/ui/rcButton";
import { noop } from "lodash";
import { FormWatch } from "components/ui/formikFields";

function ContactForm({
  formId,
  loading,
  validator,
  onFormSubmit,
  containerCss,
}) {
  return (
    <section key={formId} className={containerCss}>
      <Formik
        enableReinitialize={true}
        initialValues={{
          fname: "",
          lname: "",
          email: "",
          phone: "",
          company: "",
          message: "",
        }}
        validationSchema={validator}
        onSubmit={onFormSubmit}
      >
        {({ handleSubmit, isSubmitting, ...rest }) => (
          <>
            <FormInputs />
            <RcButton
              loading={loading}
              variant="pill-primary"
              size="lg"
              onClick={handleSubmit}
            >
              Submit
            </RcButton>
          </>
        )}
      </Formik>
    </section>
  );
}

ContactForm.propTypes = {
  validator: PropTypes.object,
  containerCss: PropTypes.string,
  onFormSubmit: PropTypes.func,
};

ContactForm.defaultProps = { onFormSubmit: noop };

export default ContactForm;
