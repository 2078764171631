import React from "react";
import PropTypes from "prop-types";
import "./footer-layout3.scss";
import { NavLink } from "react-router-dom";
import SocialLinks from "components/ui/socialLinks/SocialLinks";
import NewsLetter from "components/ui/newsLetter/NewsLetter";

const FooterLayout3 = ({ data }) => {
  const { socialLinks } = data;

  return (
    <div className="row footer-layout">
      <div className="col">
        <h4 className="py-4">CONNECT</h4>
        <div className="d-flex flex-row justify-content-center">
          <div className="d-flex flex-column align-items-center justify-content-center">
            <SocialLinks data={socialLinks} containerCss="social-links mb-3" />
            {/* <NewsLetter
              buttonType="outline-rounded-primary"
              title=""
              description=""
              buttonTitle="JOIN"
              inputPlaceholder="Email Address"
              containerCss="rca-news-letter"
            /> */}
            <div className="mt-3">
              <NavLink to="/" className="quick-links">
                SIDE HUSTLE
              </NavLink>{" "}
              |{" "}
              <NavLink to="/" className="quick-links">
                BLOG
              </NavLink>{" "}
              |{" "}
              <NavLink to="/" className="quick-links">
                GUNDAM
              </NavLink>{" "}
              |{" "}
              <NavLink to="/" className="quick-links">
                OUTSIDE WORK
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

FooterLayout3.propTypes = { data: PropTypes.object };

FooterLayout3.defaultProps = {};

export default FooterLayout3;
