import React from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";
import { Image } from "react-bootstrap";
import "assets/third-party/slick-theme.css";
import "assets/third-party/slick.css";
import "./slick-carousel.scss";

function SlickCarousel({
  data,
  options,
  itemRendererFn,
  containerCss,
  sliderCss,
}) {
  var settings = {
    //className: "center",
    centerMode: true,
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 4000,
    speed: 500,
    centerPadding: "60px",
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          // slidesToShow: 1,
          // centerMode: true,
          // centerPadding: 0,
          // variableWidth: true,

          slidesToShow: 2,
          centerPadding: 0,
          centerMode: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: 0,
          variableWidth: true,
        },
      },
      {
        breakpoint: 641,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: 0,
          variableWidth: true,
        },
      },
    ],
  };

  return (
    <section
      className={`d-flex flex-row justify-content-center position-relative rc-slick-carousel-wrapper ${containerCss}`}
    >
      <Slider {...settings} {...options} className={`slider ${sliderCss}`}>
        {data.map((o) => (
          <>
            {itemRendererFn ? (
              <>{itemRendererFn({ ...o })}</>
            ) : (
              <div className="img-container">
                <Image key={o.id} src={o.image} alt={o.alt} />
              </div>
            )}
          </>
        ))}
      </Slider>
    </section>
  );
}

SlickCarousel.propTypes = {
  options: PropTypes.object,
  itemRendererFn: PropTypes.func,
  sliderCss: PropTypes.string,
  containerCss: PropTypes.string,
};

SlickCarousel.defaultProps = {};

export default SlickCarousel;
