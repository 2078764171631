import {
  createAsyncThunk,
  createDraftSafeSelector,
  createSlice,
} from "@reduxjs/toolkit";
//import data from "data/services-quote-data";
import { isObject } from "lodash";
import QuoteService from "pages/home/services/apiService";
import {
  addQuotation,
  getQuotations,
  isQuotationExists,
  removeQuotation,
  removeQuotations,
} from "services/modules/quotationStorageService";
import * as SystemSlice from "store/system/systemSlice";

const initialState = {
  quotations: [],
  formEntryDefaults: {
    services: null,
    subCategory: null,
  },
  showModal: false,
  loading: false,
};

// ---------------
// Action Thunks
// ---------------
export const addService = createAsyncThunk(
  "quotation/addService",
  async (data, thunkAPI) => {
    try {
      const { serviceTypeId, serviceId, serviceType, serviceName } = data;

      const isQuoteExistInStorage = isQuotationExists(serviceTypeId, serviceId);

      if (isQuoteExistInStorage)
        return {
          serviceTypeId,
          serviceId,
          serviceType,
          serviceName,
          cancelOperation: true,
        };

      // Save to local storage
      addQuotation({ serviceTypeId, serviceId, serviceType, serviceName });

      return {
        serviceTypeId,
        serviceId,
        serviceType,
        serviceName,
        cancelOperation: false,
      };
    } catch (err) {
      thunkAPI.dispatch(
        SystemSlice.showNotificationError({
          message: "Unable to add service.",
          error: err,
        })
      );
      throw err;
    }
  }
);

export const removeService = createAsyncThunk(
  "quotation/removeService",
  async (data, thunkAPI) => {
    try {
      const { serviceTypeId, serviceId } = data;

      removeQuotation({ serviceTypeId, serviceId });

      return { serviceTypeId, serviceId };
    } catch (err) {
      thunkAPI.dispatch(
        SystemSlice.showNotificationError({
          message: "Unable to remove service.",
          error: err,
        })
      );
      throw err;
    }
  }
);

export const submitQuotation = createAsyncThunk(
  "quotation/submitQuotation",
  async (data, thunkAPI) => {
    try {
      const quotations = getQuotations();

      const payload = {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        mobileNumber: data.mobileNumber,
        landlineNumber: data.landlineNumber,
        company: data.company,
        message: data.message,

        address: {
          addressLine1: data.addressLine1,
          addressLine2: data.addressLine2,
          provinceOrState: data.provinceState.value,
          cityOrMunicipality: data.cityMunicipality.value,
          zipCode: data.zipcode,
        },

        services: [...quotations],
      };

      const result = await new QuoteService().createQuotationAsync(payload);

      removeQuotations();

      return result;
    } catch (err) {
      thunkAPI.dispatch(
        SystemSlice.showNotificationError({
          message: "Unable to submit quotation.",
          error: err,
        })
      );
      throw err;
    }
  }
);

export const loadAllServices = createAsyncThunk(
  "quotation/loadAllServices",
  async (data, thunkAPI) => {
    try {
      const quotations = getQuotations();

      return quotations;
    } catch (err) {
      thunkAPI.dispatch(
        SystemSlice.showNotificationError({
          message: "Unable to load all services.",
          error: err,
        })
      );
      throw err;
    }
  }
);

// ------------
// Slice
// ------------
export const quotationSlice = createSlice({
  name: "quotation",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //
    // Add Service
    //
    builder.addCase(addService.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addService.fulfilled, (state, action) => {
      if (!action.payload.cancelOperation) {
        state.quotations.push({
          serviceTypeId: action.payload.serviceTypeId,
          serviceId: action.payload.serviceId,
          serviceType: action.payload.serviceType,
          serviceName: action.payload.serviceName,
        });
      }

      state.loading = false;
    });
    builder.addCase(addService.rejected, (state) => {
      state.loading = false;
    });

    //
    // Remove Service
    //
    builder.addCase(removeService.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(removeService.fulfilled, (state, action) => {
      state.quotations = state.quotations.filter(
        (f) =>
          !(
            f.serviceTypeId === action.payload.serviceTypeId &&
            f.serviceId === action.payload.serviceId
          )
      );

      state.loading = false;
    });
    builder.addCase(removeService.rejected, (state) => {
      state.loading = false;
    });

    //
    // Load all Services
    //
    builder.addCase(loadAllServices.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(loadAllServices.fulfilled, (state, action) => {
      state.quotations = action.payload.map((o) => ({ ...o }));
      state.loading = false;
    });
    builder.addCase(loadAllServices.rejected, (state) => {
      state.loading = false;
    });

    //
    // Submit Quotation
    //
    builder.addCase(submitQuotation.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(submitQuotation.fulfilled, (state, action) => {
      state.quotations = [];
      state.loading = false;
    });
    builder.addCase(submitQuotation.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const { showRequestQuotation } = quotationSlice.actions;

export default quotationSlice.reducer;

// ------------
// Selectors
// ------------
const selectSelf = (state) => state.quotation;

export const selectQuotation = createDraftSafeSelector(
  selectSelf,
  (state) => state
);

export const selectNumberOfQuotations = createDraftSafeSelector(
  selectSelf,
  (state) => state.quotations.length
);
