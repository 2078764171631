import React from "react";
import PropTypes from "prop-types";
import MobileLayout1 from "./MobileLayout1";

const MobileLayoutFactory = ({
  type,
  headerGroups,
  page,
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  ...rest
}) => {
  switch (type) {
    case 1:
      return (
        <MobileLayout1
          {...rest}
          headerGroups={headerGroups}
          page={page}
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
      );
    default:
      return <p>Mobile layout not available.</p>;
  }
};

MobileLayoutFactory.propTypes = {
  type: PropTypes.number,
};

MobileLayoutFactory.defaultProps = { type: 1 };

export default MobileLayoutFactory;
