import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { Image } from "react-bootstrap";
import MarkDown from "components/ui/markdown/MarkDown";

function ImageMdComboItem({
  isImage,
  imageUrl,
  markDown,
  isLastItem,
  onPreview,
}) {
  const handlePreview = useCallback(
    () => onPreview(imageUrl),
    [onPreview, imageUrl]
  );

  if (isImage) {
    return (
      <Image
        src={imageUrl}
        className={`image-md-combo-image cursor-pointer ${
          isLastItem ? "" : "mb-4"
        }`}
        onClick={handlePreview}
        fluid
        rounded
      />
    );
  }

  return (
    <MarkDown text={markDown} containerCss={`${isLastItem ? "" : "mb-4"}`} />
  );
}

ImageMdComboItem.propTypes = {
  isImage: PropTypes.bool,
  imageUrl: PropTypes.string,
  markDown: PropTypes.string,
  isLastItem: PropTypes.bool,
};

ImageMdComboItem.defaultProps = {};

export default React.memo(ImageMdComboItem);
