import React from "react";
import PropTypes from "prop-types";
import * as SystemSlice from "store/system/systemSlice";
import { useDispatch, useSelector } from "react-redux";
import RcNotificationSection from "components/ui/rcNotification/RcNotificationSection";

function NotificationCenter() {
  const dispatch = useDispatch();

  const { notifications, position } = useSelector(SystemSlice.selectSystem);

  const handleOnClose = (id) => dispatch(SystemSlice.hideNotification(id));

  return (
    <RcNotificationSection
      data={notifications}
      position={position}
      onClose={handleOnClose}
    />
  );
}

NotificationCenter.propTypes = {};

NotificationCenter.defaultProps = {};

export default NotificationCenter;
