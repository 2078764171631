/* eslint-disable react-hooks/exhaustive-deps */
/**
 * Custom React Hooks for detecting Formik errors
 */
import { useEffect, useState } from "react";
import useSystem from "./useSystem";
import { useFormikContext } from "formik";

const useFormErrorDetection = () => {
  const { notify } = useSystem();
  const [hasErrors, setHasErrors] = useState(false);
  const { errors, submitCount } = useFormikContext();
  const [count, setCount] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      setCount(submitCount);
    }, 1);
  }, [submitCount]);

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      setHasErrors(true);
      notify(
        "It appears that some fields are incomplete. Please fill them in and try submitting again."
      );
    }
  }, [count]);

  return { hasErrors };
};

export default useFormErrorDetection;
