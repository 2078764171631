import { MenuItem } from "react-pro-sidebar";
import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";

const SidebarItem = ({ title, to, icon }) => {
  const location = useLocation();

  return (
    <MenuItem
      active={to === location.pathname}
      icon={icon ? <i className={icon} /> : null}
      component={<Link to={to} />}
      className="menu-item"
    >
      {title}
    </MenuItem>
  );
};

SidebarItem.propTypes = {
  title: PropTypes.string,
  to: PropTypes.string,
  icon: PropTypes.string,
  selected: PropTypes.string,
  setSelected: PropTypes.func,
};

SidebarItem.defaultProps = {};

export default SidebarItem;
