import {
  CLIENT_EMAIL,
  FOOTER_PHONE1,
  FOOTER_PHONE2,
  FOOTER_PHONE3,
  FOOTER_PHONE4,
} from "constants/appConstants";

const fatFooterData = {
  location: "",
  callUs: [FOOTER_PHONE1, FOOTER_PHONE2, FOOTER_PHONE3, FOOTER_PHONE4],
  emailUs: [CLIENT_EMAIL],
};

export default fatFooterData;
