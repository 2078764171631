import { lazy } from "react";
import { Navigate, Route } from "react-router-dom";
import HomePage from "pages/home/HomePage";
//import DemoPage from "pages/demo/DemoPage";
// import ButtonPage from "pages/demo/buttons/ButtonPage";
// import FramerPage from "pages/demo/framer/FramerPage";
// import NavBarPage from "pages/demo/navBar/NavBarPage";
// import DataTablePage from "pages/demo/table/DataTablePage";
//import QuotationPage from "pages/quotation/QuotationPage";
//import AddServiceForm from "components/containers/quotation/services/AddServiceForm";
//import PrivacyPage from "pages/privacy/PrivacyPage";
//import RequestCompletedPage from "pages/requestCompleted/RequestCompletedPage";
//import CookiePolicyPage from "pages/cookiePolicy/CookiePolicyPage";
//import CareersPage from "pages/careers/CareersPage";
//import BrandsPage from "pages/brand/BrandsPage";

const PrivacyPage = lazy(() => import("pages/privacy/PrivacyPage"));
const CookiePolicyPage = lazy(() =>
  import("pages/cookiePolicy/CookiePolicyPage")
);
const DemoPage = lazy(() => import("pages/demo/DemoPage"));
const ButtonPage = lazy(() => import("pages/demo/buttons/ButtonPage"));
const FramerPage = lazy(() => import("pages/demo/framer/FramerPage"));
const NavBarPage = lazy(() => import("pages/demo/navBar/NavBarPage"));
const DataTablePage = lazy(() => import("pages/demo/table/DataTablePage"));
const CareersPage = lazy(() => import("pages/careers/CareersPage"));
const AccountVerificationPage = lazy(() =>
  import("pages/auth/client/signinOrSignup/SigninOrSignupPage")
);

const mainRoutes = () => {
  return (
    <>
      <Route index element={<HomePage />} />
      <Route path="home" element={<HomePage />} />

      {/* <Route index element={<CareersPage />} />
      <Route path="home" element={<CareersPage />} /> */}

      {/* <Route path="quotation" element={<QuotationPage />}>
        <Route path="add-service" element={<AddServiceForm />} />
      </Route> */}
      {/* <Route
        path="request-quotation-completed"
        element={<RequestCompletedPage />}
      /> */}
      <Route path="privacy-policy" element={<PrivacyPage />} />
      <Route path="cookie-policy" element={<CookiePolicyPage />} />
      <Route path="careers" element={<CareersPage />} />
      {/* <Route path="brands/:bid" element={<BrandsPage />} /> */}
      <Route path="demo" element={<DemoPage />}>
        <Route path="buttons" element={<ButtonPage />} />
        <Route path="navbar" element={<NavBarPage />} />
        <Route path="framer-motion" element={<FramerPage />} />
        <Route path="data-table" element={<DataTablePage />} />
      </Route>
      {/* <Route path="about" element={<AboutUsView />} />
        <Route path="contacts" element={<ContactUsView />} />
        <Route
          path="demo"
          element={
            <ProtectedRoute>
              <DemoView />
            </ProtectedRoute>
          }
        /> */}
      <Route path="*" element={<Navigate to="/pages/error-404" />} />
    </>
  );
};

export default mainRoutes;
