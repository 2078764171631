import { useState } from "react";
import videoData from "./../data/videoData";

const useOurBrand = (data) => {
  const [showVideoPlayer, setShowVideoPlayer] = useState(false);
  const [videoToPlay, setVideoToPlay] = useState();

  const handleWatchVideo = (id) => {
    const videoData = data.find((f) => f.id === id);

    setVideoToPlay(videoData);
    setShowVideoPlayer(true);
  };

  const hideVideoPlayer = () => setShowVideoPlayer(false);

  return {
    showVideoPlayer,
    videoToPlay,
    handleWatchVideo,
    hideVideoPlayer,
  };
};

export default useOurBrand;
