import React from "react";
import PropTypes from "prop-types";
import useFastField from "../hooks/useFastField";
import InputField from "components/ui/formFields/inputField/InputField";
import ValidationMessage from "../validationMessage/ValidationMessage";

function FormInput({ name, validate, ...rest }) {
  const { field, meta, helpers } = useFastField({ name, validate });

  return (
    <InputField
      {...field}
      {...rest}
      value={field.value}
      onChange={helpers.setValue}
      isInvalid={!!(meta.touched && meta.error)}
      validationComponent={<ValidationMessage errorMessage={meta.error} />}
    />
  );
}

FormInput.propTypes = {
  name: PropTypes.string,
  validate: PropTypes.func,
  containerCss: PropTypes.string,
};

FormInput.defaultProps = { type: "text" };

export default FormInput;
