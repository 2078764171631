import {
  createAsyncThunk,
  createDraftSafeSelector,
  createSlice,
} from "@reduxjs/toolkit";
import * as SystemSlice from "store/system/systemSlice";
import BrandShowcaseService from "./services/apiService";
import { populateBrandsLookup, populateModelsLookup } from "./helpers/mapping";

const initialState = {
  brandsLookup: [],
  modelsLookup: [],
  selectedBrandId: null,
  brandsData: null,
  initialSelectedBrand: null,
  hasDataLoaded: false,
  loading: false,
};

// ---------------
// Action Thunks
// ---------------
export const loadData = createAsyncThunk(
  "brands/loadData",
  async (data, thunkAPI) => {
    try {
      const hasDataLoaded = selectBrands(thunkAPI.getState()).hasDataLoaded;

      // Check cache
      const result = hasDataLoaded
        ? { data: [] }
        : await new BrandShowcaseService().getBrandsAsync();

      return { selectedBrandId: data, data: result.data };
    } catch (err) {
      thunkAPI.dispatch(
        SystemSlice.showNotificationError({
          message: "Unable to fetch brands.",
          error: err,
        })
      );
      throw err;
    }
  }
);

// ------------
// Slice
// ------------
export const brandsSlice = createSlice({
  name: "brands",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //
    // Fetch data
    //
    builder.addCase(loadData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(loadData.fulfilled, (state, action) => {
      state.selectedBrandId = action.payload.selectedBrandId;
      if (!state.hasDataLoaded) {
        state.brandsLookup = populateBrandsLookup(action.payload.data);
        state.modelsLookup = populateModelsLookup(action.payload.data);
        state.brandsData = action.payload.data;
      }

      state.initialSelectedBrand = state.brandsLookup.find(
        (f) => f.value === action.payload.selectedBrandId
      );
      state.hasDataLoaded = true;
      state.loading = false;
    });
    builder.addCase(loadData.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default brandsSlice.reducer;

// ------------
// Selectors
// ------------
const selectSelf = (state) => state.brands;

export const selectBrands = createDraftSafeSelector(
  selectSelf,
  (state) => state
);

export const selectModelsByBrand = createDraftSafeSelector(
  [(state) => state.brands, (state) => state.brands.selectedBrandId], // Input here
  (selectBrands, selectedBrandId) => {
    // Output here

    let result = selectBrands.modelsLookup.filter(
      (f) => f.brandId === selectedBrandId
    );

    return result;
  }
);
