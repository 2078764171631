import React from "react";
import PropTypes from "prop-types";
import BasicModal from "components/ui/formFields/modals/basicModal/BasicModal";
import { noop } from "lodash";
import MediaPlayer from "components/ui/mediaPlayer/MediaPlayer";
import "./video-player.scss";

function VideoPlayerModal({ title, url, showModal, onHandleHide }) {
  return (
    <BasicModal
      title={title}
      isOpen={showModal}
      size="xl"
      showFooter={false}
      centered
      backdropClassName="our-brands-modal"
      onHide={onHandleHide}
      onOk={onHandleHide}
    >
      <div className="our-brand-player-wrapper">
        <MediaPlayer
          url={url}
          playing={true}
          width="100%"
          height="100%"
          className="our-brand-react-player"
        />
      </div>
    </BasicModal>
  );
}

VideoPlayerModal.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string,
  showModal: PropTypes.bool,
  onHandleHide: PropTypes.func,
};

VideoPlayerModal.defaultProps = { onHandleHide: noop };

export default VideoPlayerModal;
