import React from "react";
import PropTypes from "prop-types";
import RcTooltip from "components/ui/rcTooltip/RcTooltip";
import useMediaQueryExt from "hooks/useMediaQueryExt";
import { TwitterShareButton, XIcon } from "react-share";

function XShareButton({ title, url, hashTags, size, containerCss }) {
  const { isMobile } = useMediaQueryExt();

  return (
    <>
      <TwitterShareButton
        title={title}
        url={url}
        hashtags={hashTags}
        className={`xshare ${containerCss}`}
      >
        <XIcon size={size} round />
      </TwitterShareButton>
      <RcTooltip
        message="Share page to X (Twitter)"
        selector="xshare"
        place={`${isMobile ? "bottom" : "left"}`}
      />
    </>
  );
}

XShareButton.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string,
  hashTags: PropTypes.array,
  size: PropTypes.number,
  containerCss: PropTypes.string,
};

XShareButton.defaultProps = { size: 28, hashTags: [] };

export default XShareButton;
