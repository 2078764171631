import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import "./admin-layout.scss";
import AdminToolbar from "../../components/ui/adminToolbar/AdminToolbar";
import RcSidebar from "components/ui/rcSidebar/RcSidebar";
import adminSidebarMenus from "data/admin-sidebar-menu";
import { useAppTheme } from "hooks";
import SidebarAvatar from "./../../components/ui/rcSidebar/components/SidebarAvatar";
import { Button, Stack } from "react-bootstrap";
import ProfileMenu from "components/ui/adminToolbar/profileMenu/ProfileMenu";
import useMediaQueryExt from "hooks/useMediaQueryExt";

const AdminLayout = () => {
  const { isDesktop } = useMediaQueryExt();
  const [collapsed, setCollapsed] = useState(false);
  const [toggled, setToggled] = useState(false);
  const { adminCurrentTheme } = useAppTheme();

  const handleCollapsed = () => {
    if (!isDesktop) {
      setToggled(!toggled);
      setCollapsed(false);
    } else {
      setCollapsed(!collapsed);
      setToggled(false);
    }
  };

  const handleBackdropClick = () => setToggled(!toggled);

  return (
    <div
      className={`d-flex flex-row admin-${adminCurrentTheme} admin-layout-wrapper`}
    >
      <div className="admin-sidebar-container">
        <RcSidebar
          data={adminSidebarMenus}
          brandName="Rcnet App"
          logo="/images/logo/logo-icon.png"
          firstLevelMenuTextColor="#dee0e2"
          secondLevelMenuTextColor="#f8f9fa"
          collapsed={collapsed}
          toggled={toggled}
          sidebarAvatarComponent={(collapsed) => (
            <SidebarAvatar
              collapsed={collapsed}
              imageSrc="/images/avatar/avatar.png"
              avatarName="Mickey Mouse"
            />
          )}
          isMobileOrTabletView={!isDesktop}
          onBackdropClick={handleBackdropClick}
        />
      </div>
      <main role="main" className="admin-content-wrapper w-100">
        <AdminToolbar
          collapsed={collapsed}
          onCollapseSidebar={handleCollapsed}
          theme={adminCurrentTheme}
          isMobileOrTabletView={!isDesktop}
          leftToolbarComponent={() => (
            <Stack direction="horizontal" gap={2}>
              <Button variant="light">
                <i className="fa-solid fa-pen" />
              </Button>
              <Button variant="light">
                <i className="fa-solid fa-envelope" />
              </Button>
            </Stack>
          )}
          rightToolbarComponent={() => (
            <ProfileMenu
              name="Mich Cruz"
              imageSrc="/images/avatar/avatar.png"
            />
          )}
        />
        <div className="route-section">
          <div className="admin-outlet">
            <Outlet />
          </div>
        </div>
      </main>
    </div>
  );
};
export default AdminLayout;
