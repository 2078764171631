import React from "react";
import PropTypes from "prop-types";
import NavBarDemo from "components/features/demo/NavBarDemo";

function NavBarPage({ containerCss }) {
  return (
    <section className={containerCss}>
      <h1>NavBar</h1>
      <NavBarDemo />
    </section>
  );
}

NavBarPage.propTypes = {
  containerCss: PropTypes.string,
};

NavBarPage.defaultProps = {};

export default NavBarPage;
