import React from "react";
import PropTypes from "prop-types";
import Buttons from "components/features/demo/Buttons";
import Framer from "components/features/demo/Framer";

function FramerPage({ containerCss }) {
  return (
    <section className={containerCss}>
      <h1>Framer Motion Effects</h1>
      <Framer containerCss="my-5" />
    </section>
  );
}

FramerPage.propTypes = {
  containerCss: PropTypes.string,
};

FramerPage.defaultProps = {};

export default FramerPage;
