import React from "react";
import PropTypes from "prop-types";
import "./footer-layout4.scss";
import { NavLink } from "react-router-dom";
import SocialLinks from "components/ui/socialLinks/SocialLinks";
import ContactItem from "./contact-item";
import { Image } from "react-bootstrap";

const FooterLayout4 = ({ data }) => {
  const { socialLinks, groupLink1, groupLink2, contactItems } = data;

  return (
    <div className="mb-5">
      <div className="row footer-layout">
        <div className="col">
          <div className="d-flex flex-column flex-lg-row justify-content-center">
            {contactItems.map((o, indx) => (
              <ContactItem
                key={indx}
                title={o.title}
                subTitle={o.subTitle}
                iconCss={o.iconCss}
                containerCss="me-5"
              />
            ))}
          </div>
          <hr />
        </div>
      </div>
      <div className="row footer-layout mt-4">
        <div className="col">
          <div className="d-flex flex-column flex-lg-row  justify-content-between">
            <div className="logo-section">
              <Image src="/images/logo/logo.png" className="footer-logo" />
              <p className="mt-4 text-wrap">
                A member of Pest Exterminator Association of the Philippines
                (PEAP) and Philippine Federation of Pest Management Operator’s
                Association, Inc. (PFMOA, Inc), APMI is a full-suite Pest and
                Termite service provider that uses state-of-the art equipment
                and game-changing technology to provide cost effective pesticide
                solution.
              </p>
            </div>

            <div className="d-flex flex-column text-start item">
              <b className="my-3">{groupLink1.groupName}</b>
              <div className="title-highlight" />
              {groupLink1.links.map((o, i) => (
                <NavLink to={o.url} key={i}>
                  {o.label}
                </NavLink>
              ))}
            </div>

            <div className="d-flex flex-column text-start item">
              <b className="my-3">{groupLink2.groupName}</b>
              <div className="title-highlight" />
              {groupLink2.links.map((o, i) => (
                <NavLink to={o.url} key={i}>
                  {o.label}
                </NavLink>
              ))}
            </div>

            <div className="d-flex flex-column text-start item">
              <b className="my-3">Social</b>
              <div className="title-highlight" />
              <SocialLinks data={socialLinks} containerCss="social-links" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

FooterLayout4.propTypes = { data: PropTypes.object };

FooterLayout4.defaultProps = {};

export default FooterLayout4;
