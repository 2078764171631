import React from "react";
import PropTypes from "prop-types";
import { Toast } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { noop } from "lodash";

function RcNotification({
  id,
  type,
  title,
  message,
  detailsUrl,
  show,
  delay,
  onClose,
}) {
  const handleClose = () => {
    onClose(id);
  };

  const getColorByType = () => {
    switch (type) {
      case "success":
        return "fg-success";
      case "info":
        return "fg-info";
      case "error":
        return "fg-danger";

      default:
        return "fg-warn";
    }
  };

  const getIcon = () =>
    type === "error"
      ? `fas fa-info-circle ${getColorByType()}`
      : `fas fa-info-circle ${getColorByType()}`;

  return (
    <Toast show={show} delay={delay} onClose={handleClose} autohide>
      <Toast.Header className="bg-dark" closeVariant="white" closeButton>
        <i className={`${getIcon()} me-2`} />
        <strong className="me-auto fg-white">{title.toUpperCase()}</strong>
        {detailsUrl && (
          <NavLink className="fg-white" to={detailsUrl}>
            <small>Details</small>
          </NavLink>
        )}
      </Toast.Header>
      <Toast.Body className="fg-dark fw-600 bg-gray-100">{message}</Toast.Body>
    </Toast>
  );
}

RcNotification.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.string,
  detailsUrl: PropTypes.string,
  show: PropTypes.bool,
  delay: PropTypes.number,
  onClose: PropTypes.func,
};

RcNotification.defaultProps = {
  title: "System Notification",
  message: "",
  delay: 9000,
  onClose: noop,
};

export default RcNotification;
