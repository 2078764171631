import React from "react";
import PropTypes from "prop-types";
import { Image } from "react-bootstrap";
import "./service-icon.scss";

function ServicesIcon({ imageUrl, heading, alt, containerCss }) {
  return (
    <section
      className={`service-icon-wrapper d-flex flex-column align-items-center justify-content-center ${containerCss}`}
    >
      <Image
        src={imageUrl}
        alt={alt}
        style={{ height: "80px", padding: "10px" }}
        className="shadowx icon my-4"
        roundedCircle
      />
      <h3 className="pb-3">{heading}</h3>
    </section>
  );
}

ServicesIcon.propTypes = {
  imageUrl: PropTypes.string,
  heading: PropTypes.string,
  alt: PropTypes.string,
  containerCss: PropTypes.string,
};

ServicesIcon.defaultProps = {};

export default ServicesIcon;
