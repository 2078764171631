import { useEffect } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import HttpInterceptor from "../httpInterceptor";
import { noop } from "lodash";

function HttpConfiguration({ onNetworkError, onReLogin, onForbiddenAccess }) {
  useEffect(() => {
    let interceptor = HttpInterceptor({
      onNetworkError,
      onReLogin,
      onForbiddenAccess,
    });

    axios.interceptors.request.use(
      interceptor.interceptorRequestAuth,
      (error) => Promise.reject(error)
    );

    axios.interceptors.response.use(
      undefined,
      interceptor.interceptorResponseError
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}

HttpConfiguration.propTypes = {
  onNetworkError: PropTypes.func,
  onReLogin: PropTypes.func,
  onForbiddenAccess: PropTypes.func,
};

HttpConfiguration.defaultProps = {
  onNetworkError: noop,
  onReLogin: noop,
  onForbiddenAccess: noop,
};

export default HttpConfiguration;
