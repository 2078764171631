const galleryData = [
  {
    id: 1,
    image: "images/gallery/image1.jpg",
    alt: "Image 2",
  },
  {
    id: 2,
    image: "images/gallery/image2.jpg",
    alt: "Image 3",
  },
  {
    id: 3,
    image: "images/gallery/image3.jpg",
    alt: "Image 4",
  },
  {
    id: 4,
    image: "images/gallery/image4.jpg",
    alt: "Image 4",
  },
  {
    id: 5,
    image: "images/gallery/image5.jpg",
    alt: "Image 4",
  },
  {
    id: 6,
    image: "images/gallery/image6.jpg",
    alt: "Image 4",
  },
  {
    id: 7,
    image: "images/gallery/image7.jpg",
    alt: "Image 4",
  },
  {
    id: 8,
    image: "images/gallery/image8.jpg",
    alt: "Image 4",
  },
  {
    id: 9,
    image: "images/gallery/image9.jpg",
    alt: "Image 4",
  },
  {
    id: 10,
    image: "images/gallery/image10.jpg",
    alt: "Image 4",
  },
  {
    id: 11,
    image: "images/gallery/image11.jpg",
    alt: "Image 4",
  },
  {
    id: 12,
    image: "images/gallery/image12.jpg",
    alt: "Image 4",
  },
  {
    id: 13,
    image: "images/gallery/image13.jpg",
    alt: "Image 4",
  },
  {
    id: 14,
    image: "images/gallery/image14.jpg",
    alt: "Image 4",
  },
  {
    id: 15,
    image: "images/gallery/image15.jpg",
    alt: "Image 4",
  },
  {
    id: 16,
    image: "images/gallery/image16.jpg",
    alt: "Image 4",
  },
  {
    id: 17,
    image: "images/gallery/image17.jpg",
    alt: "Image 4",
  },
  {
    id: 18,
    image: "images/gallery/image18.jpg",
    alt: "Image 4",
  },
  {
    id: 19,
    image: "images/gallery/image19.jpg",
    alt: "Image 4",
  },
  {
    id: 20,
    image: "images/gallery/image20.jpg",
    alt: "Image 4",
  },
  {
    id: 21,
    image: "images/gallery/image21.jpg",
    alt: "Image 4",
  },
  {
    id: 22,
    image: "images/gallery/image22.jpg",
    alt: "Image 4",
  },
  {
    id: 23,
    image: "images/gallery/image23.jpg",
    alt: "Image 4",
  },
  {
    id: 24,
    image: "images/gallery/image24.jpg",
    alt: "Image 4",
  },
  {
    id: 25,
    image: "images/gallery/image25.jpg",
    alt: "Image 4",
  },
  {
    id: 26,
    image: "images/gallery/image26.jpg",
    alt: "Image 4",
  },
  {
    id: 27,
    image: "images/gallery/image27.jpg",
    alt: "Image 4",
  },
];

export default galleryData;
