import React from "react";
import PropTypes from "prop-types";
import "./suspense-label.scss";

function SuspenseLabel({ label, containerCss }) {
  return (
    <section
      className={`${containerCss} d-flex flex-column justify-content-center align-items-center w-100 suspense-label-wrapper`}
    >
      {/* <div className="tsmall fg-gray-800">{label}</div> */}
      <h3 className="fg-gray-800">{label}</h3>
    </section>
  );
}

SuspenseLabel.propTypes = {
  label: PropTypes.string,
  containerCss: PropTypes.string,
};

SuspenseLabel.defaultProps = {
  label: "Loading...",
};

export default SuspenseLabel;
