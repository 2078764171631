import Http from "../core/http/http";

function AuthService() {
  let apiUrl = "/v1/authentication";
  
  const login = (payload) => Http().post(`${apiUrl}/token`, payload);

  return {
    login,
  };
}

export default AuthService;
