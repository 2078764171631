import React, { useImperativeHandle } from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import RecaptchaInvisible from "components/ui/recaptchaInvisible/RecaptchaInvisible";
import { useRef } from "react";
import { noop } from "lodash";

const FormCaptcha = React.forwardRef(({ children, onSubmit, ...rest }, ref) => {
  const recaptchaRef = useRef();

  useImperativeHandle(
    ref,
    () => {
      return {
        showChallenge() {
          alert("Challenge na!");
        },
      };
    },
    []
  );

  const handleFormSubmit = async (values) => {
    const token = await recaptchaRef.current.executeAsync();

    if (!token) {
      alert("Invalid token!");
      return;
    }

    recaptchaRef.current.reset();
    values["token"] = token;
    onSubmit(values);
  };

  return (
    <Formik {...rest} onSubmit={handleFormSubmit}>
      {(formProps) => (
        <>
          {children({ ...formProps })}
          <RecaptchaInvisible
            ref={recaptchaRef}
            siteKey="6LcSBnspAAAAAFNpdH6f9ohGXKm9tJXYi8AW7Yfc"
          />
        </>
      )}
    </Formik>
  );
});

FormCaptcha.propTypes = {
  onSubmit: PropTypes.func,
};

FormCaptcha.defaultProps = { onSubmit: noop };

export default FormCaptcha;
