import React from "react";
import PropTypes from "prop-types";
import CheckboxField from "components/ui/formFields/checkboxField/CheckboxField";
import useFastField from "../hooks/useFastField";
import ValidationMessage from "../validationMessage/ValidationMessage";

function FormCheckbox({ name, validate, ...rest }) {
  const { field, meta, helpers } = useFastField({ name, validate });

  const handleOnChange = () => {
    debugger;
    helpers.setValue(!!!field.value);
  };

  return (
    <CheckboxField
      name={name}
      {...field}
      {...rest}
      isInvalid={!!(meta.touched && meta.error)}
      validationComponent={
        <ValidationMessage
          errorMessage={meta.touched && meta.error}
          forceDisplay={true}
        />
      }
      onChange={handleOnChange}
    />
  );
}

FormCheckbox.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  labelBold: PropTypes.bool,
  renderAsMarkdown: PropTypes.bool,
  showRequiredLabel: PropTypes.string,
};

FormCheckbox.defaultProps = {};

export default FormCheckbox;
