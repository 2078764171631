import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as CommonSlice from "store/modules/commonDataSlice";

const useBrands = () => {
  const dispatch = useDispatch();
  const { brandItems } = useSelector(CommonSlice.selectCommonData);

  useEffect(() => {}, []);

  return { brandItems };
};

export default useBrands;
