import Http from "core/http/http";

function ContactUsService() {
  const apiUrl = "/v1/clientMessages";

  const createMessageAsync = (payload) => Http().post(apiUrl, payload);

  return {
    createMessageAsync,
  };
}

export default ContactUsService;
