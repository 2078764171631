import React from "react";
import PropTypes from "prop-types";
import FooterLayout1 from "./layouts/FooterLayout1";
import FooterLayout2 from "./layouts/FooterLayout2";
import FooterLayout3 from "./layouts/FooterLayout3";
import FooterLayout4 from "./layouts/footerLayout4/FooterLayout4";
import FooterLayout5 from "./layouts/footerLayout5/FooterLayout5";

const FooterLayoutFactory = ({ type, data }) => {
  switch (type) {
    case 1:
      return <FooterLayout1 data={data.layout1} />;
    case 2:
      return <FooterLayout2 data={data.layout2} />;
    case 3:
      return <FooterLayout3 data={data.layout3} />;
    case 4:
      return <FooterLayout4 data={data.layout4} />;
    case 5:
      return <FooterLayout5 data={data.layout5} />;

    default:
      return <p>Footer not available.</p>;
  }
};

FooterLayoutFactory.propTypes = {
  type: PropTypes.number,
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

FooterLayoutFactory.defaultProps = { type: 1 };

export default FooterLayoutFactory;
