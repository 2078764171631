import React from "react";
import PropTypes from "prop-types";
import { Badge, Button, Card } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { RcButton } from "components/ui/rcButton";
import "./brand-item.scss";
import PerfectScrollbar from "react-perfect-scrollbar";
import { isObject } from "lodash";
import { ReactComponent as LocationSvg } from "assets/images/feather/map-pin.svg";

function BrandItem({
  id,
  title,
  description,
  descriptionItems,
  defaultModelId,
  tag,
  imageUrl,
  buttonText,
  buttonText2,
  buttonIcon1,
  buttonIcon2,
  containerCss,
  titleHandler,
  location,
  titleLink,
  onPrimaryButtonClick,
  onSecondaryButtonClick,
}) {
  console.log(defaultModelId);

  const getDescriptionItems = () => {
    if (descriptionItems == null || descriptionItems?.length === 0) return null;
    return descriptionItems.map((o, i) => (
      <li style={{ marginBottom: "5px;" }} key={i}>
        {o}
      </li>
    ));
  };

  const getButtonIcon1 = () => {
    if (isObject(buttonIcon1)) {
      return <>{buttonIcon1}</>;
    } else {
      <i className={buttonIcon1} />;
    }
  };

  const getButtonIcon2 = () => {
    if (isObject(buttonIcon2)) {
      return <>{buttonIcon2}</>;
    } else {
      <i className={buttonIcon2} />;
    }
  };

  const handlePrimaryClick = () => onPrimaryButtonClick(id, defaultModelId);

  const handleSecondaryClick = () => onSecondaryButtonClick(id);

  return (
    <section
      className={`service-item-wrapper d-flex justify-content-center ${containerCss}`}
    >
      <Card className="shadow card-service">
        <div className="img-hover-zoom img-hover-zoom--default">
          <Card.Img
            variant="top"
            src={imageUrl}
            style={{ maxHeight: "300px" }}
          />
        </div>
        <Card.Body>
          <Card.Title className="d-flex flex-column justify-content-center align-items-center rca-custom-link py-3">
            <div className="d-flex flex-row align-items-start service-title-container">
              {titleHandler == null && (
                <strong className="service-title text-center">{title}</strong>
              )}
              {typeof titleHandler === "string" && (
                <>
                  <NavLink to={titleLink} className="service-title">
                    {title}
                  </NavLink>
                  <i className="far fa-arrow-right ms-2 tsmall2"></i>
                </>
              )}
              {typeof titleHandler === "function" && (
                <Button
                  variant="link"
                  onClick={titleHandler}
                  className="service-title service-title-link"
                >
                  {title}
                </Button>
              )}
            </div>
          </Card.Title>
          <Card.Text className="card-text-container tsmall1">
            <PerfectScrollbar>
              <div className="location mb-2">
                <LocationSvg height={16} className="location-pin" /> {location}
              </div>
              {description ? (
                <div dangerouslySetInnerHTML={{ __html: description }} />
              ) : (
                <ul style={{ listStyleType: "square" }}>
                  {getDescriptionItems()}
                </ul>
              )}
            </PerfectScrollbar>
          </Card.Text>
          <div className="d-flex flex-row justify-content-end pt-3x mb-2">
            <RcButton
              variant="pill-primary"
              containerCss="fw-600 me-2"
              onClick={handlePrimaryClick}
            >
              {getButtonIcon1()} {buttonText}
            </RcButton>
            <RcButton
              variant="outline-dark-pill-tertiary"
              containerCss="fw-600"
              onClick={handleSecondaryClick}
            >
              {getButtonIcon2()} {buttonText2}
            </RcButton>
          </div>
        </Card.Body>
      </Card>
    </section>
  );
}

BrandItem.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  descriptionItems: PropTypes.array,
  defaultModelId: PropTypes.string,
  buttonText: PropTypes.string,
  buttonText2: PropTypes.string,
  buttonIcon1: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  buttonIcon2: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  containerCss: PropTypes.string,
  imageUrl: PropTypes.string,
  tag: PropTypes.string,
  titleHandler: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  location: PropTypes.string,
  titleLink: PropTypes.string,
  onPrimaryButtonClick: PropTypes.func,
  onSecondaryButtonClick: PropTypes.func,
};

BrandItem.defaultProps = {
  title: "--",
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore",
  buttonText: "Button 1",
  imageUrl: "images/services/termite.jpg",
  titleLink: "",
};

export default BrandItem;
