import React from "react";
import PropTypes from "prop-types";
import GoogleMap from "google-maps-react-markers";
import "./gmap.scss";
import LocationPin from "./locationPin/LocationPin";

function Gmap({ settings, zoomLevel, minHeight, containerCss }) {
  return (
    <div className={`google-map-wrapper ${containerCss}`}>
      <GoogleMap
        apiKey={settings.gmapKey}
        defaultCenter={settings.location}
        defaultZoom={zoomLevel}
        mapMinHeight={minHeight}
        libraries={["places"]}
      >
        <LocationPin
          lat={settings.location.lat}
          lng={settings.location.lng}
          text={settings.location.address}
        />
      </GoogleMap>
    </div>
  );
}

Gmap.propTypes = {
  settings: PropTypes.object,
  zoomLevel: PropTypes.number,
  minHeight: PropTypes.string,
  containerCss: PropTypes.string,
};

Gmap.defaultProps = { minHeight: "70vh" };

export default Gmap;
