import React, { useRef } from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import SignupFormInputs from "./formInput/SignupFormInput";
import { FormWatch } from "components/ui/formikFields";
import FormCaptcha from "components/ui/formikFields/formCaptcha/FormCaptcha";

const ClientSignup = React.forwardRef(
  ({ validator, containerCss, onFormSubmit }, ref) => {
    return (
      <section className={containerCss}>
        <FormCaptcha
          ref={ref}
          enableReinitialize={true}
          initialValues={{
            firstName: "",
            lastName: "",
            email: "",
            mobileNumber: "",
            landlineNumber: "",
            addressLine1: "",
            addressLine2: "",
            provinceState: null,
            cityMunicipality: null,
            zipcode: "",
            allowPromotion: true,
          }}
          //validationSchema={validator}
          onSubmit={onFormSubmit}
          //onSubmit={onFormSubmit}
        >
          {({ values, isSubmitting, setFieldValue, handleSubmit }) => (
            <>
              <SignupFormInputs
                values={values}
                onSubmit={handleSubmit}
                setFieldValue={setFieldValue}
              />
              {/* <FormWatch /> */}
            </>
          )}
        </FormCaptcha>
      </section>
    );
  }
);

ClientSignup.propTypes = {
  containerCss: PropTypes.string,
  validator: PropTypes.object,
  onFormSubmit: PropTypes.func,
};

ClientSignup.defaultProps = {};

export default ClientSignup;
