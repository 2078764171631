import React from "react";
import PropTypes from "prop-types";
import "./footer-layout5.scss";
import { NavLink } from "react-router-dom";
import SocialLinks from "components/ui/socialLinks/SocialLinks";
import { Image } from "react-bootstrap";
import {
  CLIENT_EMAIL,
  CLIENT_EMAIL2,
  CLIENT_EMAIL3,
} from "constants/appConstants";

const FooterLayout5 = ({ data }) => {
  const { socialLinks, groupLink1, groupLink2, webSiteDescription } = data;

  return (
    <div className="mb-5">
      <div className="row footer-layout mt-4">
        <div className="col">
          <div className="d-flex flex-column flex-lg-row  justify-content-between">
            <div className="logo-section">
              <Image
                src="/images/logo/footer-logo.png"
                className="footer-logo"
              />
              <p
                className="mt-4 text-wrap description"
                dangerouslySetInnerHTML={{ __html: webSiteDescription }}
              />
              <p className="fg-primary fw-700">
                VISTA ESTATES South by Angelica Villarante
                <br />
                (APC Sales Supremacy)
              </p>
            </div>

            <div className="d-flex flex-column text-start item">
              <b className="my-3">{groupLink1.groupName}</b>
              <div className="title-highlight" />
              {groupLink1.links.map((o, i) => (
                <NavLink to={o.url} key={i}>
                  {o.label}
                </NavLink>
              ))}
            </div>

            <div className="d-flex flex-column text-start item">
              <b className="my-3">{groupLink2.groupName}</b>
              <div className="title-highlight" />
              {groupLink2.links.map((o, i) => (
                <NavLink to={o.url} key={i}>
                  {o.label}
                </NavLink>
              ))}
            </div>

            <div className="d-flex flex-column text-start item">
              <b className="my-3">Follow Us</b>
              <div className="title-highlight" />
              <SocialLinks data={socialLinks} containerCss="social-links" />
            </div>

            <div className="d-flex flex-column text-start item">
              <b className="my-3">Call / Email Us</b>
              <div className="title-highlight" />
              <a href="tel:639232786034">(63) 939 915 2047</a>
              <a href="tel:639232786034">(63) 916 492 6578</a>
              <a href={`mailto:${CLIENT_EMAIL2}`}>{CLIENT_EMAIL2}</a>
              <a href={`mailto:${CLIENT_EMAIL3}`}>{CLIENT_EMAIL3}</a>
              <a href={`mailto:${CLIENT_EMAIL}`}>{CLIENT_EMAIL}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

FooterLayout5.propTypes = { data: PropTypes.object };

FooterLayout5.defaultProps = {};

export default FooterLayout5;
