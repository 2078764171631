import React from "react";
import PropTypes from "prop-types";
import { ContentPane } from "components/ui/contentPane";
import "./about-us.scss";
import { Col, Image, Row } from "react-bootstrap";
import { RcButton } from "components/ui/rcButton";

function AboutUs({ containerCss }) {
  return (
    <ContentPane
      id="about-us"
      isFullWidth={true}
      hasShadow={false}
      containerCss="about-wrapper mt-3"
      contentCss="content-box"
      contentPaddingHorizontal="0"
      contentPaddingVertical="0"
      mainContentCss="about-content-wrapper"
    >
      <Row className="about-content gx-0">
        <Col className="d-flex flex-column p-5" xs={12} md={12} lg={6}>
          <h1 className="rca-heading3 mb-3">About Us</h1>
          <div className="title-separator mb-4" />
          <p>
            A member of Pest Exterminator Association of the Philippines (PEAP)
            and Philippine Federation of Pest Management Operator’s Association,
            Inc. (PFMOA, Inc), APMI is a full-suite Pest and Termite service
            provider that uses state-of-the art equipment and game-changing
            technology to provide cost effective pesticide solution.
          </p>

          <h6 className="mt-4">CORPORATE OVERVIEW</h6>
          <p>
            In 1986, Abbatoir Pest Control was founded originally offering
            services to residential clients, APC has evolved into a full suite
            of Pest and Termite service solution and has officially tapped
            several goverment agencies, commercial establishments and
            residential areas as well.
          </p>

          <p>
            In 1991, APC was transformed into a corporation named Abbatoir Pest
            Management Inc was formed. Its primary objective is to expand the
            business and enter new target market on the government sector,
            education, hospitality, food beverage &amp; restaurants,
            manufacturing and logistics.
          </p>

          <p>
            In 2011, APMI has accredited itself in various Pest Management
            Associations/Organization in order to maintain and improve
            capabilities and equipment with strict adherence to industry and
            international standards in pest management. Since then, Licensed
            technicians have been attending training courses and have acquired
            various certification on specific pest management discipline.
          </p>

          <p>
            To date, APMI grew in number of clients both residential and
            commercial and have partnered with several government projects. And
            our mission: To give the best quality services has been maintained
            through the years.
          </p>

          <h6 className="mt-4">CERTIFICATIONS</h6>
          <p>
            <i className="fas fa-certificate certificate me-2" />
            Yearly - Pest Control Technician&#39;s Training Philippine
            Exterminators Association of the Philippines
          </p>
          <p>
            <i className="fas fa-certificate certificate me-2" />
            Setricon Termite Colony Elimination System Training - July 2014 -
          </p>
          <p>
            <i className="fas fa-certificate certificate me-2" />
            Continuous Termite and Pesticide Education - Quarterly appraisal to
            all Lead and Sr. Technicians
          </p>
        </Col>
        <Col className="d-flex flex-column justify-content-center">
          {/* <Image src="/images/about-us/about-main.webp" fluid rounded /> */}
          <div>
            <Image
              src="/images/about-us/about-main.webp"
              className="about-us-image"
              fluid
            />
          </div>
        </Col>
      </Row>
    </ContentPane>
  );
}

AboutUs.propTypes = {
  containerCss: PropTypes.string,
};

AboutUs.defaultProps = {};

export default AboutUs;
