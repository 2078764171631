const adminSidebarMenus = [
  {
    menu: "Users",
    icon: "fa-solid fa-users",
    subMenus: [
      {
        menu: "View Users",
        to: "/admin/demo",
      },
      {
        menu: "Delete User",
        to: "/admin/demo/framer-motion",
      },
      {
        menu: "View Users",
        to: "/admin/demo",
      },
      {
        menu: "Delete User",
        to: "/admin/demo/framer-motion",
      },
      {
        menu: "View Users",
        to: "/admin/demo",
      },
      {
        menu: "Delete User",
        to: "/admin/demo/framer-motion",
      },
      {
        menu: "View Users",
        to: "/admin/demo",
      },
      {
        menu: "Delete User",
        to: "/admin/demo/framer-motion",
      },
      {
        menu: "View Users",
        to: "/admin/demo",
      },
      {
        menu: "Delete User",
        to: "/admin/demo/framer-motion",
      },
      {
        menu: "View Users",
        to: "/admin/demo",
      },
      {
        menu: "Delete User",
        to: "/admin/demo/framer-motion",
      },
      {
        menu: "View Users",
        to: "/admin/demo",
      },
      {
        menu: "Delete User",
        to: "/admin/demo/framer-motion",
      },
      {
        menu: "View Users",
        to: "/admin/demo",
      },
      {
        menu: "Delete User",
        to: "/admin/demo/framer-motion",
      },
    ],
  },
  {
    menu: "Permissions",
    to: "doc",
    icon: "fa-solid fa-key",
    permissionHook: null,
  },
];

export default adminSidebarMenus;
