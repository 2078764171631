import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Row, Tab, Tabs } from "react-bootstrap";
import { FormInput } from "components/ui/formikFields";
import FormSelectField from "components/ui/formikFields/formSelect/FormSelectField";
import useFastField from "components/ui/formikFields/hooks/useFastField";

function FormInputs({ serviceLookups, containerCss }) {
  const [subCategoryFinal, setSubCategoryFinal] = useState();
  const { field } = useFastField({ name: "services" });
  const { helpers } = useFastField({ name: "subCategory" });

  useEffect(() => {
    if (field.value == null) return;

    setSubCategoryFinal(field.value.subCategories);
  }, [field.value]);

  const handleChangeOverride = () => {
    helpers.setValue(null);
  };

  return (
    <section className={containerCss}>
      <Row>
        <FormSelectField
          name="services"
          label="Service Type"
          options={serviceLookups}
          showRequiredLabel={true}
          closeMenuOnSelect={true}
          xs={12}
          onChangeOverride={handleChangeOverride}
        />
      </Row>
      <Row>
        <FormSelectField
          name="subCategory"
          label="Sub Category"
          options={subCategoryFinal}
          showRequiredLabel={true}
          closeMenuOnSelect={true}
          xs={12}
        />
      </Row>
      <Row>
        <FormInput
          name="fname"
          label="First name"
          showRequiredLabel={true}
          xs={12}
          md={6}
        />
        <FormInput
          name="lname"
          label="Last name"
          showRequiredLabel={true}
          xs={12}
          md={6}
        />
      </Row>
      <Row>
        <FormInput
          name="email"
          label="Email"
          showRequiredLabel={true}
          xs={12}
          md={4}
        />
        <FormInput
          name="phone"
          label="Cellphone Number"
          showRequiredLabel={true}
          xs={12}
          md={4}
        />
        <FormInput
          name="phone"
          label="Landline Number"
          showRequiredLabel={true}
          xs={12}
          md={4}
        />
      </Row>
      <Row>
        <FormInput name="company" label="Company" xs={12} />
      </Row>
      <Row>
        <FormInput type="textarea" name="message" label="Message" xs={12} />
      </Row>
    </section>
  );
}

FormInputs.propTypes = {
  serviceLookups: PropTypes.array,
  containerCss: PropTypes.string,
};

FormInputs.defaultProps = { serviceLookups: [] };

export default FormInputs;
