import { useRef, useState } from "react";

const useStateWithRef = () => {
  const [stateValue, setValue] = useState();
  const stateRef = useRef();

  const setStateValue = (value) => {
    setValue(value);
    stateRef.current = value;
  };

  return { stateValue, stateRefValue: stateRef.current, setStateValue };
};

export default useStateWithRef;
