import React from "react";
import PropTypes from "prop-types";
import "./intro-text-left-aligned.scss";
import { RcButton } from "components/ui/rcButton";
import { noop } from "lodash";
import { Row } from "react-bootstrap";

function IntroTextLeftAligned({
  title,
  subTitle,
  ctaPrimaryText,
  ctaSecondaryText,
  containerCss,
  onCtaClick,
}) {
  const handleCtaPrimaryClick = () => onCtaClick("primary");

  const handleCtaSecondaryClick = () => onCtaClick("secondary");

  return (
    <section
      className={`intro-text-wrapper intro-left-aligned custom-heading d-flex flex-column justify-content-center ${containerCss}`}
    >
      <h1 className="rca-heading1 intro-title">{title}</h1>
      {subTitle && <h1 className="rca-heading3">{subTitle}</h1>}

      {/* For Desktop */}
      <div className="d-md-flex d-none intro-cta my-5 gy-2">
        {ctaPrimaryText && (
          <RcButton
            variant="primary"
            size="lg"
            containerCss="me-3"
            onClick={handleCtaPrimaryClick}
          >
            {ctaPrimaryText}
          </RcButton>
        )}

        {ctaSecondaryText && (
          <RcButton
            variant="custom-outline-secondary"
            size="lg"
            onClick={handleCtaSecondaryClick}
          >
            {ctaSecondaryText}
          </RcButton>
        )}
      </div>

      {/* For Mobile */}
      <Row className="d-flex d-md-none intro-cta my-5 gy-2">
        {ctaPrimaryText && (
          <RcButton
            variant="primary"
            size="lg"
            containerCss="me-3"
            onClick={handleCtaPrimaryClick}
          >
            {ctaPrimaryText}
          </RcButton>
        )}

        {ctaSecondaryText && (
          <RcButton
            variant="custom-outline-secondary"
            size="lg"
            onClick={handleCtaSecondaryClick}
          >
            {ctaSecondaryText}
          </RcButton>
        )}
      </Row>
    </section>
  );
}

IntroTextLeftAligned.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  buttonPrimaryText: PropTypes.string,
  buttonSecondaryText: PropTypes.string,
  containerCss: PropTypes.string,
  onCtaClick: PropTypes.func,
};

IntroTextLeftAligned.defaultProps = { onCtaClick: noop };

export default React.memo(IntroTextLeftAligned);
