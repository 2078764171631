import React from "react";
import PropTypes from "prop-types";
import { Card } from "react-bootstrap";
import "./card-view.scss";
import MotionDiv from "components/features/motionDiv/MotionDiv";

function CardView({ title, children, containerCss, ...rest }) {
  return (
    <MotionDiv>
      <Card className="shadow" {...rest}>
        <Card.Body className="card-view-wrapper">
          {title && (
            <>
              <Card.Title className={`d-flex flex-column ${containerCss}`}>
                <h4>{title}</h4>
                <div className="title-separator" />
              </Card.Title>
              <div className="mt-4 mb-3">{children}</div>
            </>
          )}
          {!title && <>{children}</>}
        </Card.Body>
      </Card>
    </MotionDiv>
  );
}

CardView.propTypes = {
  title: PropTypes.string,
};

CardView.defaultProps = {};

export default CardView;
