import { Navigate, Route } from "react-router-dom";
import Page404 from "pages/misc/404/page-404";
import Page500 from "pages/misc/500/page-500";

const miscRoutes = () => {
  return (
    <>
      <Route path="error-404" element={<Page404 />} />
      <Route path="error-500" element={<Page500 />} />
      <Route path="*" element={<Navigate to="/pages/error-404" />} />
    </>
  );
};

export default miscRoutes;
