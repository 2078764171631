import React from "react";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import PageNumberMobileLayout1 from "./PageNumberMobileLayout1";
import { RcButton } from "components/ui/rcButton";

const PaginationMobileLayout1 = ({
  canPreviousPage,
  canNextPage,
  pageCount,
  previousPage,
  nextPage,
  gotoPage,
  containerCss,
  ...rest
}) => {
  return (
    <section className={containerCss}>
      <div className="pagination d-flex flex-row justify-content-between">
        <div className="d-flex flex-row justify-content-start">
          <RcButton
            containerCss="me-1"
            variant="outline-pill-light"
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            <i className="fas fa-step-backward" />
          </RcButton>

          <RcButton
            containerCss="me-1"
            variant="outline-pill-light"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            <i className="fas fa-caret-left" />
          </RcButton>
        </div>

        {/* Page Number */}
        <PageNumberMobileLayout1 {...rest} gotoPage={gotoPage} />

        <div className="d-flex flex-row justify-content-end">
          <RcButton
            containerCss="me-1"
            variant="outline-pill-light"
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            <i className="fas fa-caret-right" />
          </RcButton>

          <RcButton
            containerCss="me-1"
            variant="outline-pill-light"
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            <i className="fas fa-step-forward" />
          </RcButton>
        </div>
      </div>
    </section>
  );
};

PaginationMobileLayout1.propTypes = {
  containerCss: PropTypes.string,
};

PaginationMobileLayout1.defaultProps = {};

export default PaginationMobileLayout1;
