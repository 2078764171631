import React from "react";
import PropTypes from "prop-types";
import { DefaultMediaQuery, Mobile } from "components/ui/responsive";
import PageNumber from "./pageNumber/PageNumber";
import PagingMobileLayoutFactory from "./layouts/PaginationMobileLayoutFactory";
import { Button, Card } from "react-bootstrap";
import { RcButton } from "components/ui/rcButton";

const Pagination = ({
  gotoPage,
  previousPage,
  nextPage,
  canPreviousPage,
  canNextPage,
  pageCount,
  paginationContainerCss,
  ...rest
}) => {
  const renderPagination = () => {
    return (
      <div className="pagination d-flex flex-row justify-content-betweenx justify-content-center">
        <div className="w30 h10 d-flex flex-row justify-content-betweenx justify-content-end">
          <RcButton
            containerCss="me-2"
            variant="outline-pill-light"
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            {"First"}
          </RcButton>
          <RcButton
            className="w50 me-1"
            variant="outline-pill-light"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            {"Prev"}
          </RcButton>
        </div>

        <PageNumber {...rest} gotoPage={gotoPage} />

        <div className="w30 h10 d-flex flex-row justify-content-betweenx">
          <RcButton
            containerCss="me-2"
            variant="outline-pill-light"
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            {"Next"}
          </RcButton>
          <RcButton
            variant="outline-pill-light"
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            {"Last"}
          </RcButton>
        </div>
      </div>
    );
  };

  return (
    <section className={paginationContainerCss}>
      <Card className="my-2 px-1 mb-1">
        <Card.Body>
          <DefaultMediaQuery>{renderPagination()}</DefaultMediaQuery>
          <Mobile>
            <PagingMobileLayoutFactory
              {...rest}
              gotoPage={gotoPage}
              previousPage={previousPage}
              nextPage={nextPage}
              canPreviousPage={canPreviousPage}
              canNextPage={canNextPage}
              pageCount={pageCount}
            />
          </Mobile>
        </Card.Body>
      </Card>
    </section>
  );
};

Pagination.propTypes = {
  paginationContainerCss: PropTypes.string,
  canPreviousPage: PropTypes.bool,
  canNextPage: PropTypes.bool,
  pageCount: PropTypes.number,
  pageIndex: PropTypes.number,
  pageOptions: PropTypes.array,
  pageSize: PropTypes.number,
  setPageSize: PropTypes.func,
  gotoPage: PropTypes.func,
  previousPage: PropTypes.func,
  nextPage: PropTypes.func,
  paginationOptions: PropTypes.shape({
    sizeSelection: PropTypes.array,
    selectionLabel: PropTypes.string,
    defaultPageSize: PropTypes.number,
  }),
};

export default Pagination;
