import React from "react";
import PropTypes from "prop-types";
import { ContentPane } from "components/ui/contentPane";
import SectionTitle from "components/ui/pageSection/SectionTitle";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import BrandItem from "./brandItem/BrandItem";
import { ReactComponent as VideoSvg } from "assets/images/feather/video.svg";
import VideoPlayerModal from "./videoPlayer/VideoPlayerModal";
import useOurBrand from "./hooks/useOurBrand";
import "./our-brands.scss";
import MotionDiv from "../motionDiv/MotionDiv";
import { useNavigate } from "react-router-dom";

function OurBrands({ data, containerCss }) {
  const ourBrandHook = useOurBrand(data);
  const navigate = useNavigate();

  const handleExplore = (id, defaultModelId) => {
    navigate(`/discover/brands/${id}/model/${defaultModelId}`);
  };

  return (
    <ContentPane
      id="our-brands"
      isFullWidth={true}
      hasShadow={false}
      containerCss="our-brands-wrapper"
      contentCss="content-box"
      contentPaddingHorizontal="3"
      contentPaddingVertical="3"
      mainContentCss="main-content py-5"
    >
      <VideoPlayerModal
        title={ourBrandHook.videoToPlay?.brandName}
        url={ourBrandHook.videoToPlay?.videoUrl}
        showModal={ourBrandHook.showVideoPlayer}
        onHandleHide={ourBrandHook.hideVideoPlayer}
      />
      <SectionTitle title="Our Brands" />
      <MotionDiv>
        <Row className="section-content">
          <Col xs={12} className="d-flex justify-content-center">
            <div className="d-flex justify-content-center flex-wrap brand-items-container">
              {data.map((o) => (
                <BrandItem
                  key={o.id}
                  id={o.id}
                  title={o.brandName}
                  imageUrl={o.imageLargeUrl}
                  thumbnailUrl={o.imageThumbnailUrl}
                  location={o.locationText}
                  description={o.description}
                  defaultModelId={o.defaultModelId}
                  buttonText="Explore"
                  buttonText2="Watch"
                  buttonIcon2={<VideoSvg height={22} />}
                  containerCss="m-2"
                  onPrimaryButtonClick={handleExplore}
                  onSecondaryButtonClick={ourBrandHook.handleWatchVideo}
                />
              ))}
            </div>
          </Col>
        </Row>
      </MotionDiv>
    </ContentPane>
  );
}

OurBrands.propTypes = {
  data: PropTypes.array,
  containerCss: PropTypes.string,
};

OurBrands.defaultProps = { data: [] };

export default OurBrands;
