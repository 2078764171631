export const introCarouselData = [
  {
    id: 1,
    //image: "images/home/carousel/show1.jpg",
    image: "https://rcnetlabs-imaging.sirv.com/Vista/web-cover/show1.webp",
    alt: "Image 2",
    interval: 8000,
  },
  {
    id: 2,
    image: "https://rcnetlabs-imaging.sirv.com/Vista/web-cover/show2.jpg",
    alt: "Image 3",
    interval: 5000,
  },
  {
    id: 3,
    image: "https://rcnetlabs-imaging.sirv.com/Vista/web-cover/show3.jpg",
    alt: "Image 4",
    interval: 5000,
  },
];
