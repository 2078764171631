import { useState } from "react";

function useImagePreview() {
  const [showImagePreview, setShowImagePreview] = useState(false);
  const [imagePreviewUrl, setImagePreviewUrl] = useState();

  const previewImage = (imageUrl, show) => {
    setImagePreviewUrl(imageUrl);
    setShowImagePreview(show);
  };

  return { showImagePreview, imagePreviewUrl, previewImage };
}

export default useImagePreview;
