import {
  createAsyncThunk,
  createDraftSafeSelector,
  createSlice,
} from "@reduxjs/toolkit";
import data from "./data/data";

const initialState = {
  serviceLookups: data,
  formEntryDefaults: {
    services: null,
    subCategory: null,
  },
  showModal: false,
};

// ---------------
// Action Thunks
// ---------------
//
// Shape: {message: "", error: <object>}
//
// export const showNotificationError = createAsyncThunk(
//   "system/showNotificationError",
//   async (data, thunkAPI) => {
//     try {
//       //Todo: perform logging here (sentry)
//       if (!isObject(data)) return data;

//       const { error } = data;

//       // Handle HTTP 400 error
//       if (error?.response?.status === 400) {
//         console.log(error?.response?.data?.Errors[0]);

//         return {
//           message: data?.message,
//           errorMessage: error?.response?.data?.Errors[0], // Return first validation error
//           detailsUrl: data?.detailsUrl,
//         };
//       }

//       if (error?.response?.status === 409) {
//         console.log(error?.response?.data?.Message);

//         return {
//           message: data?.message,
//           errorMessage: error?.response?.data?.Message,
//           detailsUrl: data?.detailsUrl,
//         };
//       }

//       console.log(data?.error);

//       return { message: data?.message, errorMessage: data?.error?.message };
//     } catch (err) {
//       throw err;
//     }
//   }
// );

// ------------
// Slice
// ------------
export const requestQuotationSlice = createSlice({
  name: "requestQuotation",
  initialState,
  reducers: {
    showRequestQuotation: (state, action) => {
      state.showModal = action.payload.show;

      if (action.payload.serviceTypeId == null) return;

      state.formEntryDefaults.services = data.find(
        (f) => f.value === action.payload.serviceTypeId
      );

      if (action.payload.subCategoryId == null) return;

      state.formEntryDefaults.subCategory =
        state.formEntryDefaults.services.subCategories.find(
          (f) => f.value === action.payload.subCategoryId
        );
    },
  },
  extraReducers: (builder) => {
    // //
    // // Toast Error
    // //
    // builder.addCase(showNotificationError.pending, (state) => {});
    // builder.addCase(showNotificationError.fulfilled, (state, action) => {
    //   const message =
    //     action.payload?.message == null && action.payload.errorMessage != null
    //       ? action.payload.errorMessage
    //       : action.payload?.message;
    //   state.notifications.unshift({
    //     id: uniqueId("notif"),
    //     show: true,
    //     type: "error",
    //     title: "Error Notification",
    //     message,
    //     errorMessage: action.payload?.errorMessage,
    //     detailsUrl: action.payload?.detailsUrl,
    //   });
    // });
    // builder.addCase(showNotificationError.rejected, (state) => {});
  },
});

export const { showRequestQuotation } = requestQuotationSlice.actions;

export default requestQuotationSlice.reducer;

// ------------
// Selectors
// ------------
const selectSelf = (state) => state.requestQuotation;

export const selectRequestQuotation = createDraftSafeSelector(
  selectSelf,
  (state) => state
);
