/**
 * Authentication library
 * Create singleton instance
 */
 import Config from "../../config";
 
class Auth {
  isAuthenticated() {
    // Check storage if token still in the local store
    let token = this.getToken();

    return token != null ? !!token : false;
  }

  setToken(token, refreshToken, expiresIn) {
    //this._populateModel(token, refreshToken, expiresIn);

    //if (this.useLocalStorage) {
    localStorage.setItem(
      Config.authStorageKey,
      JSON.stringify({ token, refreshToken, expiresIn })
    );
    //}
  }

  removeToken() {
    localStorage.removeItem(Config.authStorageKey);
  }

  getToken() {
    const tokenObj = this._fetchFullTokenObject();
    if (!tokenObj) return tokenObj;

    return tokenObj.token;
  }

  getRefreshToken() {
    const tokenObj = this._fetchFullTokenObject();
    if (!tokenObj) return tokenObj;

    return tokenObj.refreshToken;
  }

  // Refresh token payload builder
  getRefreshTokenPayload(clientId, username) {
    const refreshTokenPayload = {
      username,
      ClientId: clientId,
      GrantType: "refresh_token",
      RefreshToken: this.getRefreshToken(),
    };

    return refreshTokenPayload;
  }

  _fetchFullTokenObject() {
    let tokenObject = localStorage.getItem(Config.authStorageKey);

    return JSON.parse(tokenObject);
  }
}

export default Auth = new Auth();
