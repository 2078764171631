import Http from "core/http/http";

function CareersService() {
  const apiUrl = "/v1/jobApplications";

  const submitJobApplication = (payload) => Http().post(`${apiUrl}`, payload);

  return {
    submitJobApplication,
  };
}

export default CareersService;
