import React from "react";
import PropTypes from "prop-types";
import TableDemo from "components/features/demo/TableDemo";

function DataTablePage({ containerCss }) {
  return (
    <section className={containerCss}>
      <h2 className="mb-5">Data Table</h2>
      <TableDemo />
    </section>
  );
}

DataTablePage.propTypes = {
  containerCss: PropTypes.string,
};

DataTablePage.defaultProps = {};

export default DataTablePage;
