import React, { useState } from "react";
import PropTypes from "prop-types";
import FormInputDemo from "components/features/demo/FormInputDemo";
import FormikDemo from "components/features/demo/FormikDemo";
import { formDemoValidator } from "./validators/form-validator";
import axios from "axios";
import useFileUploaderUtils from "components/ui/formFields/fileUpload/hooks/useFileUploaderUtils";
import { useEffect } from "react";

function InputDemoPage({ containerCss }) {
  const [data, setData] = useState([]);
  const { getFormData } = useFileUploaderUtils();

  const handleUpload = (files, mode, resolve, reject) => {
    const formData = getFormData(files, 1, mode);

    axios({
      method: "post",
      url: "https://localhost:7259/api/v1/files",
      data: formData,
    })
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((ex) => {
        console.log(ex);
        reject(ex);
      });
  };

  const handleRemoveFile = (id, isImage) => {
    return axios({
      method: "delete",
      url: `https://localhost:7259/api/v1/files/${id}/isImage/${isImage}`,
    });
  };

  const loadData = () => {
    // const payload = {
    //   ids: [
    //     "3BB8AA86-3FB6-42B4-E592-08DC0BAF63D0",
    //     "F02D10DF-EA78-4C2E-E593-08DC0BAF63D0",
    //     "9DDEC4ED-9D14-41F2-E594-08DC0BAF63D0",
    //   ],
    //   isImage: false,
    // };

    // const payload = {
    //   ids: [
    //     "A1DF21A2-5F90-4370-10C6-08DC0C3B0040",
    //     "F44925E3-800F-4819-10C5-08DC0C3B0040",
    //     "C200E6DB-4D60-473F-10C4-08DC0C3B0040",
    //     "86FB98F7-57A9-419E-10C3-08DC0C3B0040",
    //   ],
    //   isImage: true,
    // };

    const payload = {
      ids: [],
      isImage: true,
    };

    axios({
      method: "post",
      url: "https://localhost:7259/api/v1/files/getfiles",
      data: payload,
    })
      .then((resp) => {
        setData(resp.data);
      })
      .catch((ex) => {
        console.log(ex);
        alert(ex);
      });
  };

  const handleActivityInProgress = (value) => {
    console.log(`Activity: ${value}`);
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <section className={containerCss}>
      <h2 className="mb-5">Input</h2>
      {/* <FormInputDemo
        data={data}
        onUpload={handleUpload}
        onRemove={handleRemoveFile}
        onActivityInProgress={handleActivityInProgress}
      /> */}
      <FormikDemo
        data={data}
        onUpload={handleUpload}
        onRemove={handleRemoveFile}
        onActivityInProgress={handleActivityInProgress}
        validator={formDemoValidator}
      />
    </section>
  );
}

InputDemoPage.propTypes = {
  containerCss: PropTypes.string,
};

InputDemoPage.defaultProps = {};

export default InputDemoPage;
