/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import ContentPane from "components/ui/contentPane/ContentPane";
import "./home.scss";
import RcCarousel from "components/ui/rcCarousel/RcCarousel";
import { introCarouselData } from "data/intro-carousel";
import IntroTextLeftAligned from "components/ui/rcCarousel/plugins/IntroTextLeftAligned";
import Services from "components/containers/productServices/Services";
import AboutUs from "components/features/aboutUs/AboutUs";
import MissionVision from "components/features/missionAndVision/MissionVision";
import OurTeam from "components/features/ourTeam/OurTeam";
import WhyApmi from "components/features/whyApmi/WhyApmi";
import ContactUs from "components/features/contactUs/ContactUs";
import { contactUsValidator } from "./validators/contactUsValidator";
import ClientMap from "components/features/clientMap/ClientMap";
import { useNavigate } from "react-router-dom";
import OurClients from "components/features/ourClients/OurClients";
import Gallery from "components/features/gallery/Gallery";
import CookiePolicy from "components/ui/cookiePolicy/CookiePolicy";
//import convertProvince from "core/utils/provinceMigrationTool";
import useSystem from "hooks/useSystem";
import * as QuotationSlice from "store/modules/quotation-slice";
import { Urls } from "constants/urls";
import useCookiePolicy from "hooks/useCookiePolicy";
import * as ContactUsSlice from "store/modules/contactUsSlice";
import useScrollNavigate from "hooks/useScrollNavigate";
import SeoTags from "components/features/seoTags/SeoTags";
import IntroTextCenter from "components/ui/rcCarousel/plugins/IntroTextCenter";
import { NavHeaderContext } from "components/ui/navigationHeader/context/NavHeaderContextProvider";
import { NavHeaderTypes } from "constants/navHeaderTypes";
import OurBrands from "components/features/ourBrands/OurBrands";
import useBrands from "./hooks/useBrands";
import HeroIntro from "components/features/heroIntro/HeroIntro";
import useHero from "./hooks/useHero";

const HomePage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { notify } = useSystem();
  const headerContext = useContext(NavHeaderContext);
  const { showCookie, setAcceptCookie } = useCookiePolicy();
  const { brandItems } = useBrands();
  const heroHook = useHero();
  const { loading, formId } = useSelector(ContactUsSlice.selectContactUs);

  useScrollNavigate(true, 100);

  useEffect(() => {
    headerContext.changeMode(NavHeaderTypes.DUAL_MODE);
  }, []);

  const handleContactUsFormSubmit = (values) => {
    dispatch(ContactUsSlice.createMessage(values));
  };

  const handleCloseCookie = () => {
    setAcceptCookie(true);
  };

  const handleQuoteNow = (
    serviceTypeId,
    serviceTypeName,
    serviceId,
    serviceName
  ) => {
    dispatch(
      QuotationSlice.addService({
        serviceTypeId: serviceTypeId,
        serviceId: serviceId,
        serviceType: serviceTypeName,
        serviceName,
      })
    );
    navigate(Urls.QUOTATION);
  };

  const handleAddToQuote = (
    serviceTypeId,
    serviceTypeName,
    serviceId,
    serviceName
  ) => {
    dispatch(
      QuotationSlice.addService({
        serviceTypeId: serviceTypeId,
        serviceId: serviceId,
        serviceType: serviceTypeName,
        serviceName,
      })
    )
      .unwrap()
      .then(() => {
        notify("Service added to quotation list.");
      });
  };

  return (
    <section className="home-wrapper">
      <SeoTags />

      <RcCarousel
        fxType="scale"
        data={introCarouselData}
        indicators={false}
        pause={false}
        introTextComponent={
          <IntroTextCenter>
            <HeroIntro
              title="Vista Land"
              subTitle="Vista Estates South by"
              subTitle2="Angelica Villarante"
              ctaPrimaryText="Our Brands"
              ctaSecondaryText="Discover"
              handleCtaPrimaryClick={heroHook.handleCtaPrimaryClick}
              handleCtaSecondaryClick={heroHook.handleCtaSecondaryClick}
            />
          </IntroTextCenter>
        }
      />

      <OurBrands data={brandItems} />

      {/* <Services onQuoteNow={handleQuoteNow} onAddToQuote={handleAddToQuote} />
      <AboutUs />
      <MissionVision />
      <WhyApmi />
      <Gallery />
      <OurClients /> */}
      {/* <ContactUs
        formId={formId}
        loading={loading}
        validator={contactUsValidator}
        onFormSubmit={handleContactUsFormSubmit}
      /> */}
      {/* <ClientMap /> */}
      <CookiePolicy show={showCookie} onCloseCookie={handleCloseCookie} />
    </section>
  );
};

export default HomePage;
