const QUOTATION_STORAGE_KEY = "urn:quotation.list";

export const isQuotationExists = (serviceTypeId, serviceId) => {
  try {
    const items = JSON.parse(localStorage.getItem(QUOTATION_STORAGE_KEY)) || [];

    const result = items.find(
      (f) => f.serviceTypeId === serviceTypeId && f.serviceId === serviceId
    );

    return !!result;
  } catch (e) {
    console.error(e);
  }

  return false;
};

export const getQuotation = (serviceTypeId, serviceId) => {
  try {
    const items = JSON.parse(localStorage.getItem(QUOTATION_STORAGE_KEY)) || [];

    const result = items.find(
      (f) => f.serviceTypeId === serviceTypeId && f.serviceId === serviceId
    );

    return result || [];
  } catch (e) {
    console.error(e);
  }

  return null;
};

export const getQuotations = () => {
  try {
    const items = JSON.parse(localStorage.getItem(QUOTATION_STORAGE_KEY)) || [];

    return items;
  } catch (e) {
    console.error(e);
  }

  return null;
};

export const addQuotation = ({
  serviceTypeId,
  serviceId,
  serviceType,
  serviceName,
}) => {
  try {
    let quotations = getQuotations();

    quotations.push({
      serviceTypeId,
      serviceId,
      serviceType,
      serviceName,
    });

    localStorage.setItem(QUOTATION_STORAGE_KEY, JSON.stringify(quotations));
  } catch (e) {
    console.error(e);
  }
};

export const removeQuotation = ({ serviceTypeId, serviceId }) => {
  try {
    const items = JSON.parse(localStorage.getItem(QUOTATION_STORAGE_KEY)) || [];

    const result = items.filter(
      (f) => !(f.serviceTypeId === serviceTypeId && f.serviceId === serviceId)
    );

    localStorage.setItem(QUOTATION_STORAGE_KEY, JSON.stringify(result));
  } catch (e) {
    console.error(e);
  }
};

export const removeQuotations = () => {
  try {
    localStorage.removeItem(QUOTATION_STORAGE_KEY);
  } catch (e) {
    console.error(e);
  }
};
