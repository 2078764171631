import React from "react";
import PropTypes from "prop-types";
import "./footer-layout1.scss";
import { NavLink } from "react-router-dom";

const FooterLayout1 = ({ data }) => {
  const { webSiteName, webSiteDescription, groupLink1, groupLink2 } = data;

  return (
    <div className="row footer-layout">
      <div className="col-md-4 col-12 text-left item mb-2">
        <b className="title">{webSiteName}</b>
        <p className="tsmall2 about-us mt-2">{webSiteDescription}</p>
      </div>
      <div className="col">
        <div className="d-flex flex-row justify-content-start">
          <div className="d-flex flex-column text-left item">
            <b className="title mb-1">{groupLink1.groupName}</b>
            {groupLink1.links.map((o, i) => (
              <NavLink to="/" key={i}>
                {o.label}
              </NavLink>
            ))}
          </div>
          <div className="d-flex flex-column text-left itemx">
            <b className="title mb-1">{groupLink2.groupName}</b>
            {groupLink2.links.map((o, i) => (
              <NavLink to="/" key={i}>
                {o.label}
              </NavLink>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

FooterLayout1.propTypes = { data: PropTypes.object };

FooterLayout1.defaultProps = {};

export default FooterLayout1;
