import Http from "core/http/http";

function FileUploadService() {
  const apiUrl = "/v1/files";

  const uploadFile = (payload) => Http().post(`${apiUrl}`, payload);

  const removeFile = (id, isImage) =>
    Http().delete(`${apiUrl}/${id}/isImage/${isImage}`);

  const getFiles = (payload) =>
    Http().post(`${apiUrl}/files/getfiles`, payload);

  return {
    uploadFile,
    removeFile,
    getFiles,
  };
}

export default FileUploadService;
